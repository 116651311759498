import { capitalizeWords } from "../../helpers/dashboard";
import { AfriexSecurityNote, NoteUserState } from "../../types/Notes";
import * as formatDate from "../../utils/dateFormatter";

const NoteTableBody = ({
  securityNotes,
}: {
  securityNotes: AfriexSecurityNote[];
}) => {
  return (
    <tbody className="bg-white">
      {securityNotes.map((cardNote, idx) => {
        const hasReason = cardNote?.reason?.length;
        const hasUnblockReason = cardNote?.unblockReason?.length;

        const action =
          cardNote?.userState === NoteUserState.BLOCKED || hasReason
            ? "Blocked"
            : cardNote?.userState === NoteUserState.UNBLOCKED ||
                hasUnblockReason
              ? "Unblocked"
              : cardNote?.userState === NoteUserState.NON_ACTION
                ? capitalizeWords("Flagged")
                : "";

        const reason =
          cardNote?.userState === NoteUserState.BLOCKED || hasReason
            ? capitalizeWords(cardNote?.reason?.replace(/_/g, " ") ?? "")
            : cardNote?.userState === NoteUserState.UNBLOCKED ||
                hasUnblockReason
              ? capitalizeWords(
                  cardNote?.unblockReason?.replace(/_/g, " ") ?? ""
                )
              : "N/A";

        return (
          <tr key={idx} className="border-t">
            <td className="px-4 py-2 text-sm text-gray-600">
              {formatDate.DDMonYYYY(cardNote?.createdAt, true)}
            </td>
            <td className="px-4 py-2 text-sm text-gray-600">
              {cardNote?.adminInfo?.name ?? "System"}{" "}
              {cardNote?.adminInfo?.role
                ? ` - (${cardNote?.adminInfo?.role})`
                : ""}
            </td>
            <td className="px-4 py-2 text-sm text-gray-600">
              {cardNote?.note}
            </td>
            <td className="px-4 py-2 text-sm text-gray-600">{reason}</td>
            <td
              className={`px-4 py-2 font-semibold ${
                action === "Unblocked"
                  ? "text-green-600"
                  : action === "Blocked"
                    ? "text-red-600"
                    : "text-yellow-600"
              }`}
            >
              {action}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default NoteTableBody;
