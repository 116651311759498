import { capitalizeWords } from "../../helpers/dashboard";

import "./receipt.css";
import GenerateReceiptButton from "./GenerateReceiptButton";
import ReceiptFooter from "./Footer";
import ReceiptHeader from "./Header";
import receiptConfig from "./config";
import { TransactionTypes } from "../../types";

const Receipt = ({
  type,
  transactionType,
  children,
}: {
  type: "transaction" | "kyc";
  transactionType?: TransactionTypes;
  children: React.ReactNode;
}) => {
  const getReceiptTitle = () => {
    switch (type) {
      case "transaction":
        return "Remittance Transaction Receipt";
      default:
        return `${capitalizeWords(type)} Receipt`;
    }
  };

  const getFileName = () => {
    if (type === "transaction") {
      switch (transactionType) {
        case TransactionTypes.WITHDRAW:
          return "withdraw";
        case TransactionTypes.DEPOSIT:
          return "deposit";
        case TransactionTypes.TRANSFER:
          return "transfer";
        default:
          return "transaction";
      }
    } else if (type === "kyc") {
      return "kyc";
    } else {
      return "";
    }
  };

  return (
    <div className="flex flex-col gap-5 mt-5">
      <div className="text-right">
        {/* Generate Receipt Button */}
        <GenerateReceiptButton
          fileName={getFileName()}
          label="Download Receipt"
        />
      </div>
      <div
        id="receipt-parent"
        className="flex w-450 flex-col justify-center rounded-md border border-receipt-line rounded-md"
      >
        <ReceiptHeader />

        <div className="body mx-7">
          <p className="header font-bold text-sm text-cyan-600">
            {getReceiptTitle()}
          </p>

          {children}
        </div>

        <ReceiptFooter receiptConfig={receiptConfig} />
      </div>
    </div>
  );
};

Receipt.displayName = "Receipt";
export default Receipt;
