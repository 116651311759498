import UserProfile from "./UserProfile";
import countries from "../../../constants/countries";
import { Badge, Button, Link } from "../../../components";
import { ReactElement } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AfriexVerificationStatus } from "../../../types";
import { kycService } from "../../../services/kycService";
import UserUpdateForm from "./UserUpdateForm";
import { UserDetailsReturnProps } from "./types";
import ComplianceChecklistModal from "./RequestDocumentsModal";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";

const PAGE_PERMISSION = "access-kyc-page";

type UserOverviewProps = Pick<
  UserDetailsReturnProps,
  | "user"
  | "isLoadingUserDetails"
  | "showModal"
  | "toggleModal"
  | "showComplianceModal"
  | "toggleComplianceModal"
>;
const UserOverview = ({
  isLoadingUserDetails,
  user,
  showModal,
  toggleModal,
  showComplianceModal,
  toggleComplianceModal,
}: UserOverviewProps): ReactElement => {
  const country = user?.currentCountry?.toUpperCase();

  const verifyText = "Mark as verified";
  const unverifyText = "Unverify this user";

  const isVerified = user?.kyc?.status === AfriexVerificationStatus.Success;
  const isKYCBtnDisabled = !user?.kyc?.status;

  const verifyApprove = (shouldVerify: boolean) => {
    const status = shouldVerify
      ? AfriexVerificationStatus.Success
      : AfriexVerificationStatus.Failed;
    confirmAlert({
      title: "Confirm to submit",
      message: shouldVerify
        ? "Are you sure to verify this user."
        : "Are you sure to Unverify this use.",
      buttons: [
        {
          label: "Yes",
          onClick: () => kycService.verifyUser(user.id, status),
        },
        {
          label: "No",
          onClick: () => false,
        },
      ],
    });
  };

  return (
    <div className="px-3 pt-3 mt-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"User Profile".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      {/* Name */}
      <div className="flex gap-2 items-center">
        <h2 className="font-semibold text-2xl mr-5">{user.name?.fullName}</h2>
        <Button
          className="mt-1"
          disabled={isLoadingUserDetails}
          variant="ghost"
          onClick={toggleModal}
        >
          Update
        </Button>
      </div>

      {/* Phone | Country */}
      <div className="w-full my-5 flex divide-x">
        <div className="mr-5">
          <div className="text-slate-500 text-sm">Phone</div>
          <div>{user.currentPhone}</div>
        </div>

        <div className="pl-10">
          <div className="text-slate-500 text-sm">Country</div>
          <div>{`${country} ${countries[country]}`}</div>
        </div>

        <div className="pl-10">
          <div className="text-slate-500 text-sm">KYC verified</div>
          <div>
            <Badge
              label={isVerified ? "Yes" : "No"}
              className="px-3"
              colorScheme={isVerified ? "green" : "red"}
            />
            {isVerified ? (
              <Button
                disabled={isKYCBtnDisabled}
                variant="ghost"
                onClick={() => verifyApprove(false)}
              >
                {unverifyText}
              </Button>
            ) : (
              <Button
                disabled={isKYCBtnDisabled}
                variant="ghost"
                onClick={() => verifyApprove(true)}
              >
                {verifyText}
              </Button>
            )}
            <Link
              to={`/kyc/${user.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="ghost">View KYC details</Button>
            </Link>
            <PermissionsProvider
              permission={PAGE_PERMISSION}
              action="click-kyc-request-documents"
            >
              <Button
                disabled={isKYCBtnDisabled}
                variant="ghost"
                onClick={() => toggleComplianceModal()}
              >
                Request KYC Docs
              </Button>
            </PermissionsProvider>
          </div>
        </div>
      </div>

      <UserProfile
        showEmail
        showUsername
        showUserID
        showReferredBy
        showSecurityEnabled
        showDateOpened
        showDeviceId
        showVirtualAccount
        user={user}
      />
      {/* modal for BackID */}
      {showModal && <UserUpdateForm user={user} toggleModal={toggleModal} />}
      {/* modal for Request Documents */}
      {showComplianceModal && (
        <ComplianceChecklistModal
          toggleComplianceModal={toggleComplianceModal}
          user={user}
        />
      )}
    </div>
  );
};

export default UserOverview;
