import {
  Header,
  Pagination,
  Table,
  TBody,
  TableSkeleton,
} from "../../../components";
import { doNothingAsync } from "../../../constants/formatters";
import { SupportedCurrencies } from "../../../types";

import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import UpdateForm from "./UpdateForm";
import useProcessorList from "./useProcessorList";

const ProcessorList = () => {
  const {
    isLoading,
    handleUpdateProcessor,
    total,
    filteredCountryList,
    countryList,
    processorsLatest,
    processorsAll,
    selectedCurrency,
    shouldShowUpdateForm,
    showProcessorUpdateForm,
    toggleProcessorUpdateForm,
  } = useProcessorList();
  const totalFigure = total ? ` (${total.toLocaleString()})` : "";

  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto ">
      <Header title={"Processors By Corridor" + totalFigure} />

      <main className="pt-5 py-10 overflow-x-auto">
        {isLoading ? (
          <div className="min-w-full">
            <TableSkeleton />
          </div>
        ) : (
          <Table>
            <ListHeader />
            <TBody>
              {filteredCountryList?.map((item, index) => (
                <ListItem
                  index={index}
                  item={item}
                  key={item.id}
                  processors={processorsLatest}
                  corridors={countryList?.filter(
                    (c) => c.currency === item.currency
                  )}
                  showProcessorUpdateForm={showProcessorUpdateForm}
                />
              ))}
            </TBody>
          </Table>
        )}
      </main>

      {countryList && countryList.length > 1 && (
        <Pagination
          onPageChange={doNothingAsync}
          total={total}
          isSearch={false}
          limit={100}
        />
      )}

      {shouldShowUpdateForm && (
        <UpdateForm
          onClose={toggleProcessorUpdateForm}
          handleUpdate={handleUpdateProcessor}
          processors={processorsAll}
          currency={selectedCurrency as SupportedCurrencies}
        />
      )}
    </div>
  );
};

export default ProcessorList;
