import { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getIndexCreationFiles, getKeyHoleFiles } from "../../../api/monitor";
import KeyHoleForm from "./KeyHoleForm";
import IndexCreationForm from "./IndexForm";

export default function MongoDBLogsForm(): JSX.Element {
  const actions = [
    { key: "Query Analysis", value: "keyHole" },
    { key: "Index Creation", value: "indexes" },
  ];

  const [openKeyHole, setKeyHole] = useState(false);
  const [keyHoleItems, setKeyHoleItems] = useState<any>({} as any);

  const [openCreatedIndexes, setOpenCreatedIndexes] = useState(false);
  const [indexCreationItems, setIndexCreationItems] = useState<any>({} as any);

  useEffect(() => {
    getKeyItems();
  }, []);

  async function getKeyItems() {
    const res = await getKeyHoleFiles();
    setKeyHoleItems(res);
    setKeyHole(true);
  }

  const handleChange = (event: any) => {
    const { value } = event.target;
    if (value === "keyHole") {
      getKeyHoleFiles().then(
        (result) => {
          setKeyHoleItems(result);
          setKeyHole(true);
          setOpenCreatedIndexes(false);
        },
        function (error) {
          alert(`erro=${error}`);
        }
      );
    }
    if (value === "indexes") {
      getIndexCreationFiles().then(
        (result) => {
          setKeyHole(false);
          setIndexCreationItems(result);
          setOpenCreatedIndexes(true);
        },
        function (error) {
          alert(`serr=${error}`);
        }
      );
    }
  };

  return (
    <div className="mt-10">
      <div className="my-4">
        <div className="flex space-x-4">
          <div className="w-full grid grid-cols-1 gap-4 items-center">
            <div className="w-full grid grid-cols-1 gap-4 items-center">
              <select
                defaultValue="createContact"
                onChange={handleChange}
                name="actionType"
                className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                {actions.map((action) => {
                  return (
                    <option key={action.key} value={action.value}>
                      {action.key}
                    </option>
                  );
                })}
              </select>
              {openKeyHole && <KeyHoleForm data={keyHoleItems} />}
              {openCreatedIndexes && (
                <IndexCreationForm data={indexCreationItems} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
