import { AfriexRate } from "../../types";

export const applyDiscountToRates = (
  rates: AfriexRate[],
  discount: number
): AfriexRate[] => {
  const increase = 1 + Number(discount);
  return rates.map((rate) => ({
    ...rate,
    value: (increase * Number(rate.value)).toString(),
  }));
};
