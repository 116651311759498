export enum SupportedCurrencies {
  BRL = "BRL",
  CAD = "CAD",
  CNY = "CNY",
  EGP = "EGP",
  ETB = "ETB",
  EUR = "EUR",
  GBP = "GBP",
  GHS = "GHS",
  GNF = "GNF",
  HTG = "HTG",
  INR = "INR",
  KES = "KES",
  MXN = "MXN",
  NGN = "NGN",
  PHP = "PHP",
  PKR = "PKR",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  XAF = "XAF",
  XOF = "XOF",
}
