import Button from "../common/Button";
import Modal from "../common/Modal";
import { ICurrency } from "../../types/Currency";
import SpinningLoader from "../common/SpinningLoader";

type SwapModalProps = {
  loading: boolean;
  currency: { [key: string]: ICurrency };
  amount: { [key: string]: number };
  swapFunc: () => void;
  onClose: () => void;
};

export function SwapModal({
  loading,
  currency,
  amount,
  swapFunc,
  onClose,
}: SwapModalProps) {
  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        {loading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <h3 className="text-lg ">
              Are you sure you want to swap &nbsp;
              <span className="text-indigo-500 text-center">
                {amount.from}&nbsp;{currency.from.code}&nbsp;
              </span>
              to &nbsp;
              <span className="text-indigo-500">
                {amount.to}&nbsp;{currency.to.code}
              </span>
              ?
            </h3>
            <div className="text-sm font-light mt-3">
              Double check the amount before clicking confirm
            </div>
            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={onClose} variant="outline">
                cancel
              </Button>
              <Button
                onClick={swapFunc}
                variant="outline"
                className="bg-indigo-200 hover:g"
              >
                Confirm
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
