import { isEmpty } from "lodash";
import { Fragment } from "react";
import { Button, InfoBox } from "../../../components";
import AppRatesTable from "../App/RatesTable";
// import OTCRatesTable from "../OTC/RatesTable";
import ArbitrageTable from "./ArbitrageTable";
import RatesUploadForm from "./RatesUploadForm";
import useRatesUpdate from "./useRatesUpdate";
const RatesUpload = () => {
  const {
    arbitrageResult,
    handleTemplateDownload,
    handleAppRatesUpload,
    appRates,
    loadingText,
    tolerance,
    handleToleranceChange,
    isArbitrageChecked,
    isArbitrageDetected,
    handleRatesCancellation,
    handleRatesUpdate,
  } = useRatesUpdate();
  const isDisabled =
    !isArbitrageChecked || isArbitrageDetected || isEmpty(appRates);
  return (
    <div>
      <RatesUploadForm
        handleAppRatesUpload={handleAppRatesUpload}
        handleTemplateDownload={handleTemplateDownload}
      />
      <br />
      {loadingText && (
        <InfoBox>
          <svg
            className="animate-spin h-5 w-5 mr-3 ..."
            viewBox="0 0 24 24"
          ></svg>
          {loadingText}
        </InfoBox>
      )}
      {arbitrageResult && isArbitrageChecked && (
        <ArbitrageTable
          arbitrageResult={arbitrageResult}
          rates={appRates}
          tolerance={tolerance}
          onToleranceChange={handleToleranceChange}
        />
      )}
      {!isEmpty(appRates) && !isArbitrageDetected && (
        <section style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            onClick={handleRatesCancellation}
            className="check-abs-btn hover:bg-blue-600 text-black py-1 px-3 rounded mt-4"
            style={{ background: "#ECECEC" }}
          >
            Clear
          </button>
        </section>
      )}
      {!isEmpty(appRates) && (
        <Fragment>
          <h3>App Rates</h3>
          <AppRatesTable rates={appRates} />
        </Fragment>
      )}
      {/* {otcRates && otcRates?.length > 0 && <Fragment><h3>OTC Rates</h3><OTCRatesTable rates={otcRates} /></Fragment>}
       */}
      {!isEmpty(appRates) && (
        <section className="action-wrap flex justify-end w-full">
          <Button
            disabled={isDisabled}
            className="btn border-2 p-2 text-sm bg-blue-500 text-white disabled:opacity-70 disabled:text-slate-200"
            data-tip
            data-for="productionPush"
            onClick={handleRatesUpdate.bind(null, appRates)}
            variant={"solid"}
          >
            Push to production
          </Button>
        </section>
      )}
    </div>
  );
};

export default RatesUpload;
