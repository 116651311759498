import React from "react";

export const TableSkeleton = () => {
  const skeletonClassName = "animate-pulse bg-gray-200";
  const greenSkeletonClassName = "animate-pulse bg-green-200";

  return (
    <table className="min-w-full">
      <tbody>
        {Array.from({ length: 10 }).map((_, index) => (
          <tr
            key={index}
            className="w-full flex hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500
          "
          >
            <td className="w-full m-1">
              <div
                className={`${skeletonClassName} h-5 rounded w-5 w-full h-10`}
              ></div>
            </td>

            <td className="w-full m-1">
              <div
                className={`${skeletonClassName} h-5 rounded w-5 w-full h-10`}
              ></div>
            </td>

            <td className="w-full m-1">
              <div
                className={`${greenSkeletonClassName} h-5 rounded w-5 w-full h-10`}
              ></div>
            </td>

            <td className="w-full m-1">
              <div
                className={`${skeletonClassName} h-5 rounded w-5 w-full h-10`}
              ></div>
            </td>

            <td className="w-full m-1">
              <div
                className={`${skeletonClassName} h-5 rounded w-5 w-full h-10`}
              ></div>
            </td>
            <td className="w-full m-1">
              <div
                className={`${skeletonClassName} h-5 rounded w-5 w-full h-10`}
              ></div>
            </td>
            <td className="w-full m-1">
              <div
                className={`${skeletonClassName} h-5 rounded w-5 w-full h-10`}
              ></div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableSkeleton;
