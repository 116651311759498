import { Header, Pagination, Table, TBody } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import { AfriexReward } from "../../../types";

import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import UpdateButton from "./UpdateButton";
import useReward from "./useReward";

const RewardCardList = () => {
  const { isLoading, isSearch, fetchList, total, list } = useReward();
  const totalFigure = total ? ` (${total.toLocaleString()})` : "";

  /**
   * These are the filter attributes that will be passed to the filter bar
   */

  return (
    <div className="mx-10">
      <Header title={"Manage Reward List" + totalFigure} />
      <div className="w-full flex justify-end mb-3">
        <UpdateButton />
      </div>

      <main className="pt-5 py-10 overflow-x-auto">
        {isLoading ? (
          <div className="min-w-full">
            <TableSkeleton />
          </div>
        ) : (
          <Table>
            <ListHeader />
            <TBody>
              {list?.map((item: AfriexReward) => (
                <ListItem item={item} key={item.id} />
              ))}
            </TBody>
          </Table>
        )}
      </main>

      <footer>
        {list && list.length > 1 && (
          <Pagination
            onPageChange={fetchList as any}
            total={total}
            isSearch={isSearch}
          />
        )}
      </footer>
    </div>
  );
};

export default RewardCardList;
