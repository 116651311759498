import { Header, Pagination } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import KycFilters from "./KycFilters";
import KycTable from "./KycTable";
import useKYCList from "./useKYCList";

const KYCList = () => {
  const {
    isLoading,
    isSearch,
    isPageReset,
    fetchUserList,
    pageInfo,
    userList,
    filterCount,
    currentFilters,
    filtersToApply,
    handleApplyFilters,
    clearFilters,
    handleAutoComplete,
    selectAppliedFilters,
    selectCurrentFilters,
  } = useKYCList();
  const totalFigure = pageInfo.total
    ? ` (${pageInfo.total.toLocaleString()})`
    : "";

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={"KYC List" + totalFigure} />

      <KycFilters
        filterCount={filterCount}
        currentFilters={currentFilters}
        filtersToApply={filtersToApply}
        clearFilters={clearFilters}
        selectCurrentFilters={selectCurrentFilters}
        selectAppliedFilters={selectAppliedFilters}
        handleApplyFilters={handleApplyFilters}
        handleAutoComplete={handleAutoComplete}
      />

      <section className="pt-5 md:pt-5">
        {isLoading ? <TableSkeleton /> : <KycTable items={userList} />}
      </section>

      {userList && userList.length > 1 && (
        <Pagination
          onPageChange={fetchUserList as any}
          total={pageInfo.total}
          isSearch={isSearch}
          isPageReset={isPageReset}
        />
      )}
    </main>
  );
};

export default KYCList;
