import { UserDetailsReturnProps } from "./types";

import FundUserWallet from "./FundUserWallet";
import { Link, Swap, Button } from "../../../components";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import UserWalletBalance from "./UserWalletBalance";
import React from "react";

type TransactionViewProps = Pick<
  UserDetailsReturnProps,
  "walletBalances" | "isLoadingWalletBalances" | "user"
>;

const TransactionsView: React.FC<TransactionViewProps> = ({
  walletBalances,
  isLoadingWalletBalances,
  user,
}) => {
  return (
    <div className="px-3 pt-3 mt-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Transactions".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <div className="flex justify-between">
        <UserWalletBalance
          isLoadingWalletBalances={isLoadingWalletBalances}
          walletBalances={walletBalances}
        />
        <FundUserWallet user={user} />
      </div>

      <div className="flex flex-col mt-5 gap-3 w-4/12">
        <Swap userId={user.id} key={user.id} />
        <Link
          to={`/transactions/user/${user.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="w-max"
        >
          <Button
            colorScheme="cyan"
            variant="ghost"
            rightIcon={<ExternalLinkIcon className="w-5 h-5" />}
            className="no-padding"
          >
            View user transactions
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default TransactionsView;
