import axios from "../api/axios";
import {
  AfriexAuthResponse,
  AfriexLoginRequest,
  AfriexMessageChannel,
  AfriexNonceResponse,
  AfriexOTPRequest,
  AfriexOTPResendRequest,
  AfriexTokenResponse,
} from "../types";

export const authService = {
  sendLogin: async (body: AfriexLoginRequest): Promise<AfriexAuthResponse> => {
    const response = await axios.post(`/v2/admin/users/login`, body);
    return response.data;
  },
  sendOTP: async (
    code: string,
    nonce: string
  ): Promise<AfriexTokenResponse> => {
    const body: AfriexOTPRequest = {
      nonce,
      code,
    };

    const response = await axios.post(`/v2/admin/users/otp`, body);
    return response.data;
  },
  resendOTP: async (nonce: string): Promise<AfriexNonceResponse> => {
    const body: AfriexOTPResendRequest = {
      nonce,
      channels: [AfriexMessageChannel.Sms],
      deviceId: "",
    };
    const response = await axios.post(`/v2/users/otp/resend`, body);
    return response.data;
  },
};
