import { ReactElement } from "react";
import { Th, Thead, Tr } from "../../../components";

const ListHeader = (): ReactElement => (
  <Thead>
    <Tr>
      <Th></Th>
      <Th>NAME</Th>
      <Th>THRESHOLD</Th>
      <Th>DATE</Th>
      <Th></Th>
    </Tr>
  </Thead>
);

export default ListHeader;
