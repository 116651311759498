import { useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { subMonths } from "date-fns";
import DataTable from "react-data-table-component";
import { ProviderDetails } from "../../../types/MonitoringBoard";
import { logger } from "../../../utils/logger";

export default function ProvidersForm(): JSX.Element {
  const actions = [
    { label: "Providus", value: "providus" },
    { label: "Stripe", value: "stripe" },
  ];

  const endD = new Date();
  const startD = subMonths(endD, 1);
  const [startDate] = useState(startD);
  const [endDate] = useState(endD);

  const handleChange = (event: any) => {
    logger.log(JSON.stringify(event));
  };

  const columns = [
    {
      name: "txnDate",
      selector: (row: any) => row.txnDate,
      sortable: true,
      wrap: true,
    },
    {
      name: "txnType",
      selector: (row: any) => row.txnType,
      sortable: true,
      wrap: true,
    },
    {
      name: "provider",
      selector: (row: any) => row.provider,
      sortable: true,
      wrap: true,
    },
    {
      name: "status",
      selector: (row: any) => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "errorResponse",
      selector: (row: any) => row.errorResponse,
      sortable: true,
      wrap: true,
    },
    {
      name: "action",
      button: true,
      cell: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      ),
    },
  ];

  const data: ProviderDetails[] = [
    {
      txnDate: "10/14/2022",
      txnType: "Send To Bank",
      provider: "Providus",
      status: "failed",
      errorResponse: "We should not be failing but we are",
      action: "action",
    },
  ];
  const customStyles = {
    option: (provided: any, state: { isSelected: boolean }) => ({
      ...provided,
      borderBottom: "1px solid #dede",
      color: state.isSelected ? "#53e3a6" : "green",
      backgroundColor: "white",
      padding: 1,
    }),
    control: (base: any, state: any) => ({
      ...base,
      color: state.isSelected ? "#53e3a6" : "green",
      border: "1px solid rgba(255, 255, 255, 0.4)",
      boxShadow: "none",
      margin: 0,
      "&:hover": {
        border: "1px solid rgba(255, 255, 255, 0.4)",
      },
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: "1em",
      color: "black",
      fontWeight: 40,
    }),
  };

  return (
    <div className="mt-10">
      <div className="my-4 ">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
              </svg>
              <DatePicker
                selected={startDate}
                showTimeSelect
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
              </svg>
              <DatePicker
                selected={endDate}
                showTimeSelect
                onChange={handleChange}
              />
            </div>

            <Select
              placeholder={"Select Provider"}
              onChange={handleChange}
              options={actions}
              styles={customStyles}
            />
            <Select
              placeholder={"Select Service"}
              onChange={handleChange}
              options={actions}
              styles={customStyles}
            />
          </div>

          <DataTable columns={columns} pagination data={data} />
        </div>
      </div>
    </div>
  );
}
