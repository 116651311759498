const UserRoles = {
  SUPER_ADMIN: "super_admin",
  OWNER: "owner",
  ADMIN: "admin",
  DEFI_FULfILLER: "defi-fulfiller",
  FINANCE: "finance",
  COMPLIANCE: "compliance",
  CUSTOMER_CARE: "customer_care",
  GROWTH: "growth",
  PRODUCT: "product.eng.qa.design.manager",
};

export default UserRoles;
