import { Table, TBody, Th, Thead, Tr } from "../../../components";
import { AfriexOTCRate } from "../../../types";
import RateItem from "./RateItem";

export type RatesTableProp = {
  rates: AfriexOTCRate[];
};

export const ratesHeaderHue = "bg-blue-50 p-8 shadow-sm sticky";

const RatesTable = ({ rates }: RatesTableProp) => (
  <div className="py-3 overflow-x-auto" style={{ width: "100%" }}>
    <Table>
      <Thead className={ratesHeaderHue}>
        <Tr>
          <Th>From</Th>
          <Th>To</Th>
          <Th>Rate</Th>
          <Th>Rate(inverse)</Th>
          <Th>Source</Th>
        </Tr>
      </Thead>
      <TBody>
        <>
          {rates?.map((otcRate: AfriexOTCRate, index: number) => (
            <RateItem key={otcRate.id} rate={otcRate} index={index} />
          ))}
        </>
      </TBody>
    </Table>
  </div>
);

export default RatesTable;
