import {
  AfriexOTCRate,
  AfriexOTCVolume,
  AfriexPaginatedResponse,
  AfriexRate,
  AfriexRates,
  SupportedCurrencies,
} from "../types";
import axios from "../api/axios";
import { RatesOTCQueryParams } from "../types/request";

export const ratesService = {
  getAppRatesList: async (): Promise<AfriexRate[]> => {
    const url = `/v2/admin/rates`;
    const response = await axios.get(url);
    return response?.data;
  },
  getOTCRatesList: async (): Promise<any> => {
    const url = `/v2/admin/rates`;
    const response = await axios.get(url);
    return response?.data;
  },

  updateRates: async (payload: any): Promise<AfriexRates> => {
    const url = `/v2/admin/rates`;
    const response = await axios.post(url, payload);
    return response?.data;
  },
  // This service is currently not in use as the arbitrage detection is done on the FE using web workers

  getSymbolList: async (): Promise<SupportedCurrencies[]> => {
    const url = `/v2/rates/symbols`;
    const response = await axios.get(url);
    return response?.data?.symbols;
  },
  getPromoList: async (): Promise<SupportedCurrencies[]> => {
    const url = `/v2/rates/symbols`;
    const response = await axios.get(url);
    return response?.data?.symbols;
  },
  getOTCVolumeList: async (
    filters: RatesOTCQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexOTCVolume[]>> => {
    const paginationParams = {
      page: 0,
      limit: 10,
    } as any;
    const params = { ...paginationParams, ...filters };
    const url = `/v2/admin/otc-volume?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },
  saveOTCVolume: async (payload: {
    otcVolume: AfriexOTCVolume;
    otcRates: AfriexOTCRate[];
  }): Promise<AfriexOTCVolume> => {
    const url = `/v2/admin/otc-volume`;
    const response = await axios.post(url, payload);
    return response?.data;
  },
  updateOTCVolume: async (rate: AfriexOTCVolume): Promise<AfriexOTCVolume> => {
    const url = `/v2/admin/otc-volume`;
    const response = await axios.patch(url, rate);
    return response?.data;
  },
  approveOTCVolume: async (rateId: string): Promise<AfriexOTCVolume> => {
    const url = `/v2/admin/otc-volume/${rateId}`;
    const response = await axios.patch(url, {});
    return response?.data;
  },
};
