import { useCallback, useEffect, useState } from "react";
import { AfriexPerson } from "../../../types";
import { useNavigate, useParams } from "react-router-dom";
import { kycService } from "../../../services/kycService";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import { Header } from "../../../components";
import Spinner from "../../../assets/svg/Spinner";
import KycReceipt from "../Receipt";

const KycReceiptPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const userId = params?.userId ?? "";

  const [kycDetails, setKycDetails] = useState<AfriexPerson>(
    {} as AfriexPerson
  );

  const [kycLoading, setKycLoading] = useState(false);

  const fetchKYCDetails = useCallback(async (): Promise<void> => {
    try {
      if (!userId) {
        throw new Error("User Id is required");
      }
      setKycLoading(true);
      const kycResponse = await kycService.getKYCDetails(userId);
      setKycDetails(kycResponse);
    } catch (err: any) {
      const errorMessage = err.message
        ? err.message
        : err.response?.data.message;
      showErrorMessage("Failed to fetch KYC details:" + errorMessage);
    } finally {
      setKycLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchKYCDetails();
  }, [fetchKYCDetails]);

  return (
    <div className="mt-10 mb-5 mx-4 md:mx-10">
      <Header
        title="Kyc"
        icon={
          <ArrowNarrowLeftIcon
            className="font-light w-5 h-5 text-black hover:text-indigo-500 cursor-pointer"
            onClick={() => navigate(-1)}
          />
        }
      />

      <div className="flex justify-center items-center flex-col">
        {kycLoading ? (
          <Spinner size={8} className="text-cyan-600" />
        ) : (
          <KycReceipt data={kycDetails} />
        )}
      </div>
    </div>
  );
};

export default KycReceiptPage;
