import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import OTCVolumeForm from "./OTCVolumeForm";
import { AfriexOTCVolume } from "../../../types";
import { isEmpty } from "lodash";

type OTCUpdateButtonProps = {
  initialData?: AfriexOTCVolume;
};

const OTCUpdateButton = ({
  initialData,
}: OTCUpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = isEmpty(initialData)
    ? "Upload OTC Volume +"
    : "Update OTC Volume";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <OTCVolumeForm onClose={toggleFullScreen} initialData={initialData} />
  ) : (
    <Button
      variant={"solid"}
      className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
      onClick={toggleFullScreen}
    >
      {updateLabel}
    </Button>
  );
};

export default OTCUpdateButton;
