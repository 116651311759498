import { ReactElement } from "react";

import countryFlags from "../../constants/countries";
import { AfriexCountry, SupportedCountryCodes } from "../../types";
import { Select } from "../Select";
import useCountryList from "../../hooks/useCountryList";
import { matches, filter } from "lodash";
interface CountryPickersProps {
  onChange: (value: SupportedCountryCodes, event: any) => void;
  value?: SupportedCountryCodes;
  label?: string;
  multiple?: boolean;
  filter?: Partial<AfriexCountry>;
}

const mapOptionsToList = (
  options: AfriexCountry[],
  filters: Partial<AfriexCountry> = {}
) =>
  filter(options, matches(filters)).map((country: AfriexCountry) => ({
    value: country.iso2,
    label: `${country.name} ${countryFlags?.[country?.iso2] ?? ""}`,
  }));

const CountryPicker = ({
  label,
  onChange,
  value,
  multiple = false,
  filter = {},
}: CountryPickersProps): ReactElement => {
  const { countryList } = useCountryList();
  const options = mapOptionsToList(countryList ?? [], filter);
  return (
    <div className="mb-4 mr-2">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor={label}
      >
        {label}
      </label>
      <Select
        multiple={multiple}
        options={options}
        value={value}
        onChange={(e: any) => onChange(e.target.value, e)}
        className="rounded-l-md border-r-1 border-l-1  border-gray-300  text-gray-900 focus:ring-indigo-500 focus:border-blue-500 block flex-1 min-w-0 w-full sm:text-sm p-2.5"
      />
    </div>
  );
};
export default CountryPicker;
