import "react-confirm-alert/src/react-confirm-alert.css";
import DataTable from "react-data-table-component";
import Modal from "../../common/Modal";
import SpinningLoader from "../../common/SpinningLoader";
import Button from "../../common/Button";

type KeyHoleAnalysisProps = {
  data: [];
  onClose: () => void;
  loading: boolean;
};

export default function IndexCreationModal({
  data,
  onClose,
  loading,
}: KeyHoleAnalysisProps): JSX.Element {
  const columns = [
    {
      name: "Database",
      cell: (row: any) => row.db,
    },
    {
      name: "Collecton",
      cell: (row: any) => row.collection,
    },
    {
      name: "Key",
      selector: (row: any) => row.key,
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <Modal modalOpen>
      <div className="max-h-screen overflow-y-auto my-3 mx-5">
        <h3 className="text-lg text-center">Indexes Created</h3>
        <br />
        <br />
        {loading ? (
          <div className="flex w-full h-full place-content-stretch justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <div className="w-full grid place-content-stretch grid-cols-1 gap-4 items-center divide-y ">
              <div className="w-full grid grid-cols-1 place-content-stretch">
                <DataTable columns={columns} pagination data={data} />
              </div>

              <div className="flex w-full col-span-2 justify-center space-x-2 py-5">
                <Button onClick={onClose} variant="outline">
                  Done
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
