import React, { useState, useEffect } from "react";
import { userService } from "../../services/userService";
import {
  AfriexDispute,
  AfriexDisputeNote,
  AfriexDisputeStatus,
  AfriexDisputeCount,
} from "../../types/AfriexDispute";
import TableSkeleton from "../../components/transactions/TransactionSkeleton";
import { useNavigate } from "react-router-dom";

const Disputes: React.FC = () => {
  const [disputes, setDisputes] = useState<AfriexDispute[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [expandedDispute, setExpandedDispute] = useState<string | null>(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedDisputeId, setSelectedDisputeId] = useState<string | null>(
    null
  );
  const [noteText, setNoteText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<AfriexDisputeStatus>(
    AfriexDisputeStatus.PENDING
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [disputeCounts, setDisputeCounts] = useState<AfriexDisputeCount | null>(
    null
  );
  const [sortField, setSortField] = useState<"status" | "amount" | "createdAt">(
    "createdAt"
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const limit = 10;
  const navigate = useNavigate();

  useEffect(() => {
    fetchDisputes(currentPage);
    fetchDisputeCounts();
  }, [currentPage, sortField, sortDirection]);

  const fetchDisputes = async (page: number) => {
    setLoading(true);
    try {
      const response = await userService.getDisputes({
        limit,
        page,
      });
      setDisputes(response.data);
      setTotalPages(Math.ceil(response.total / limit));
    } catch (error) {
      console.error("Error fetching disputes:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDisputeCounts = async () => {
    try {
      const counts = await userService.countDisputes();
      setDisputeCounts(counts);
    } catch (error) {
      console.error("Error fetching dispute counts:", error);
    }
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const handleDisputeClick = (disputeId: string) => {
    setExpandedDispute(expandedDispute === disputeId ? null : disputeId);
  };

  const handleUpdateDispute = (disputeId: string) => {
    setSelectedDisputeId(disputeId);
    setIsUpdateModalOpen(true);
  };

  const handleUpdateSubmit = async () => {
    if (!selectedDisputeId) {
      return;
    }
    setIsUpdating(true);
    try {
      await userService.updateDispute(selectedDisputeId, {
        status: selectedStatus,
        notes: [
          {
            note: noteText,
            createdAt: new Date(),
            updatedAt: new Date(),
          },
        ],
      });
      setIsUpdateModalOpen(false);
      setNoteText("");
      setSelectedStatus(AfriexDisputeStatus.PENDING);
      setSelectedDisputeId(null);
      fetchDisputes(currentPage);
      fetchDisputeCounts();
    } catch (error) {
      console.error("Error updating dispute:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const renderNotes = (notes: AfriexDisputeNote[] | undefined) => {
    if (!notes || notes.length === 0) {
      return <p>No notes available.</p>;
    }

    return (
      <ul className="list-disc pl-5">
        {notes.map((note, index) => (
          <li key={index} className="mb-2">
            <p className="font-semibold">
              {new Date(note.createdAt).toLocaleString()}
            </p>
            <p>{note.note}</p>
          </li>
        ))}
      </ul>
    );
  };

  const handleUserClick = (userId: string) => {
    navigate(`/users/${userId}`);
  };

  const handleTransactionClick = (transactionId: string) => {
    navigate(`/transactions/${transactionId}`);
  };

  const handleSort = (field: "status" | "amount" | "createdAt") => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const DisputeCountBox: React.FC<{
    label: string;
    count: number;
    color: string;
  }> = ({ label, count, color }) => (
    <div className={`p-4 rounded-lg shadow-md ${color}`}>
      <h3 className="text-lg font-semibold mb-2">{label}</h3>
      <p className="text-2xl font-bold">{count}</p>
    </div>
  );

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-3">Disputes</h1>
      {disputeCounts && (
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
          <DisputeCountBox
            label="Today"
            count={disputeCounts.todayCount}
            color="bg-blue-100"
          />
          <DisputeCountBox
            label="Pending"
            count={disputeCounts.pendingCount}
            color="bg-yellow-100"
          />
          <DisputeCountBox
            label="Closed"
            count={disputeCounts.closedCount}
            color="bg-green-100"
          />
          <DisputeCountBox
            label="Created"
            count={disputeCounts.createdCount}
            color="bg-purple-100"
          />
          <DisputeCountBox
            label={disputeCounts.withNotesCount > 1 ? "Attended" : "With Notes"}
            count={disputeCounts.withNotesCount}
            color={
              disputeCounts.withNotesCount > 1 ? "bg-indigo-100" : "bg-red-100"
            }
          />
        </div>
      )}
      <div className="overflow-x-auto">
        {loading ? (
          <TableSkeleton />
        ) : (
          <table className="w-full text-sm border-collapse bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 text-left">ID</th>
                <th className="p-2 text-left">User ID</th>
                <th className="p-2 text-left">Payment ID</th>
                <th
                  className="p-2 text-left cursor-pointer"
                  onClick={() => handleSort("amount")}
                >
                  Amount{" "}
                  {sortField === "amount" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th className="p-2 text-left">Currency</th>
                <th
                  className="p-2 text-left cursor-pointer"
                  onClick={() => handleSort("status")}
                >
                  Status{" "}
                  {sortField === "status" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th className="p-2 text-left">Reason</th>
                <th
                  className="p-2 text-left cursor-pointer"
                  onClick={() => handleSort("createdAt")}
                >
                  Created At{" "}
                  {sortField === "createdAt" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th className="p-2 text-left">Notes</th>
              </tr>
            </thead>
            <tbody>
              {disputes.map((dispute) => (
                <React.Fragment key={dispute.id}>
                  <tr
                    className="border-b hover:bg-gray-50 cursor-pointer transition duration-150 ease-in-out"
                    onClick={() => handleDisputeClick(dispute.id as string)}
                    title="Click to expand"
                  >
                    <td className="p-2 underline text-blue-600">
                      {dispute.id}
                    </td>
                    <td
                      className="p-2 underline text-blue-600 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUserClick(dispute.userId);
                      }}
                    >
                      {dispute.userId}
                    </td>
                    <td
                      className="p-2 underline text-blue-600 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTransactionClick(dispute.paymentId);
                      }}
                    >
                      {dispute.paymentId}
                    </td>
                    <td className="p-2">{dispute.amount}</td>
                    <td className="p-2">{dispute.currency}</td>
                    <td className="p-2">{dispute.status}</td>
                    <td className="p-2">{dispute.reason}</td>
                    <td className="p-2">
                      {new Date(dispute.createdAt).toLocaleString()}
                    </td>
                    <td className="p-2">{dispute.notes?.length || 0}</td>
                  </tr>
                  {expandedDispute === dispute.id && (
                    <tr>
                      <td colSpan={9} className="p-4 bg-gray-100">
                        <div className="mb-4">
                          <h3 className="text-lg font-semibold mb-2">Notes:</h3>
                          {renderNotes(dispute.notes)}
                        </div>
                        <div className="flex justify-end space-x-2">
                          <button
                            onClick={() =>
                              handleUpdateDispute(dispute.id as string)
                            }
                            className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                          >
                            Update Dispute
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="mt-3 flex justify-center">
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`mx-1 px-2 py-1 text-xs rounded ${
              currentPage === i
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>
      {isUpdateModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-1/2 max-w-2xl">
            <h2 className="text-xl font-bold mb-4">Update Dispute</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Status
              </label>
              <select
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={selectedStatus}
                onChange={(e) =>
                  setSelectedStatus(e.target.value as AfriexDisputeStatus)
                }
              >
                {Object.values(AfriexDisputeStatus).map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
            <textarea
              className="w-full h-40 p-2 border rounded mb-4"
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              placeholder="Enter your note here..."
            ></textarea>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsUpdateModalOpen(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                disabled={isUpdating}
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateSubmit}
                className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${
                  noteText.length < 5 || isUpdating
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={noteText.length < 5 || isUpdating}
              >
                {isUpdating ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Disputes;
