import { Button, CheckBox, Input, ViewModal } from "../../../components";
import { UserDetailsReturnProps } from "./types";
import useUserUpdate from "./useUserUpdate";

type UserUpdateFormProps = Pick<UserDetailsReturnProps, "user" | "toggleModal">;
const UserUpdateForm = ({ user, toggleModal }: UserUpdateFormProps) => {
  const {
    handleEmailChange,
    handleNameChange,
    handlePhoneChange,
    handleUsernameChange,
    handlePhoneVerifiedChange,
    isButtonDisabled,
    isPhoneVerified,
    isSubmitting,
    handleSubmit,
    setIsPhoneVerified,
  } = useUserUpdate(user, toggleModal);

  return (
    <ViewModal onClose={toggleModal}>
      <form onSubmit={handleSubmit}>
        <div className="mt-5">
          <Input
            label="Full Name"
            type="text"
            placeholder="Enter full name"
            defaultValue={user?.name?.fullName}
            onChange={handleNameChange}
          />
        </div>

        <div className="mt-5">
          <Input
            label="Email"
            placeholder="Enter email"
            type="text"
            defaultValue={user?.currentEmail}
            onChange={handleEmailChange}
            on
          />
        </div>
        <div className="mt-5">
          <Input
            label="Username"
            placeholder="Enter username"
            type="text"
            defaultValue={user?.name?.userName}
            onChange={handleUsernameChange}
            on
          />
        </div>
        <div className="mt-5">
          <Input
            label="Phone"
            placeholder="Enter Phone"
            type="text"
            defaultValue={user?.currentPhone}
            onChange={handlePhoneChange}
          />
        </div>
        <div className="mt-5">
          <CheckBox
            label="Verify Phone Number"
            type="checkbox"
            checked={isPhoneVerified}
            onChange={() => {
              handlePhoneVerifiedChange(!isPhoneVerified);
              setIsPhoneVerified(!isPhoneVerified);
            }}
          />
        </div>
        <div className="flex w-full justify-end space-x-2 py-5">
          <Button variant="outline" onClick={toggleModal}>
            Cancel
          </Button>

          <Button
            disabled={isSubmitting || isButtonDisabled}
            type="submit"
            variant="outline"
            className="bg-indigo-200 hover:g"
          >
            Update User
          </Button>
        </div>
      </form>
    </ViewModal>
  );
};
export default UserUpdateForm;
