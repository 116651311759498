interface TableProps {
  children?: JSX.Element | JSX.Element[] | string | React.ReactNode;
  className?: string;
  [rest: string]: any;
}

export function Table({ children, className = "" }: TableProps): JSX.Element {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md ${className}`}
    >
      {children}
    </table>
  );
}

// border shadow-sm border-color-gray-200

export function Thead({ children, className }: TableProps): JSX.Element {
  return <thead className={className}>{children}</thead>;
}

export function TBody({ children, className = "" }: TableProps): JSX.Element {
  return (
    <tbody className={`bg-white divide-y divide-gray-200 ${className}`}>
      {children}
    </tbody>
  );
}

export function Tr({
  children,
  className = "",
  ...rest
}: TableProps): JSX.Element {
  return (
    <tr className={className} {...rest}>
      {children}
    </tr>
  );
}

interface TdProps extends TableProps {
  isNumeric?: boolean;
  colspan?: number;
}
export function Td({
  children,
  colspan = 1,
  className = "",
  isNumeric = false,
}: TdProps) {
  return (
    <td
      colSpan={colspan}
      className={`py-2 whitespace-normal ${
        isNumeric && "text-right"
      } ${className}`}
    >
      {children}
    </td>
  );
}

interface ThProps extends TableProps {
  isNumeric?: boolean;
}
export function Th({
  children,
  className,
  isNumeric = false,
}: ThProps): JSX.Element {
  return (
    <th
      scope="col"
      className={`py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider ${
        isNumeric && "text-right"
      } ${className}`}
    >
      {children}
    </th>
  );
}
