import {
  Header,
  FilterBar,
  Pagination,
  SearchBar,
  Table,
  TBody,
  Tabs,
} from "../../../components";
import {
  FilterAttribute,
  FilterAttributeType,
} from "../../../components/FilterBar/types";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import { AfriexReferralStatus, TabItem } from "../../../types";

import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import useReferralList from "./useReferralList";

const ReferralList = () => {
  const {
    isLoading,
    isSearch,
    fetchReferralList,
    handleAutoComplete,
    handleFilters,
    handlePayment,
    handleBlockPayout,
    total,
    referralList,
  } = useReferralList();

  const totalFigure = total ? ` (${total.toLocaleString()})` : "";

  /**
   * These are the filter attributes that will be passed to the filter bar
   */
  const attributes: FilterAttribute[] = [
    {
      title: "From Date",
      label: "fromDate",
      type: FilterAttributeType.Date,
    },
    {
      title: "To Date",
      label: "toDate",
      type: FilterAttributeType.Date,
    },
    {
      title: "Status",
      label: "status",
      type: FilterAttributeType.Select,
      options: {
        [AfriexReferralStatus.Joined]: "Joined",
        [AfriexReferralStatus.Transacted]: "Transacted",
        [AfriexReferralStatus.Qualified]: "Qualified",
        [AfriexReferralStatus.Resolved]: "Resolved",
      },
    },
  ];

  const searchTabs: TabItem[] = [
    {
      id: "search",
      title: "Search Referrals",
      content: (
        <SearchBar
          handleSearch={handleAutoComplete}
          handleAutoComplete={handleAutoComplete}
          placeholder="Search by username"
          inputClassName="w-2/5"
        />
      ),
    },
    {
      id: "filter",
      title: "Filter Referrals",
      content: <FilterBar attributes={attributes} onSubmit={handleFilters} />,
    },
  ];

  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <Header title={"Referral List" + totalFigure} />
      <Tabs tabs={searchTabs} />
      <main className="pt-5 py-10 overflow-x-auto">
        {isLoading ? (
          <div className="min-w-full">
            <TableSkeleton />
          </div>
        ) : (
          <Table>
            <ListHeader />
            <TBody>
              {referralList?.map((item, index) => (
                <ListItem
                  index={index}
                  item={item}
                  key={item.id}
                  handlePayment={handlePayment}
                  handleBlockPayout={handleBlockPayout}
                />
              ))}
            </TBody>
          </Table>
        )}
      </main>

      {referralList && referralList.length > 1 && (
        <Pagination
          onPageChange={fetchReferralList as any}
          total={total}
          isSearch={isSearch}
        />
      )}
    </div>
  );
};

export default ReferralList;
