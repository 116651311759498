import {
  ClockIcon,
  XCircleIcon,
  RefreshIcon,
  CheckIcon,
  ReceiptRefundIcon,
} from "@heroicons/react/outline";
import { SVGProps } from "react";

export enum TransactionTypes {
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  TRANSFER = "TRANSFER",
  SWAP = "SWAP",
  REVERSAL = "REVERSAL",
  REFERRAL = "REFERRAL",
  ACH = "ACH",
  WELCOME_BONUS = "WELCOME_BONUS",
  E2E = "E2E",
  SCHEDULED = "SCHEDULED",
  REWARD = "REWARD",
}

export enum TransactionChannels {
  WIDGET = "WIDGET",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  MOBILE_MONEY = "MOBILE_MONEY",
  CARD = "CARD",
  CRYPTO = "CRYPTO",
  INTERNAL = "INTERNAL",
  ADMIN = "ADMIN",
  WALLET = "WALLET",
  VIRTUAL_BANK_ACCOUNT = "VIRTUAL_BANK_ACCOUNT",
  ACH_BANK_ACCOUNT = "ACH_BANK_ACCOUNT",
}

export enum AdminTransactionTypes {
  "ADMIN_DEPOSIT" = TransactionTypes.DEPOSIT,
  "ADMIN_WITHDRAW" = TransactionTypes.WITHDRAW,
  "ADMIN_WELCOME_BONUS" = TransactionTypes.WELCOME_BONUS,
}

export enum AfriexTransactionStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  PROCESSING = "PROCESSING",
  REFUNDED = "REFUNDED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
}

export interface IBankAccountInfo {
  account_number: string;
  bank_name: string;
  account_name: string;
  phoneNumber: string;
  bank_code: string;
}

export interface ICustomerDetails {
  name: string;
  email: string;
  username: string;
  photo: string;
  phone: string;
}

export interface AfriexAgentDetails {
  userName: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  country: string;
}

export interface AfriexTransactionUpdate {
  state: AfriexTransactionStatus;
}

export interface LedgerRequestParams {
  currency: string;
  amount: number;
  userId: string;
  action: "credit" | "debit";
  reason: LedgerReasons;
}

export enum LedgerReasons {
  MISSING_DEPOSIT = "MISSING_DEPOSIT",
  REFERRAL_BONUS = "REFERRAL_BONUS",
  LOST_DISPUTE = "LOST_DISPUTE",
  STRIPE_REFUND = "STRIPE_REFUND",
  WRONG_CREDIT = "WRONG_CREDIT",
  OTHERS = "OTHERS",
}

export const statusIcons: Partial<{
  [key in AfriexTransactionStatus]: React.FC<SVGProps<SVGSVGElement>>;
}> = {
  [AfriexTransactionStatus.PENDING]: ClockIcon,
  [AfriexTransactionStatus.CANCELLED]: XCircleIcon,
  [AfriexTransactionStatus.SUCCESS]: CheckIcon,
  [AfriexTransactionStatus.PROCESSING]: RefreshIcon,
  [AfriexTransactionStatus.FAILED]: XCircleIcon,
  [AfriexTransactionStatus.REFUNDED]: ReceiptRefundIcon,
};

// Function to get icon
export const getIcon = (
  label: AfriexTransactionStatus
): React.FC<SVGProps<SVGSVGElement>> | undefined => {
  return statusIcons[label];
};

// These are custom colors defined in tailwind.config.js
export const statusColors: { [key: string | AfriexTransactionStatus]: string } =
  {
    pending: "pending",
    successful: "successful",
    failed: "failed",
    cancelled: "failed",
    completed: "successful",
    rejected: "failed",
    [AfriexTransactionStatus.PENDING]: "pending",
    [AfriexTransactionStatus.SUCCESS]: "successful",
    [AfriexTransactionStatus.FAILED]: "failed",
    [AfriexTransactionStatus.CANCELLED]: "failed",
    [AfriexTransactionStatus.REFUNDED]: "refunded",
  };
