import axios from "./axios";
import { AfriexKYCInfo, AfriexVerificationStatus } from "../types";
import { KYCQueryParams } from "../types/User";
import { AfriexComplianceDocumentTypes } from "../types/AfriexComplianceDocumentTypes";

// Send Push Notification
export async function getKYC(userID: string) {
  const response = await axios.get(`/admin/kyc/${userID}`);
  return response.data;
}

export async function getKYCList({
  limit = 10,
  page = 0,
  status = undefined,
  startDate = undefined,
  endDate = undefined,
}: KYCQueryParams) {
  const params = {
    page,
    limit,
    status,
    startDate,
    endDate,
  } as any;
  const url = `/v2/admin/kyc?${new URLSearchParams(params).toString()}`;
  const response = await axios.get(url);
  return response.data;
}

export async function uploadKYC(kycData: Partial<AfriexKYCInfo>): Promise<any> {
  return axios.post("/admin/kyc", kycData);
}

export async function getUserKYC(userId: string) {
  const response = await axios.get(`/admin/kyc/${userId}`);
  return response.data;
}

export async function getImageLink(id: string) {
  const response = await axios.get(`/kyc-upload/${id}`);
  return response.data;
}

export async function updateKycStatus(
  userId: string,
  status: AfriexVerificationStatus,
  data?: { requestedAdditionalDocuments: AfriexComplianceDocumentTypes[] }
) {
  const response = await axios.patch(`/v2/admin/kyc/status/${userId}`, {
    status,
    ...data,
  });
  return response.data;
}
