import { useState } from "react";
import { Button, MinusIcon, PlusIcon, Td, Tr } from "../../../components";
import { AfriexOTCVolume } from "../../../types";
import { formatDate } from "../../../utils/formatDate";
import OTCSummary from "./OTCSummary";
const formatRateDisplay = (rateRaw: number) => {
  if (!rateRaw) return "";
  const rate = Number(rateRaw);
  const wholePart = Math.trunc(rate);
  const decimalIncluded = rate?.toFixed(2);
  const rateDisplay =
    wholePart === Number(decimalIncluded) ? wholePart : decimalIncluded;
  return Number(rateDisplay)?.toLocaleString();
};
type ListItemProps = {
  item: AfriexOTCVolume;
  index: number;
  handleApprove: (id: string) => void;
};
const ListItem = ({ item, handleApprove, index }: ListItemProps) => {
  const className = "text-sm text-black pl-3";
  const zebraRow = (index + 1) % 2 === 0 ? "" : "bg-gray-50";
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <>
      <Tr
        className={`hover:bg-indigo-200 transition-background transition-colors transition-opacity ease-in-out duration-500 ${zebraRow}`}
      >
        <Td className="pl-5">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
            <div
              className={`border border-gray-300 w-5 h-5 flex justify-center rounded-lg hover:bg-sky-50 ${
                isExpanded && "bg-sky-50"
              }`}
            >
              {isExpanded ? (
                <MinusIcon className="w-4 h-4 text-indigo-500" />
              ) : (
                <PlusIcon className="text-gray-300 w-4 h-4 hover:text-indigo-500" />
              )}
            </div>
          </Button>
        </Td>

        <Td>
          <div className={className}>
            {item?.fromAmount?.toLocaleString()} {item?.fromSymbol}
          </div>
        </Td>
        <Td>
          <div className={className}>
            {item?.toAmount?.toLocaleString()} {item?.toSymbol}
          </div>
        </Td>
        <Td>
          <div className={className}>{formatRateDisplay(item?.rate ?? 0)}</div>
        </Td>
        <Td>
          <div className={className}>
            {formatDate(item.timestamp?.toString() ?? "")}
          </div>
        </Td>
        <Td></Td>
      </Tr>
      {isExpanded && (
        <Tr className="pr-5">
          <Td className="p-0 m-0" colspan={9}>
            <div className="flex py-10 px-8 justify-center bg-sky-100 w-full">
              <OTCSummary handleApprove={handleApprove} item={item} />
            </div>
          </Td>
        </Tr>
      )}
    </>
  );
};

export default ListItem;
