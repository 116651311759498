import { PairwiseDiscrepancyResult, SupportedCurrencies } from "../../types";
import { calculatePercentageArbitrage } from "./calculatePercentageArbitrage";

export const checkPairwiseDiscrepancy = (
  ratesMatrix: number[][],
  currencySymbols: SupportedCurrencies[],
  percentageThreshold = 1
): PairwiseDiscrepancyResult => {
  const results: Record<number, number> = {};
  const remarks: string[] = [];
  let maxProfit = 0;
  for (let i = 0; i < ratesMatrix.length; i += 1) {
    for (let j = 0; j < ratesMatrix.length; j += 1) {
      const rateInForwardDirection = ratesMatrix[i][j];
      const rateInReverseDirection = ratesMatrix[j][i];
      if (rateInForwardDirection < 1) continue;
      const inverseRate =
        rateInReverseDirection > 0 ? 1 / rateInReverseDirection : 0;

      const percentageGain = calculatePercentageArbitrage(
        rateInForwardDirection,
        rateInReverseDirection,
        percentageThreshold
      );
      if (percentageGain) {
        const remark = `${currencySymbols[i]}/${currencySymbols[j]}=${rateInForwardDirection}\n
        1/${currencySymbols[j]}/${currencySymbols[i]}=${inverseRate}\n
        Gain=${percentageGain}%
        `;

        remarks.push(remark);
        results[i] = j;
        if (percentageGain > maxProfit) maxProfit = percentageGain;
      }
    }
  }
  return {
    discrepancies: results,
    remarks,
    maxProfit,
  };
};
