import {
  AfriexTier,
  SupportedCurrencies,
  TransactionChannels,
} from "../../../types";
import { TierAction, TierActionType } from "./types";

const updateTierReducer = (state: AfriexTier, action: TierAction) => {
  switch (action.type) {
    case TierActionType.UPDATE_TIER:
      return {
        ...state,
        ...(action.payload as Partial<AfriexTier>),
      };

    case TierActionType.ADD_SUPPORTED_CURRENCY:
      return {
        ...state,
        currencies: [
          ...(state.currencies ?? []),
          action.payload as SupportedCurrencies,
        ],
      };

    case TierActionType.REMOVE_SUPPORTED_CURRENCY:
      return {
        ...state,
        currencies: state.currencies?.filter((x) => x !== action.payload) ?? [],
      };
    case TierActionType.ADD_SUPPORTED_SOURCE_PAYMENT_METHOD:
      return {
        ...state,
        allowedSourceChannels: [
          ...(state.allowedSourceChannels ?? []),
          action.payload as TransactionChannels,
        ],
      };
    case TierActionType.REMOVE_SUPPORTED_SOURCE_PAYMENT_METHOD:
      return {
        ...state,
        allowedSourceChannels:
          state.allowedSourceChannels?.filter((x) => x !== action.payload) ??
          [],
      };
    case TierActionType.ADD_SUPPORTED_TARGET_PAYMENT_METHOD:
      return {
        ...state,
        allowedDestChannels: [
          ...(state.allowedDestChannels ?? []),
          action.payload as TransactionChannels,
        ],
      };
    case TierActionType.REMOVE_SUPPORTED_TARGET_PAYMENT_METHOD:
      return {
        ...state,
        allowedDestChannels:
          state.allowedDestChannels?.filter((x) => x !== action.payload) ?? [],
      };

    default:
      return state;
  }
};

export default updateTierReducer;
