import {
  Modal,
  Button,
  SpinningLoader,
  Input,
  Select,
  TextArea,
  CountryPicker,
  ImageInput,
  CheckBox,
} from "../../../components";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AfriexReward,
  AfriexRewardTier,
  AfriexRewardType,
  SupportedCountryCodes,
} from "../../../types";
import { generateRandomId } from "../../../utils/generateRandomId";
import { mapCountryCodeToFlag } from "../../../constants/countries";
import DateFilter from "../../../components/FilterBar/DateFilter";
import { isEmpty } from "lodash";

type FormProps = {
  onClose: VoidFunction;
  handleSave: (body: AfriexReward) => Promise<void>;
  initialData?: AfriexReward;
};
const UpdateForm = ({ onClose, handleSave, initialData }: FormProps) => {
  const [formValues, setFormValues] = useState<AfriexReward>({
    isDeactivated: true,
  } as AfriexReward);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isImageAdded, setIsImageAdded] = useState<boolean>(false);
  const imageId = useMemo(() => generateRandomId(), []);
  const defaultFileName = "reward_card_image_" + imageId;
  const [fileName, setFileName] = useState<string>("");
  const [supportedCountries, setSupportedCountries] = useState<
    SupportedCountryCodes[]
  >([]);

  const addCountryToList = (countryCode: SupportedCountryCodes) => {
    if (!supportedCountries.includes(countryCode)) {
      setSupportedCountries([...supportedCountries, countryCode]);
    }
  };

  const removeFromCountryList = (countryCode: SupportedCountryCodes) => {
    if (supportedCountries.includes(countryCode)) {
      setSupportedCountries(
        supportedCountries.filter((x) => x !== countryCode)
      );
    }
  };

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const body = {
      ...formValues,
      type: formValues?.type ?? formValues?.type ?? AfriexRewardType.GiftCard,
      tier: formValues?.tier ?? formValues?.tier ?? AfriexRewardTier.Member,
      supportedCountries,
      imageLink: isImageAdded ? fileName : (formValues?.imageLink ?? ""),
      pointsValue:
        Number(formValues?.pointsValue) ?? formValues?.pointsValue ?? 0,
      isDeactivated: formValues?.isDeactivated ?? false,
    };
    await handleSave(body);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedCountries, formValues, handleSave, fileName]);

  useEffect(() => {
    if (!isEmpty(initialData)) {
      setFormValues(initialData);
      setSupportedCountries(initialData?.supportedCountries ?? []);
    }
    setFileName(initialData?.imageLink ?? defaultFileName);
  }, [initialData, defaultFileName]);

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom">Add New Reward</h3>
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-5">
              <div className="mt-5">
                <Input
                  type="text"
                  label="Name"
                  value={formValues?.name ?? ""}
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      name: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>
              <div className="mt-5">
                <TextArea
                  type="text"
                  label="Description"
                  defaultValue={formValues?.description ?? ""}
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      description: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                <Select
                  label="Select Type"
                  value={formValues?.type ?? ""}
                  options={Object.entries(AfriexRewardType).map(
                    ([key, value]) => ({ label: key, value }) as any
                  )}
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      type: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                <Select
                  label="Lowest Eligible Tier"
                  value={formValues?.tier?.toLowerCase() ?? ""}
                  options={Object.entries(AfriexRewardTier).map(
                    ([key, value]) => ({ label: key, value }) as any
                  )}
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      tier: e?.target?.value?.toUpperCase() ?? "",
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                <label>Expires On (Optional)</label>
                <DateFilter
                  attribute="Expiry Date"
                  value={(formValues?.ttl as string) || ""}
                  onChange={(date: Date) => {
                    setFormValues((x) => ({
                      ...x,
                      ttl: new Date(date)?.toISOString() ?? "",
                    }));
                  }}
                />
              </div>

              <div className="mt-5">
                <ImageInput
                  label="Upload Image"
                  alt="Reward Item Image"
                  name={fileName}
                  value={formValues?.imageLink ?? ""}
                  onChange={(value: string) => {
                    setFormValues((x) => ({ ...x, imageLink: value ?? "" }));
                    setIsImageAdded(true);
                  }}
                />
              </div>

              <div className="mt-5">
                <Input
                  label="Points Value"
                  type="number"
                  min="0"
                  step="1"
                  value={formValues?.pointsValue?.toString() ?? ""}
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      pointsValue: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                {supportedCountries?.map((countryCode, index) => (
                  <div
                    className="flex justify-between space-x-2 border-b-1 py-2"
                    key={index}
                  >
                    <label>
                      {countryCode} {mapCountryCodeToFlag(countryCode)}
                    </label>
                    <Button
                      variant={"ghost"}
                      onClick={() => removeFromCountryList(countryCode)}
                    >
                      -
                    </Button>
                  </div>
                ))}
                <CountryPicker
                  label="Add Country Available"
                  filter={{ status: "active" }}
                  value={
                    supportedCountries?.[supportedCountries?.length - 1] ??
                    ("" as any)
                  }
                  onChange={(value: SupportedCountryCodes) =>
                    addCountryToList(value ?? "")
                  }
                />
              </div>
              <div className="mt-5">
                <CheckBox
                  label="Mark as active"
                  type="checkbox"
                  checked={!formValues?.isDeactivated}
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      isDeactivated:
                        !e.target?.checked ??
                        Boolean(formValues?.isDeactivated),
                    }))
                  }
                />
              </div>
              <div className="flex w-full justify-end space-x-2 py-5">
                <Button onClick={onClose} variant="outline">
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="outline"
                  className="bg-indigo-200 hover:g"
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpdateForm;
