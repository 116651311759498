import { useState } from "react";
import { UserGroupIcon } from "@heroicons/react/solid";
import countries from "../../constants/countries";
import Badge from "../common/Badge";
import Button from "../common/Button";
import { Input, TextArea } from "../common/Input";
import { toast } from "react-toastify";
import {
  sendBulkPushNotification,
  sendSinglePushNotification,
} from "../../api/notification";
import { SendPushNotificationModal } from "./SendPushNotificationModal";
import { PermissionsProvider } from "../common/PermissionsProvider";
import React from "react";
import { SupportedCountryCodes } from "../../types/CountryTypes";

const countryOptions = [{ key: "All Users", value: "" }];
Object.entries(countries).forEach((entry) =>
  countryOptions.push({
    key: `${entry[0]} ${entry[1]}`,
    value: entry[0],
  })
);

const PAGE_PERMISSION = "push-notifications";
export default function PushNotificationForm() {
  const [country, setCountry] = useState<SupportedCountryCodes>();
  const [action, setAction] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [notificationDetails, setNotificationDetails] = useState({
    type: "",
    email: "",
    info: "",
  });

  const handleActionChange = ({ target }: { target: HTMLInputElement }) => {
    setAction(target.value);
  };

  const onSelectCountry = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button = event.target as HTMLButtonElement;
    setCountry(button.name as SupportedCountryCodes);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationDetails({
      ...notificationDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOpenModal(true);
  };

  const sendNotification = async () => {
    try {
      setLoading(true);
      if (action === "masspush") {
        const response = await sendBulkPushNotification({
          message: notificationDetails?.info,
          title: notificationDetails?.type,
          countries: country,
        });
        if (response) {
          setLoading(false);
          setOpenModal(false);
          toast.success("Notification Sent");
        }
      } else {
        const response = await sendSinglePushNotification({
          email: notificationDetails?.email,
          message: notificationDetails?.info,
          title: notificationDetails?.type,
        });
        if (response) {
          setLoading(false);
          setOpenModal(false);
          toast.success("Notification Sent");
        }
      }
    } catch (err) {
      let errorMessage = "Unknown Error. Contact Admin!";
      if (typeof err === "object" && err !== null) {
        const error = err as {
          response?: { data?: { message?: string } };
          message?: string;
        };
        errorMessage =
          error.response?.data?.message || error.message || errorMessage;
      }
      toast.error(errorMessage);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mt-10">
        <div className="my-4">
          <span>Send To:</span>
          <span className="mx-4">
            <React.Fragment>
              <PermissionsProvider
                permission={PAGE_PERMISSION}
                action="click-push-notification-mass-push"
              >
                <input
                  onChange={handleActionChange}
                  type="radio"
                  id="masspush"
                  value="masspush"
                  name="action"
                  checked={action === "masspush"}
                />
              </PermissionsProvider>
            </React.Fragment>
            <label className="ml-1" htmlFor="masspush">
              Mass Push
            </label>
          </span>
          <span className="mx-4">
            <React.Fragment>
              <PermissionsProvider
                permission={PAGE_PERMISSION}
                action="click-push-notification-individual"
              >
                <input
                  onChange={handleActionChange}
                  type="radio"
                  id="individual"
                  value=""
                  name="action"
                  checked={action === ""}
                />
              </PermissionsProvider>
            </React.Fragment>
            <label className="ml-1" htmlFor="individual">
              Individual
            </label>
          </span>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Email */}

          {action !== "masspush" && (
            <React.Fragment>
              <PermissionsProvider
                permission={PAGE_PERMISSION}
                action="click-push-notification-email"
              >
                <Input
                  label="Email"
                  type="email"
                  required
                  name="email"
                  className="my-4"
                  value={notificationDetails.email}
                  onChange={handleInputChange}
                />
              </PermissionsProvider>
            </React.Fragment>
          )}

          {/* Country */}
          {action && (
            <div className="my-4">
              <div>Select Country</div>
              <div>
                {countryOptions.map(({ key, value }) => (
                  <Badge
                    key={value}
                    className="mx-1 my-2 px-2"
                    colorScheme={country === value ? "indigo" : ""}
                  >
                    <button
                      className="hover:bg-indigo-200 inline-flex"
                      name={value}
                      type="button"
                      onClick={onSelectCountry}
                    >
                      {key}
                      {value === "" && (
                        <UserGroupIcon className="w-4 h-4 text-indigo-700 ml-1" />
                      )}
                    </button>
                  </Badge>
                ))}
              </div>
            </div>
          )}

          {/* Title */}
          <React.Fragment>
            <PermissionsProvider
              permission={PAGE_PERMISSION}
              action="click-push-notification-title"
            >
              <Input
                onChange={handleInputChange}
                value={notificationDetails.type}
                className="my-4"
                label="Title"
                type="text"
                required
                name="type"
              />
            </PermissionsProvider>
          </React.Fragment>
          {/* Message */}
          <React.Fragment>
            <PermissionsProvider
              permission={PAGE_PERMISSION}
              action="click-push-notification-message"
            >
              <TextArea
                onChange={handleInputChange}
                value={notificationDetails.info}
                className="my-4"
                label="Message"
                required
                name="info"
              />
            </PermissionsProvider>
          </React.Fragment>
          <React.Fragment>
            <PermissionsProvider
              permission={PAGE_PERMISSION}
              action="click-push-notification-submit"
            >
              <Button
                type="submit"
                colorScheme="indigo"
                variant="solid"
                className="text-white"
              >
                Submit
              </Button>
            </PermissionsProvider>
          </React.Fragment>
        </form>
      </div>

      {openModal && (
        <SendPushNotificationModal
          loading={loading}
          sendPushNotification={sendNotification}
          onClose={() => setOpenModal(false)}
          title={notificationDetails.type}
          message={notificationDetails.info}
        />
      )}
    </>
  );
}
