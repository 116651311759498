import { Pagination, Table, TableSkeleton, TBody } from "../../../components";
import ListHeader from "./SignupBonusListHeader";
import ListItem from "./SignupBonusListItem";
import SignupBonusUpdateButton from "./SignupBonusUpdateButton";
import useSignupBonusList from "./useSignupBonusList";

export const SignupBonusListTab = () => {
  const { isLoading, fetchList, total, list } = useSignupBonusList();
  return (
    <>
      <div className="w-full flex justify-end mb-3">
        <SignupBonusUpdateButton />
      </div>
      <main className="pt-5 py-10 overflow-x-auto">
        <Table>
          <ListHeader />
          <TBody>
            {isLoading ? (
              <TableSkeleton />
            ) : (
              list?.map((item, index) => (
                <ListItem index={index} item={item} key={item.id} />
              ))
            )}
          </TBody>
        </Table>
      </main>

      <footer>
        {list && list.length > 1 && (
          <Pagination
            onPageChange={fetchList as any}
            total={total}
            isSearch={false}
          />
        )}
      </footer>
    </>
  );
};
