import { ReactElement } from "react";
import { Th, Thead, Tr } from "../../../components";

const ListHeader = (): ReactElement => (
  <Thead className="py-4">
    <Tr>
      <Th></Th>
      <Th>NAME</Th>
      <Th>DISCOUNT</Th>
      <Th>STATUS</Th>
      <Th>DATE</Th>
      <Th></Th>
    </Tr>
  </Thead>
);

export default ListHeader;
