export interface AfriexDispute {
  id: string;
  userId: string;
  paymentId: string;
  internalData: {
    provider: string;
    providerId: string;
    providerReason: string;
    providerStatus: string;
  };
  amount: number;
  currency: string;
  status: string;
  reason: string;
  createdAt: Date;
  updatedAt: Date;
  notes?: AfriexDisputeNote[];
}

export interface AfriexDisputeNote {
  id?: string;
  note: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum AfriexDisputeStatus {
  CREATED = "created",
  PENDING = "pending",
  CLOSED = "closed",
  FRAUD = "fraud",
  REVERSED = "reversed",
  LEGIT = "legit",
}

export interface AfriexDisputeCount {
  todayCount: number;
  pendingCount: number;
  closedCount: number;
  createdCount: number;
  withNotesCount: number;
}
