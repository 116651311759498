import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import { isEmpty } from "lodash";
import TierUserForm from "./TierUserForm";

type TierUpdateButtonProps = {
  userId?: string;
  tierId: string;
};

const TierUserUpdateButton = ({
  userId,
  tierId,
}: TierUpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = isEmpty(userId)
    ? "Add User To Tier+"
    : "Remove From Tier";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <TierUserForm onClose={toggleFullScreen} userId={userId} tierId={tierId} />
  ) : (
    <Button
      variant={"solid"}
      className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
      onClick={toggleFullScreen}
    >
      {updateLabel}
    </Button>
  );
};

export default TierUserUpdateButton;
