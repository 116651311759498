import { useMemo } from "react";
import { ArbitrageCycle } from "../../../types";
import { calculateArbitrageProfit } from "../../../utils/rates/calculateArbitrageProfit";

export const ArbitrageReporter = ({
  amounts,
  currencies,
  tolerance,
}: ArbitrageCycle & { tolerance: number }) => {
  const totalAmount = useMemo(
    () =>
      amounts?.reduce(
        (accumulatedAmount, currentAmount) => accumulatedAmount * currentAmount,
        1
      ),
    [amounts]
  );
  const profit = (totalAmount ?? 1) - 1;
  const profitPercentage = calculateArbitrageProfit({ amounts });
  const firstCurrency = currencies?.[0];
  return profitPercentage < tolerance ? null : (
    <div>
      Path: {currencies?.join(" -> ")} <br />
      Total: {amounts?.join(" x ")} = {totalAmount} <br />
      Profit: {profit} <br />
      So if you start out with 1 {firstCurrency}. After exploiting the
      arbitrage, you will end up with {totalAmount?.toFixed(4)} {firstCurrency}.
      This makes you a profit of {profit?.toFixed(4)} {firstCurrency}
      <br />
      Percentage Profit: {profitPercentage?.toFixed(2)}% which exceeds the
      tolerance of {tolerance}
    </div>
  );
};
