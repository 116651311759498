import { Modal, SpinningLoader, Tabs } from "../../../components";
import { TabItem } from "../../../types";
import TierUserBulkUploadForm from "./TierUserBulkUploadForm";
import TierUserSingleUploadForm from "./TierUserSingleUploadForm";
import useTierUserUpdate from "./useTierUserUpdate";
import { isEmpty } from "lodash";

type TierUserFormProps = {
  onClose: VoidFunction;
  userId?: string;
  tierId: string;
};

const TierForm = ({ onClose, userId, tierId }: TierUserFormProps) => {
  const { isLoading, handleSubmit, handleBulkUpload, setUserEmail, userEmail } =
    useTierUserUpdate(onClose, tierId, userId);

  const updateLabel = isEmpty(userId)
    ? "Add User To Referral Tier +"
    : "Remove User From Referral Tier";
  const submitLabel = isEmpty(userId) ? "Submit" : "Remove";
  const isDisabled = isEmpty(userEmail) && isEmpty(userId);

  const tabs: TabItem[] = [
    {
      id: "single",
      title: "Single Upload",
      content: (
        <TierUserSingleUploadForm
          onClose={onClose}
          userEmail={userEmail}
          handleSubmit={handleSubmit}
          setUserEmail={setUserEmail}
          isDisabled={isDisabled}
          submitLabel={submitLabel}
        />
      ),
    },
    {
      id: "bulk",
      title: "Bulk Upload",
      content: (
        <TierUserBulkUploadForm
          onClose={onClose}
          userEmail={userEmail}
          handleSubmit={handleBulkUpload}
          setUserEmail={setUserEmail}
          isDisabled={isDisabled}
          submitLabel={submitLabel}
        />
      ),
    },
  ];

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom">{updateLabel}</h3>

        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <Tabs key={isLoading?.toString()} tabs={tabs} queryKey={"tab"} />
        )}
      </div>
    </Modal>
  );
};

export default TierForm;
