import { PairwiseDiscrepancyResult } from "../../../types";

export const ArbitragePairwiseReporter = ({
  remarks,
}: PairwiseDiscrepancyResult) => {
  return (
    <ul>
      {remarks?.map((remark) => <li key={remark}>{remark}</li>)}
      <hr />
    </ul>
  );
};
