import { Header, Tabs } from "../../../components";
import { TabItem } from "../../../types";
import { ReferralTierListTab } from "./ReferralTierListTab";
import { SignupBonusListTab } from "./SignupBonusListTab";
import useSignupBonusList from "./useSignupBonusList";
import useTierList from "./useTierList";

const Tierlist = () => {
  const { total: tierListTotal } = useTierList();
  const { total: signupCodeTotal } = useSignupBonusList();

  const tierFigure = tierListTotal
    ? ` (${tierListTotal.toLocaleString()})`
    : "";
  const signupCodeFigure = signupCodeTotal
    ? ` (${signupCodeTotal.toLocaleString()})`
    : "";
  const tabs: TabItem[] = [
    {
      id: "tiers",
      title: "Referral Tiers" + tierFigure,
      content: <ReferralTierListTab />,
    },
    {
      id: "signup",
      title: "Signup Bonus Tiers" + signupCodeFigure,
      content: <SignupBonusListTab />,
    },
  ];
  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <Header title={"Referral Tiers List"} />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default Tierlist;
