import { ReactNode, useEffect, useState } from "react";
import { hasPermission } from "./PageRolePermissions";

type PermissionsProviderProp = {
  permission: string;
  action: string;
  children: string | JSX.Element | JSX.Element[] | ReactNode;
};
//
export function PermissionsProvider({
  permission,
  action,
  children,
}: PermissionsProviderProp) {
  const [permissionProvided, setPermissionProvided] = useState<boolean>();

  useEffect(() => {
    _setDefaults();
  }, []);

  // This populates the state with default values
  const _setDefaults = async () => {
    const perm = hasPermission(permission, action);
    setPermissionProvided(perm);
  };

  return <>{permissionProvided ? children : null}</>;
}
