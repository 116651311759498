import { Button } from "../../../components";
import { CustomInput } from "../../../components/common/Input";
import { LoginFormProps } from "./useLogin";

const LoginForm = ({
  isLoading,
  handleLogin,
  handleChange,
}: LoginFormProps) => {
  return (
    <div className="h-screen w-full flex justify-center items-center bg-indigo shadow-lg">
      <div>
        <h1 className="text-center my-10 text-gray-700 font-semibold text-xl">
          Afriex Admin Portal
        </h1>
        <div className="border px-10 py-8 rounded-md ">
          <form className="flex flex-col space-y-10" onSubmit={handleLogin}>
            <CustomInput
              onChange={handleChange}
              label="Email"
              type="email"
              required
              name="email"
            />

            <CustomInput
              onChange={handleChange}
              label="Password"
              type="password"
              name="password"
              required
            />

            <Button
              variant="solid"
              colorScheme="cyan"
              type="submit"
              isLoading={isLoading}
              loadingText="Logging in..."
            >
              Login
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
