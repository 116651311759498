import OTPForm from "./OTPForm";
import useOTP from "./useOTP";

const OTPAuth = () => {
  const { isLoading, email, otp, handleOTPChange, handleResendOTP, countdown } =
    useOTP();
  return (
    <OTPForm
      isLoading={isLoading}
      email={email}
      otp={otp}
      handleOTPChange={handleOTPChange}
      handleResendOTP={handleResendOTP}
      countdown={countdown}
    />
  );
};

export default OTPAuth;
