import { Link, useLocation } from "react-router-dom";
import {
  IMenuConfig,
  ISubMenuConfig,
  menuConfig as menuItems,
} from "../../config/menuConfig";
import { useAuth } from "../../hooks/useAuth";
import { useTransactionContext } from "../../context/TransactionContext";
import { useEffect, useState } from "react";
import { AfriexRoles } from "../../types";

const navClasses = "p-2 pl-4 w-3/4 mt-1";
const activeNavClasses = "p-2 pl-4 bg-gray-link rounded-md mt-1";
const exactNavClasses = "text-cyan-600 font-semibold";

type NavProps = {
  closeMenu: () => void;
};

export default function Nav({ closeMenu }: NavProps): JSX.Element {
  const location = useLocation();
  const { role } = useAuth();
  const { menuSuffixes } = useTransactionContext();
  return (
    <nav className="px-3 flex flex-col flex-1 overflow-y-auto">
      <ul className="flex flex-col">
        {menuItems.map((menuItem) => {
          if (!menuItem?.roles?.includes(role as AfriexRoles)) return null;
          const path = `/${menuItem.path}`;
          const Icon = menuItem.icon;
          return (
            <li key={menuItem.label}>
              <Link
                to={path}
                onClick={closeMenu}
                className={`flex items-center gap-1 ${
                  location.pathname.includes(path)
                    ? activeNavClasses
                    : navClasses
                }`}
              >
                {Icon && (
                  <Icon
                    className={`w-5 h-5 ${
                      path === location.pathname && exactNavClasses
                    }`}
                  />
                )}

                <span
                  className={`${path === location.pathname && exactNavClasses}`}
                >
                  {menuItem.label}
                </span>
              </Link>

              {location.pathname.includes(path) && (
                <SubMenus
                  menuItem={menuItem}
                  closeMenu={closeMenu}
                  currentLocation={location.pathname}
                  suffixes={menuSuffixes}
                />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

type SubMenuProps = {
  menuItem: IMenuConfig;
  closeMenu: () => void;
  currentLocation: string;
  suffixes: any;
};

function SubMenus({
  menuItem,
  closeMenu,
  currentLocation,
  suffixes,
}: SubMenuProps): JSX.Element {
  const location = useLocation();
  const [currentStatusRoute, setCurrentStatusRoute] = useState<string | null>(
    null
  );

  useEffect(() => {
    setCurrentStatusRoute(currentLocation + location.search);
  }, [currentLocation, location]);

  const subMenus = menuItem.subMenu.map((item: ISubMenuConfig, index) => {
    const subMenuPath = `/${item.path}`;
    const Icon = item.icon;
    const isActive = subMenuPath === currentStatusRoute;
    return (
      <li
        key={index}
        onClick={() => setCurrentStatusRoute(subMenuPath)}
        className={`flex items-center ${navClasses} ${
          isActive && exactNavClasses
        } m-0 p-0 w-3/5 ml-5 pl-3 gap-1`}
      >
        {Icon && <Icon className="w-5 h-5" />}
        <Link to={item.path} onClick={closeMenu} className="text-sm">
          {item.label} {suffixes[item.path] && suffixes[item.path]}
        </Link>
      </li>
    );
  });

  return <ul className="mt-0">{subMenus}</ul>;
}
