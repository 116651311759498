import { ReactElement } from "react";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import TransactionFilters from "../../../components/transactions/TransactionFilters";
import { TransactionFiltersProps } from "./types";

const PAGE_PERMISSION = "access-transaction-page";
const TransactionFilterBar = ({
  transactions,
  filterCount,
  currentFilters,
  filtersToApply,
  clearFilters,
  selectCurrentFilters,
  selectAppliedFilters,
  handleApplyFilters,
}: TransactionFiltersProps): ReactElement => (
  <PermissionsProvider
    permission={PAGE_PERMISSION}
    action="click-transaction-search"
  >
    <TransactionFilters
      transactions={transactions}
      filterCount={filterCount}
      currentFilters={currentFilters}
      filtersToApply={filtersToApply}
      clearFilters={clearFilters}
      selectCurrentFilters={selectCurrentFilters}
      selectAppliedFilters={selectAppliedFilters}
      handleApplyFilters={handleApplyFilters}
    />
  </PermissionsProvider>
);
export default TransactionFilterBar;
