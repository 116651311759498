import { Outlet } from "react-router-dom";
import { LogoutIcon } from "@heroicons/react/outline";
import Nav from "./Nav";
import AfriexLogo from "./AfriexLogo";
import { useAuth } from "../../hooks/useAuth";
import Button from "./Button";

export default function Layout(): JSX.Element {
  const { logout, adminUser } = useAuth();

  return (
    <div className="h-screen flex bg-cyan-500">
      {/* sidebar */}
      <aside className="w-64 transition-all duration-500 bg-white z-2 h-full border-r flex flex-col">
        <div className="flex-shrink-0 bg-white py-5 w-inherit border-r">
          <AfriexLogo label="Afriex Admin" margin="ml-7" />
        </div>

        <div className="flex-1 flex flex-col h-0">
          <Nav closeMenu={() => ""} />
        </div>

        <div className="flex flex-col py-4 px-3 border-t text-slate-500 flex-shrink-0 justify-between gap-4">
          <div className="flex gap-2 items-center">
            {/* avatar */}
            <div className="w-6 h-6">
              <img
                src={`https://ui-avatars.com/api/?name=${
                  adminUser?.firstName ?? "Afriex Admin"
                }&background=random`}
                alt="user-avatar"
                className="rounded-full"
              />
            </div>

            {/* User Info */}
            <div className="flex flex-col truncate">
              <p className="text-gray-700 font-semibold truncate w-36 text-sm">
                {adminUser?.firstName}
              </p>
              <p className="text-gray-600 font-normal truncate w-36 text-sm">
                {adminUser?.email}
              </p>
            </div>
          </div>

          <div className="flex w-max">
            <Button
              variant="unStyled"
              onClick={logout}
              leftIcon={
                <LogoutIcon className="w-5 h-5 hover:text-cyan-700 text-gray-700 group-hover:text-cyan-700" />
              }
              className="group no-padding ml-1"
            >
              <span className="text-sm font-medium text-gray-700 group-hover:text-cyan-700">
                Logout
              </span>
            </Button>
          </div>
        </div>
      </aside>

      {/* main */}
      <main className={`bg-background relative mt-0 flex-1`}>
        <div className="mx-0 md:mx-2 mb-40 flex-1 overflow-auto bg-background overflow-y-auto h-full">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
