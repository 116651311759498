/*
  @note
   We prefer enums for type sets where there is only a limited set of values
   We define once and reuse throughout the app as much as possible
   When integrating with third party libraries that use similar but slightly different terminology,
   we map to our internal definition of the concept
   We can add new assets to these maps and enums when we start to support them.
*/

export enum SupportedCurrencies {
  NGN = "NGN",
  USD = "USD",
  GBP = "GBP",
  EUR = "EUR",
  KES = "KES",
  UGX = "UGX",
  CAD = "CAD",
  GHS = "GHS",
  XAF = "XAF",
  ETB = "ETB",
  HTG = "HTG",
}

export enum SupportedCryptoSymbols {
  BTC = "BTC",
  ETH = "ETH",
  USDT = "USDT",
}

export type SupportedAssets = SupportedCryptoSymbols | SupportedCurrencies;

export const StripeSupportedCurrencies = [
  SupportedCurrencies.USD,
  SupportedCurrencies.GBP,
  SupportedCurrencies.EUR,
  SupportedCurrencies.CAD,
];

export const MobileMoneySupportedCurrencies = [
  SupportedCurrencies.GHS,
  SupportedCurrencies.KES,
  SupportedCurrencies.UGX,
  SupportedCurrencies.XAF,
  SupportedCurrencies.XAF,
  SupportedCurrencies.ETB,
  SupportedCurrencies.HTG,
];

export const BankTransferSupportedCurrencies = [
  SupportedCurrencies.NGN,
  SupportedCurrencies.XAF,
  SupportedCurrencies.ETB,
];

/*
  @note
  If this map grows too large, we should refactor into a db table
 */
export const assetToSupportedAssetMap = {
  NGN: SupportedCurrencies.NGN,
  USD: SupportedCurrencies.USD,
  GBP: SupportedCurrencies.GBP,
  EUR: SupportedCurrencies.EUR,
  KES: SupportedCurrencies.KES,
  UGX: SupportedCurrencies.UGX,
  GHS: SupportedCurrencies.GHS,
  CAD: SupportedCurrencies.CAD,
  BTC: SupportedCryptoSymbols.BTC,
  ETH: SupportedCryptoSymbols.ETH,
  USDT: SupportedCryptoSymbols.USDT,
  XAF: SupportedCurrencies.XAF,
  ETB: SupportedCurrencies.ETB,
  HTG: SupportedCurrencies.HTG,
};
