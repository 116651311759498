import { Button, Link } from "../../../components";
import { AfriexReferral, AfriexReferralStatus } from "../../../types";

type ReferralSummaryProp = {
  item: AfriexReferral;
  handlePayment: (referralId: string) => void;
  handleBlockPayout: (referralId: string) => void;
};

const ReferralSummary = ({
  item,
  handlePayment,
  handleBlockPayout,
}: ReferralSummaryProp) => (
  <div className="bg-white rounded-lg w-full px-10 py-3">
    <div className="w-full gap-16">
      {/* column 1 user details and card notes */}
      <div>
        <h2 className="text-xl font-semibold">{item.toName}</h2>

        {/* USER details */}
        <div className="flex justify-between items-center w-full">
          <h3 className=" font-semibold">Referral Summary</h3>

          <Link
            to={`/users/${item.fromUserId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="ghost">View referrer details</Button>
          </Link>
          <Link
            to={`/users/${item.toUserId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="ghost">View referree details</Button>
          </Link>
        </div>

        <div className="text-sm text-slate-500 w-full">
          <div className="grid grid-cols-3 gap-3 my-2">
            <div>Referral Code</div>
            <div className="col-span-2">{item.fromUserName}</div>
          </div>

          <div className="grid grid-cols-3 gap-3 my-2">
            <div>Referree</div>
            <div className="col-span-2">{item.toName}</div>
          </div>

          <div className="grid grid-cols-3 gap-3 my-2">
            <div>Referree Username</div>
            <div className="col-span-2">{item.toUserName}</div>
          </div>

          <div className="grid grid-cols-3 gap-3 my-2">
            <div>Transaction Amount So Far</div>
            <div className="col-span-2">{item.cumulativeTransactionAmount}</div>
          </div>

          <div className="grid grid-cols-3 gap-3 my-2">
            <div>Is From Afriex Staff</div>
            <div className="col-span-2">{item.isFromStaff?.toString()}</div>
          </div>

          <div className="grid grid-cols-3 gap-3 my-2">
            <div>Referral Status</div>
            <div className="col-span-2">{item?.status?.toUpperCase()}</div>
          </div>
          <div className="grid grid-cols-3 gap-3 my-2">
            <div>Is Payout Blocked</div>
            <div className="col-span-2">{item.isPayoutBlocked?.toString()}</div>
          </div>
          <div className="flex justify-between items-center w-full">
            <div />

            <Button
              disabled={item?.status !== AfriexReferralStatus.Qualified}
              className="btn border-2 p-2 text-sm bg-blue-500 text-white disabled:opacity-70 disabled:text-slate-200"
              data-tip
              data-for="payoutButton"
              onClick={() => {
                handlePayment((item as any)._id);
                return false;
              }}
              variant={"solid"}
            >
              Pay
            </Button>
            <Button
              disabled={item?.status !== AfriexReferralStatus.Qualified}
              className="btn border-2 p-2 text-sm bg-red-500 text-white disabled:opacity-70 disabled:text-slate-200"
              data-tip
              data-for="blockPayout"
              onClick={() => {
                handleBlockPayout((item as any)._id);
                return false;
              }}
              variant={"solid"}
            >
              {item?.isPayoutBlocked ? "Unblock Payout" : "Block Payout"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ReferralSummary;
