import Header from "../components/common/Header";
import ComingSoon from "../components/common/ComingSoon";

export default function PageNotFoundPage(): JSX.Element {
  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <Header title="Page Not Found" />
      <ComingSoon />
    </div>
  );
}
