import { useParams } from "react-router-dom";
import { Header, Tabs } from "../../../components";
import { TabItem } from "../../../types";
import Summary from "./Summary";
import useCurrencyUpdate from "./useCurrencyUpdate";
import { useEffect } from "react";
import Settings from "./Settings";

const Details = () => {
  const params = useParams();
  const currencyId = params?.currencyCode ?? "";
  const { isLoading, item, handleUpdate, fetchItem } = useCurrencyUpdate();

  useEffect(() => {
    if (currencyId) {
      fetchItem(currencyId);
    }
  }, [currencyId, fetchItem]);

  const tabs: TabItem[] = [
    {
      id: "details",
      title: "General",
      content: <Summary item={item} />,
    },

    {
      id: "users",
      title: " Settings",
      content: <Settings handleSave={handleUpdate} item={item} />,
    },
  ];
  return (
    <div>
      <Header title={`${item?.name ?? ""} Settings`} />
      <Tabs key={isLoading?.toString()} tabs={tabs} queryKey={"tab"} />;
    </div>
  );
};
export default Details;
