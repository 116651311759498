import { Button, TextArea } from "../../../components";

const TierUserBulkUploadForm = ({
  onClose,
  userEmail,
  handleSubmit,
  setUserEmail,
  isDisabled,
  submitLabel,
}: any) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      handleSubmit() as any;
    }}
    className="flex flex-col space-y-5"
  >
    <div className="mt-5">
      <TextArea
        label="Enter email, phone number or id of each user one per line"
        value={userEmail ?? ""}
        type="text"
        onChange={(e: any) => setUserEmail(e.target?.value ?? userEmail)}
      />
    </div>

    <div className="flex w-full justify-end space-x-2 py-5">
      <Button type="button" onClick={onClose} variant="outline">
        Cancel
      </Button>

      <Button
        disabled={isDisabled}
        type="submit"
        variant="outline"
        className="bg-indigo-200 hover:g"
      >
        {submitLabel}
      </Button>
    </div>
  </form>
);

export default TierUserBulkUploadForm;
