import {
  AfriexIDType,
  IDOptionsType,
  SupportedCountryCodes,
} from "../../../types";

export const primaryIDOptions = [
  { value: AfriexIDType.Passport, key: "Passport" },
  { value: AfriexIDType.DriversLicense, key: "Driver's License" },
];

const secondaryIDOptionsNG = [{ value: AfriexIDType.Bvn, key: "BVN" }];

const secondaryIDOptionsUS = [
  { value: AfriexIDType.Ssn, key: "SSN - Full" },
  { value: AfriexIDType.SsnLast4, key: "SSN - Last 4" },
  { value: AfriexIDType.Itin, key: "ITIN" },
];

export const secondaryIDOptions = (
  countryCode: SupportedCountryCodes
): IDOptionsType[] => {
  switch (countryCode) {
    case "NG":
      return secondaryIDOptionsNG;
    case "US":
      return secondaryIDOptionsUS;
    default:
      return [];
  }
};
