import React from "react";
import { Modal, SpinningLoader, Button, Select } from "../../../components";
import { TextArea } from "../../../components/common/Input";
import { AfriexPerson } from "../../../types";
import {
  AfriexBlockReason,
  AfriexUnblockReason,
} from "../../../types/AfriexBlockReason";

type ModalProps = {
  user: AfriexPerson;
  isLoading: boolean;
  isSecurityFlagged: boolean;
  onSubmit: () => void;
  onClose: () => void;
  securityNote: string;
  handleSecurityNoteChange: (val: string) => void;
  handleBlockReason: (val: string) => void;
  handleUnblockReason: (val: string) => void;
  unblockReason: string;
  blockReason: string;
};

export function BlockUserModal({
  user,
  isLoading,
  isSecurityFlagged,
  onClose,
  onSubmit,
  securityNote,
  handleSecurityNoteChange,
  handleBlockReason,
  blockReason,
  unblockReason,
  handleUnblockReason,
}: ModalProps) {
  const verb = isSecurityFlagged ? "Unblock" : "Block";
  const label = `${verb} ${user.name.firstName}`;
  const hasReason = blockReason || unblockReason ? true : false;
  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <h3 className="text-lg ">Enter security note to {label}</h3>
            <div className="text-sm font-light mt-3">
              <TextArea
                placeholder={`${label} because ...`}
                rows={5}
                value={securityNote ?? ""}
                onChange={handleSecurityNoteChange}
                name="securityNote"
              />
            </div>
            {!isSecurityFlagged ? (
              <div className="flex w-full sm:w-2/4 sm:mt-5">
                <Select
                  label="Select Reason"
                  name="reason"
                  placeholder="Select block reason"
                  value={blockReason}
                  options={[
                    {
                      value: AfriexBlockReason.SANCTIONS_MATCH,
                      label: "Sanctions Match",
                    },
                    {
                      value: AfriexBlockReason.IDENTITY_THEFT,
                      label: "Identity Theft",
                    },
                    {
                      value: AfriexBlockReason.MULTIPLE_DISPUTES,
                      label: "Multiple Disputes",
                    },
                    {
                      value: AfriexBlockReason.SOCIAL_ENGINEERING,
                      label: "Social Engineering",
                    },
                    {
                      value: AfriexBlockReason.POSITIVE_DOW_JONES_MATCH,
                      label: "Positive Dow Jones match",
                    },
                    {
                      value: AfriexBlockReason.CONFIRMED_FRAUDULENT_ACTIVITY,
                      label: "Confirmed fraudulent activity",
                    },
                    {
                      value:
                        AfriexBlockReason.FRAUDULENT_ACTIVITY_INVESTIGATION_ONGOING,
                      label: "Fraudulent activity investigation ongoing",
                    },
                    {
                      value: AfriexBlockReason.ACCOUNT_TAKEOVER_CASE,
                      label: "Account takeover case",
                    },
                    {
                      value:
                        AfriexBlockReason.UNAUTHORIZED_TRANSACTIONS_INVESTIGATION_COMPLETED,
                      label:
                        "Unauthorized transactions investigation completed",
                    },
                    {
                      value: AfriexBlockReason.IDENTITY_THEFT_CASE,
                      label: "Identity theft case",
                    },
                    {
                      value: AfriexBlockReason.ACH_FRAUD,
                      label: "ACH fraud",
                    },
                    {
                      value: AfriexBlockReason.OTHERS,
                      label: "Others",
                    },
                  ]}
                  onChange={handleBlockReason}
                />
              </div>
            ) : (
              <div className="flex w-full sm:w-2/4 sm:mt-5">
                <Select
                  label="Select Reason"
                  name="reason"
                  placeholder="Select unblock reason"
                  value={unblockReason}
                  options={[
                    {
                      value: AfriexUnblockReason.EDD_PROVIDED,
                      label: "EDD Provided",
                    },
                    {
                      value: AfriexUnblockReason.KYC_IN_PLACE,
                      label: "KYC Place",
                    },

                    {
                      value: AfriexUnblockReason.WATCHLIST_MATCH_CLEARED,
                      label: "Watchlist Match Cleared",
                    },
                    {
                      value:
                        AfriexUnblockReason.DISPUTE_FUNDS_RECOVERED_FROM_WALLET,
                      label: "Dispute Funds Recovered From Wallet",
                    },
                    {
                      value: AfriexUnblockReason.DISPUTE_RESOVED,
                      label: "Dispute Resolved",
                    },
                    {
                      value: AfriexUnblockReason.CARDS_REVIWED_ON_STRIPE,
                      label: "Cards reviewed on stripe",
                    },
                    {
                      value: AfriexUnblockReason.DATE_OF_BIRTH_UPDATED,
                      label: "Date of birth updated",
                    },
                    {
                      value: AfriexUnblockReason.LEGAL_NAMES_UPDATED,
                      label: "Legal names updated",
                    },
                    {
                      value: AfriexUnblockReason.OTHERS,
                      label: "Others",
                    },
                  ]}
                  onChange={handleUnblockReason}
                />
              </div>
            )}
            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={onClose} variant="outline">
                cancel
              </Button>
              <Button
                disabled={isLoading || !securityNote || !hasReason}
                onClick={onSubmit as any}
                colorScheme={user.isSecurityFlagged ? "orange" : "red"}
              >
                {label}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
