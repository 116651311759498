import { Link } from "../../../components";
import { AfriexReward } from "../../../types";
import { formatDate } from "../../../utils/formatDate";
import UpdateButton from "./UpdateButton";

type SummaryProp = {
  item: AfriexReward;
};

const Summary = ({ item }: SummaryProp) => {
  return (
    <div className="bg-white rounded-lg w-full px-10 py-3">
      <div className="flex justify-between items-center w-full">
        <h3 className=" font-semibold">{item?.name} Details</h3>
      </div>

      <div className="text-sm text-slate-500 w-full">
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Name</div>
          <div className="col-span-2">{item?.name}</div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Description</div>
          <div className="col-span-2">{item?.description ?? ""}</div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Type</div>
          <div className="col-span-2">
            {item?.type?.replace("_", " ")?.toUpperCase() ?? "Not Set"}
          </div>
        </div>

        {item.ttl && (
          <div className="grid grid-cols-3 gap-3 my-2">
            <div>Expires On</div>
            <div className="col-span-2">
              {formatDate(item?.ttl) ?? "Not Set"}
            </div>
          </div>
        )}

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Points Value</div>
          <div className="col-span-2">
            {item?.pointsValue ?? "0"} Afriex Points
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-4">
          <div>Countries Available</div>
          <div className="col-span-2">
            {item?.supportedCountries?.join(", ") || "All"}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Inactive</div>
          <div className="col-span-2">{item?.isDeactivated?.toString()}</div>
        </div>
        <hr />
        <div className="grid grid-cols-3 gap-3 my-4">
          <div className="col-span-6">
            <div className="flex justify-between">
              <UpdateButton initialData={item} />
              <Link
                to={`/rewards/${item?.id}`}
                className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
              >
                Manage {item?.name} Cards
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
