import { Pagination, Table, TBody } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import { AfriexRewardCard } from "../../../types";
import CardUpdateButton from "./CardUpdateButton";
import useReward from "./useReward";
import CardItem from "./CardItem";
import { useEffect } from "react";
import ListHeader from "./CardListHeader";
type Prop = {
  rewardId: string;
};

const CardList = ({ rewardId }: Prop) => {
  const {
    isCardLoading,
    fetchCardList,
    cardList: list,
    cardTotal: total,
  } = useReward();

  useEffect(() => {
    if (rewardId) {
      fetchCardList({}, rewardId);
    }
  }, [rewardId, fetchCardList]);

  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <div className="w-full flex justify-end mb-3">
        <CardUpdateButton rewardId={rewardId} />
      </div>
      <main className="pt-5 py-10 overflow-x-auto">
        <Table>
          <ListHeader />
          <TBody>
            {isCardLoading ? (
              <TableSkeleton />
            ) : (
              list?.map((item) => (
                <CardItem item={item as AfriexRewardCard} key={item.id} />
              ))
            )}
          </TBody>
        </Table>
      </main>

      <footer>
        {list && list.length > 1 && (
          <Pagination
            onPageChange={fetchCardList as any}
            total={total}
            isSearch={false}
          />
        )}
      </footer>
    </div>
  );
};

export default CardList;
