import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { appPaths } from "../../../config/routesConfig";
import { authService } from "../../../services/authService";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { hasAdminRole, parseJwt } from "../../../utils/auth";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";

const useOTP = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const { state } = useLocation();
  const [countdown, setCountdown] = useState<number | null>(null);
  const nextScreen = state?.from?.pathname ?? appPaths.users;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { login } = useAuth();
  const nonceData = localStorage.getItem("nonce");
  const nonce = nonceData?.toString() ?? "";

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!otp) {
        throw new Error("OTP is required");
      }
      if (!nonce) {
        throw new Error("Nonce is required");
      }
      const tokenResponse = await authService.sendOTP(otp, nonce);
      const token = tokenResponse?.tokens?.admin;
      const tokenDetails = parseJwt(token);

      const person = tokenResponse?.person;

      if (!hasAdminRole(token)) {
        // amplitudeEmitter(response.user.email,`adminPortal:logon`, "login", response.user.role, "login", "failure");
        throw new Error("Unauthorized");
      }

      // amplitudeEmitter(response.user.email,`adminPortal:logon`, "login", response.user.role, "login", "success");
      localStorage.setItem("afriex-token", token);
      localStorage.setItem("user-id", person?.id ?? "");
      localStorage.setItem("admin-role", tokenDetails?.role);
      localStorage.setItem("admin-email", person?.currentEmail ?? "");
      localStorage.setItem("admin-firstname", person?.name?.firstName ?? "");
      localStorage.setItem(
        "device-token",
        person?.currentDeviceInfo?.deviceToken ?? ""
      );
      login(tokenDetails?.role, {
        email: person?.currentEmail,
        firstName: person?.name?.firstName,
      });
      showSuccessMessage("Verification Successful");
      setIsLoading(false);

      navigate("/" + nextScreen, { replace: true });
    } catch (error: any) {
      const errorMessage =
        error?.message ?? error.response?.data?.message ?? "";
      setIsLoading(false);
      showErrorMessage(errorMessage ?? "Error Logging in: Contact Engineering");
    }
  }, [login, navigate, nextScreen, otp, nonce]);

  useEffect(() => {
    if (otp && otp.length === 6) {
      handleSubmit();
    }
  }, [handleSubmit, otp]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (countdown !== null && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown !== null && countdown === 0) {
      setCountdown(0);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleResendOTP = () => {
    if (countdown === null || countdown === 0) {
      setCountdown(30);
      authService
        .resendOTP(nonce)
        .then(() => {
          toast.success("Otp resent Successfully");
        })
        .catch((err) => {
          const errorMessage = err.message || err.response?.data?.message;
          toast.error(errorMessage || "Error sending Otp");
        });
    }
  };

  return {
    isLoading,
    email: state?.email,
    otp,
    handleOTPChange: setOtp,
    handleResendOTP,
    countdown,
  };
};
export default useOTP;
