import { Button } from "../../../components";
import { ClipboardLabel } from "../../../components";
import { AfriexOTCVolume } from "../../../types";
import { formatDate, formatTime } from "../../../utils/formatDate";
import { formatName } from "../../../utils/formatName";
import OTCUpdateButton from "./OTCUpdateButton";

type OTCSummaryProp = {
  item: AfriexOTCVolume;
  handleApprove: (id: string) => void;
};

const OTCSummary = ({ item, handleApprove }: OTCSummaryProp) => {
  return (
    <div className="bg-white rounded-lg w-full px-10 py-3">
      <div className="flex justify-between items-center w-full">
        <h3 className=" font-semibold">OTC Details</h3>
        {!item?.approvedBy?.id ? <OTCUpdateButton initialData={item} /> : null}
      </div>

      <div className="text-sm text-slate-500 w-full">
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Reference ID</div>
          <div className="col-span-2">
            <ClipboardLabel>
              {item?.id ?? (item as any)?._id ?? ""}
            </ClipboardLabel>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Date of Input</div>
          <div className="col-span-2">
            {formatDate(item?.createdAt?.toString() ?? "")}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Date of Transaction</div>
          <div className="col-span-2">
            {formatDate(item?.timestamp?.toString() ?? "")}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Time of Transaction</div>
          <div className="col-span-2">
            {formatTime(item?.timestamp?.toString() ?? "")}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Country of Trade</div>
          <div className="col-span-2">{item.country}</div>
        </div>
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Amount Sent</div>
          <div className="col-span-2">
            {item.fromAmount?.toLocaleString()} {item.fromSymbol}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Amount Received</div>
          <div className="col-span-2">
            {item.toAmount?.toLocaleString()} {item.toSymbol}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Balance Remaining</div>
          <div className="col-span-2">
            {item.availableAmount?.toLocaleString()} {item.toSymbol}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Rate</div>
          <div className="col-span-2">{item.rate?.toLocaleString()}</div>
        </div>
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Submitted By</div>
          <div className="col-span-2">{formatName(item.submittedBy)}</div>
        </div>
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Approved By</div>
          <div className="col-span-2">
            {item.approvedBy ? (
              <span>{formatName(item.approvedBy)}</span>
            ) : (
              <div className="flex justify-between">
                <span className="text-red-500">Not Approved </span>
                <Button
                  variant={"solid"}
                  className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
                  onClick={() => {
                    handleApprove(item.id);
                    return false;
                  }}
                >
                  Approve
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTCSummary;
