import SpiningLoader from "../../../components/common/SpinningLoader";
import { AfriexReferralTier } from "../../../types";
import { formatDate } from "../../../utils/formatDate";
import { formatName } from "../../../utils/formatName";
import TierUpdateButton from "./SignupBonusUpdateButton";
type TierSummaryProp = {
  item: AfriexReferralTier;
  isLoading?: boolean;
  isDetail?: boolean;
};

const SignupBonusSummary = ({ item, isLoading }: TierSummaryProp) => {
  return isLoading ? (
    <SpiningLoader />
  ) : (
    <div className="bg-white rounded-lg w-full px-10 py-3">
      <div className="w-full gap-16">
        <div>
          <div className="flex justify-between items-center w-full">
            <span className=" font-semibold text-blue-500">{item.name}</span>
          </div>

          <div className="text-sm text-slate-500 w-full">
            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Bonus Amount Per User</div>
              <div className="col-span-2 text-gray-400">
                {item.bonusAmountPerReferree} {item.currencyCode}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Referral Code</div>
              <div className="col-span-2 text-gray-400">
                {item.referralCode}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Status</div>
              <div className="col-span-2">
                {!item?.isDeactivated ? (
                  <span className="text-green-400">Active</span>
                ) : (
                  <span className="text-gray-300">Inactive</span>
                )}
              </div>
            </div>
            {item.admin && (
              <div className="grid grid-cols-3 gap-3 my-2">
                <div>Approved By</div>
                <div className="col-span-2">
                  <span>{formatName(item.admin)}</span>
                </div>
              </div>
            )}

            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Date Created</div>
              <div className="col-span-2 text-gray-400">
                {formatDate(item.createdAt?.toString() ?? "")}
              </div>
            </div>
            <div className="flex justify-between items-center w-full">
              <TierUpdateButton initialData={item} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupBonusSummary;
