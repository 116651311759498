import { ReactElement } from "react";
import { SupportedCurrencies } from "../../types";
import { Select } from "../Select";
interface CurrencyPickersProps {
  onChange: (value: SupportedCurrencies, event: any) => void;
  value?: SupportedCurrencies;
  label?: string;
}

const mapOptionsToList = (options: SupportedCurrencies[]) =>
  options.map((currency: SupportedCurrencies) => ({
    value: currency,
    label: currency,
  }));

const CurrencyPicker = ({
  label,
  onChange,
  value,
}: CurrencyPickersProps): ReactElement => {
  const options = mapOptionsToList(Object.values(SupportedCurrencies));
  return (
    <div className="mb-4 mr-2">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor={label}
      >
        {label}
      </label>
      <Select
        options={options}
        defaultValue={value}
        onChange={(e: any) => onChange(e.target.value, e)}
        className="rounded-l-md border-r-1 border-l-1  border-gray-300  text-gray-900 focus:ring-indigo-500 focus:border-blue-500 block flex-1 min-w-0 w-full sm:text-sm p-2.5"
      />
    </div>
  );
};
export default CurrencyPicker;
