import { Link } from "react-router-dom";
import { Button } from "../../../components";
import SpiningLoader from "../../../components/common/SpinningLoader";
import { AfriexRateInfo, AfriexTier } from "../../../types";
import { formatDate } from "../../../utils/formatDate";
import { formatName } from "../../../utils/formatName";
import { toTitleCase } from "../../../utils/services/toTitleCase";
import TierUpdateButton from "./TierUpdateButton";

type TierSummaryProp = {
  item: AfriexTier;
  rateInfo?: AfriexRateInfo;
  isLoading?: boolean;
  isDetail?: boolean;
};

const TierSummary = ({
  item,
  isDetail,
  isLoading,
  rateInfo,
}: TierSummaryProp) => {
  return isLoading ? (
    <SpiningLoader />
  ) : (
    <div className="bg-white rounded-lg w-full px-10 py-3">
      <div className="w-full gap-16">
        <div>
          <div className="flex justify-between items-center w-full">
            <span className=" font-semibold text-blue-500">{item.name}</span>
          </div>

          <div className="text-sm text-slate-500 w-full">
            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Tier ID</div>
              <div className="col-span-2 text-gray-400">{item?.id}</div>
            </div>
            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Discount</div>
              <div className="col-span-2 text-gray-400">{item.discount}%</div>
            </div>

            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Maximum Allowed Transactions</div>
              <div className="col-span-2 text-gray-400">
                {item.transactionCountThreshold}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Promo Band</div>
              <div className="col-span-2 text-gray-400">
                {toTitleCase(item?.code?.replace("_", " ") ?? "")}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Countries Allowed</div>
              <div className="col-span-2 text-gray-400">
                {item.countries?.join(", ") ?? "ALL"}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Tier Status</div>
              <div className="col-span-2">
                {item?.isActive ? (
                  <span className="text-green-400">Active</span>
                ) : (
                  <span className="text-gray-300">Inactive</span>
                )}
              </div>
            </div>
            {item.admin && (
              <div className="grid grid-cols-3 gap-3 my-2">
                <div>Approved By</div>
                <div className="col-span-2">
                  <span>{formatName(item.admin)}</span>
                </div>
              </div>
            )}

            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Date Created</div>
              <div className="col-span-2 text-gray-400">
                {formatDate(item.createdAt?.toString() ?? "")}
              </div>
            </div>
            <div className="flex justify-between items-center w-full">
              {!isDetail ? (
                <Link
                  to={`/tiers/${item.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="ghost">View details</Button>
                </Link>
              ) : (
                <div />
              )}

              <TierUpdateButton initialData={item} rateInfo={rateInfo} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TierSummary;
