import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import UpdateForm from "./UpdateForm";
import { AfriexReward } from "../../../types";
import { isEmpty } from "lodash";
import useReward from "./useReward";

type UpdateButtonProps = {
  initialData?: AfriexReward;
};

const UpdateButton = ({ initialData }: UpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const { handleAdd, handleUpdate } = useReward();
  const updateLabel = !isEmpty(initialData)
    ? "Update Reward"
    : "Add New Reward";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <UpdateForm
      onClose={toggleFullScreen}
      handleSave={!isEmpty(initialData) ? handleUpdate : handleAdd}
      initialData={initialData}
    />
  ) : (
    <Button
      variant={"solid"}
      className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
      onClick={toggleFullScreen}
    >
      {updateLabel}
    </Button>
  );
};

export default UpdateButton;
