import { Link } from "react-router-dom";
import { Button } from "../../../components";
import SpiningLoader from "../../../components/common/SpinningLoader";
import { AfriexReferralTier } from "../../../types";
import { formatDate } from "../../../utils/formatDate";
import { formatName } from "../../../utils/formatName";
import TierUpdateButton from "./TierUpdateButton";
import { Fragment } from "react";

type TierSummaryProp = {
  item: AfriexReferralTier;
  isLoading?: boolean;
  isDetail?: boolean;
};

const TierSummary = ({ item, isDetail, isLoading }: TierSummaryProp) => {
  const shouldSkipAllPayout =
    item.shouldSkipReferrerPayout && item.shouldSkipReferreePayout;
  return isLoading ? (
    <SpiningLoader />
  ) : (
    <div className="bg-white rounded-lg w-full px-10 py-3">
      <div className="w-full gap-16">
        <div>
          <div className="flex justify-between items-center w-full">
            <span className=" font-semibold text-blue-500">{item.name}</span>
          </div>

          <div className="text-sm text-slate-500 w-full">
            {!shouldSkipAllPayout && (
              <Fragment>
                {" "}
                <div className="grid grid-cols-3 gap-3 my-2">
                  <div>Cumulative Transaction Threshold</div>
                  <div className="col-span-2 text-gray-400">
                    {item.cumulativeThreshold} USD
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-3 my-2">
                  <div>Bonus Payout Amount Per Referrer</div>
                  <div className="col-span-2 text-gray-400">
                    {item.bonusAmountPerReferrer} USD
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-3 my-2">
                  <div>Bonus Payout Amount Per Referred User</div>
                  <div className="col-span-2 text-gray-400">
                    {item.bonusAmountPerReferree} USD
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-3 my-2">
                  <div>Transaction Window</div>
                  <div className="col-span-2 text-gray-400">
                    {item.transactionWindow} days
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-3 my-2">
                  <div>Should Automate Payout</div>
                  <div className="col-span-2">
                    {item?.shouldAutomatePayout ? (
                      <span className="text-green-400">Yes</span>
                    ) : (
                      <span className="text-gray-300">No</span>
                    )}
                  </div>
                </div>
                {item?.shouldSkipReferrerPayout && (
                  <div className="grid grid-cols-3 gap-3 my-2">
                    <div>Should Skip Referrer Payout</div>
                    <div className="col-span-2">
                      {item?.shouldSkipReferrerPayout ? (
                        <span className="text-green-400">Yes</span>
                      ) : (
                        <span className="text-gray-300">No</span>
                      )}
                    </div>
                  </div>
                )}
                {item?.shouldSkipReferreePayout && (
                  <div className="grid grid-cols-3 gap-3 my-2">
                    <div>Should Skip Referee Payout</div>
                    <div className="col-span-2">
                      {item?.shouldSkipReferreePayout ? (
                        <span className="text-green-400">Yes</span>
                      ) : (
                        <span className="text-gray-300">No</span>
                      )}
                    </div>
                  </div>
                )}
              </Fragment>
            )}
            {item?.referralCode && (
              <div className="grid grid-cols-3 gap-3 my-2">
                <div>Referral Code</div>
                <div className="col-span-2 text-gray-400">
                  {item.referralCode}
                </div>
              </div>
            )}
            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Status</div>
              <div className="col-span-2">
                {!item?.isDeactivated ? (
                  <span className="text-green-400">Active</span>
                ) : (
                  <span className="text-gray-300">Inactive</span>
                )}
              </div>
            </div>
            {item.admin && (
              <div className="grid grid-cols-3 gap-3 my-2">
                <div>Approved By</div>
                <div className="col-span-2">
                  <span>{formatName(item.admin)}</span>
                </div>
              </div>
            )}

            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Date Created</div>
              <div className="col-span-2 text-gray-400">
                {formatDate(item.createdAt?.toString() ?? "")}
              </div>
            </div>
            <div className="flex justify-between items-center w-full">
              {!isDetail ? (
                <Link
                  to={`/referral/tiers/${item.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="ghost">
                    {item.referralCode
                      ? "View Tier Details"
                      : "Manage Tier Users"}
                  </Button>
                </Link>
              ) : (
                <div />
              )}

              <TierUpdateButton initialData={item} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TierSummary;
