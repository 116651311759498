import { ReactElement } from "react";
import * as formatDate from "../../../utils/dateFormatter";
import { AfriexPerson } from "../../../types";
import { Link } from "react-router-dom";
import { Button } from "../../../components";
import { getUXCamUserSessionURL } from "../../../utils/getUXCamUserSessionURL";
import VirtualAccountInfo from "../../KYC/Details/VirtualAccountInfo";
import useKYCDetails from "../../KYC/Details/useKYCDetails";
import { isEmpty } from "lodash";

export interface UserProfileProps {
  user: AfriexPerson;
  type?: "user" | "kyc";
  showUserID?: boolean;
  showPhone?: boolean;
  showPhoneVerified?: boolean;
  showReferredBy?: boolean;
  showSecurityEnabled?: boolean;
  showKYCStatus?: boolean;
  showUXCamSessions?: boolean;
  showUsername?: boolean;
  showDateOpened?: boolean;
  showDeviceId?: boolean;
  showLastLogin?: boolean;
  showEmail?: boolean;
  showViewTransactions?: boolean;
  showVirtualAccount?: boolean;
}

const UserProfile = ({
  user,
  type = "user",
  showUserID,
  showPhone,
  showPhoneVerified,
  showReferredBy,
  showSecurityEnabled,
  showKYCStatus,
  showUXCamSessions,
  showUsername,
  showDateOpened,
  showDeviceId,
  showLastLogin,
  showEmail,
  showViewTransactions,
  showVirtualAccount,
}: UserProfileProps): ReactElement => {
  const { virtualAccountInfo } = useKYCDetails(user?.id);
  return (
    <div>
      {/* Email */}
      {showEmail && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Account Email</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {user.currentEmail}
          </p>
        </div>
      )}

      {/* Username */}
      {showUsername && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Account Username</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {user.name?.userName}
          </p>
        </div>
      )}

      {/* User ID */}
      {showUserID && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">User ID</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            <span className="mr-3">{user.id}</span>
            {type !== "user" && (
              <Link
                to={`/users/${user.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="ghost" className="no-padding no-margin">
                  View User Profile
                </Button>
              </Link>
            )}
          </p>
        </div>
      )}

      {/* Phone */}
      {showPhone && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Phone Number</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {user?.currentPhone}
          </p>
        </div>
      )}

      {/* Phone Verified */}
      {showPhoneVerified && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Phone Verified</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {String(Boolean(user?.isPhoneVerified))}
          </p>
        </div>
      )}

      {/* Referred By */}
      {showReferredBy && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Referred By</p>
          {user?.referralInfo?.referrerId ? (
            <p className="text-sm font-medium text-gray-700 w-1/2">
              {user?.referralInfo?.referrerId} (
              {user?.referralInfo?.referrerUserName})
            </p>
          ) : (
            <p className="text-sm font-medium text-gray-700 w-1/2">None</p>
          )}
        </div>
      )}

      {/* Security Enabled */}
      {showSecurityEnabled && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Security Enabled</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {user.isSecurityFlagged?.toString()}
          </p>
        </div>
      )}

      {/* KYC Status */}
      {showKYCStatus && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">KYC Status</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            <span className="mr-3">{user?.kyc?.status?.toUpperCase()}</span>
            {type !== "kyc" && (
              <Link
                to={`/kyc/${user.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="ghost" className="no-padding no-margin">
                  View KYC details
                </Button>
              </Link>
            )}
          </p>
        </div>
      )}

      {/* UXCam Sessions */}
      {showUXCamSessions && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">UXCam Sessions</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            <Link
              to={getUXCamUserSessionURL(user.id)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="ghost" className="no-padding no-margin">
                Click to view UXCam Sessions
              </Button>
            </Link>
          </p>
        </div>
      )}

      {/* Date Opened */}
      {showDateOpened && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Date Opened</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatDate.DDMonYYYY(user?.createdAt?.toString() ?? "")}
          </p>
        </div>
      )}

      {/* Device ID */}
      {showDeviceId && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Device ID</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {user.currentDeviceInfo?.deviceId ||
              (Array.isArray(user.devices) &&
              user.devices.filter(Boolean).length > 0
                ? user.devices.filter(Boolean)[
                    user.devices.filter(Boolean).length - 1
                  ]?.deviceId || "N/A"
                : "N/A")}
          </p>
        </div>
      )}

      {/* Last Login */}
      {showLastLogin &&
        (user.lastLoggedInAt ? (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Last Login</p>
            <p className="text-sm font-medium text-gray-700 w-1/2">
              {formatDate.dateTimeInTimeZone(user.lastLoggedInAt)}
            </p>
          </div>
        ) : null)}

      {/* View Transactions */}
      {showViewTransactions && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Transactions</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            <Link
              to={`/transactions/user/${user.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="ghost" className="no-padding no-margin">
                View Transactions
              </Button>
            </Link>
          </p>
        </div>
      )}

      {showVirtualAccount && !isEmpty(virtualAccountInfo) && (
        <VirtualAccountInfo data={virtualAccountInfo} />
      )}
    </div>
  );
};

export default UserProfile;
