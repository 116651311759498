import Modal from "../common/Modal";
import { XCircleIcon } from "@heroicons/react/solid";

type ModalProps = {
  children?: React.ReactNode;
  onClose: () => void;
};

export function ViewModal({ onClose, children }: ModalProps) {
  return (
    <Modal modalOpen>
      <div className="w-99 my-3 mx-2">
        <div className="w-full flex justify-end">
          <button onClick={onClose}>
            <XCircleIcon className="h-5 w-5 text-red-900" />
          </button>
        </div>
        {children}
      </div>
    </Modal>
  );
}
