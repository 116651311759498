import { ReactElement, useEffect, useState } from "react";
import { AfriexVirtualApplication } from "../../../types";
import * as formatDate from "../../../utils/dateFormatter";
import { Button } from "../../../components";
import { capitalizeWords } from "../../../helpers/dashboard";
import { kycBucketKey, fileService } from "../../../services/fileService";
import { renderFile } from "../../../utils/imageFiles";

interface VirtualAccountApplicationProps {
  data: AfriexVirtualApplication;
}

const VirtualAccountApplication = ({
  data,
}: VirtualAccountApplicationProps): ReactElement => {
  const docName = data?.nameOfDocument
    ? data?.nameOfDocument
    : "proof_of_income";
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const doc = (
      data?.document && data?.document?.length > "VirtualAccount/".length
        ? data?.document
        : ""
    ) as string;
    const getDocumentUrl = async () => {
      const getUrl = await fileService.fetchUploadedFile(doc, kycBucketKey);
      setUrl(getUrl);
    };

    if (doc?.length) getDocumentUrl();
  }, [data?.document]);

  return (
    <div>
      {url && (
        <div className="mb-2 py-2 flex items-center gap-3 justify-between">
          <p className="text-sm font-medium text-gray-700 flex flex-col gap-1">
            {renderFile(url, docName, "w-40")}
            <span className="flex items-center gap-2">
              {capitalizeWords(docName.replace(/_/g, " "))}
              <Button
                onClick={() => window.open(url, "_blank")}
                variant="ghost"
                className="no-padding no-margin"
              >
                View Image
              </Button>
            </span>
          </p>
        </div>
      )}

      {/* Application Date */}
      {data?.createdAt && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Application Date</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatDate.DDMonYYYY(data.createdAt?.toString() ?? "")}
          </p>
        </div>
      )}

      {/* Monthly Income */}
      {data?.monthlyIncome && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Monthly Income</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {data.monthlyIncome} USD
          </p>
        </div>
      )}

      {/* Employment Status */}
      {data?.employmentStatus && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Employment Status</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {capitalizeWords(data.employmentStatus.replace(/-/g, " ") ?? "")}
          </p>
        </div>
      )}

      {/* SSN */}
      {data?.ssn && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">SSN</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">{data.ssn}</p>
        </div>
      )}

      {/* LinkedIn */}
      {data?.linkedInProfile && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">LinkedIn</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            <Button
              onClick={() =>
                window.open(data.linkedInProfile?.toLocaleLowerCase(), "_blank")
              }
              variant="ghost"
              className="no-padding no-margin"
            >
              View Profile
            </Button>
          </p>
        </div>
      )}

      {/* Status */}
      {data?.status && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Status</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {capitalizeWords(data?.status)}
          </p>
        </div>
      )}

      {/* Upated */}
      {data?.createdAt && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Updated On</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatDate.DDMonYYYY(data.updatedAt?.toString() ?? "")}
          </p>
        </div>
      )}
    </div>
  );
};

export default VirtualAccountApplication;
