import { SupportedCurrencies } from "./AssetTypes";

export enum SupportedCountryCodes {
  NG = "NG",
  GB = "GB",
  US = "US",
  CA = "CA",
  KE = "KE",
  GH = "GH",
  UG = "UG",
  CM = "CM",
  ET = "ET",
  HT = "HT",
  AT = "AT",
  BE = "BE",
  HR = "HR",
  CY = "CY",
  EE = "EE",
  FI = "FI",
  FR = "FR",
  DE = "DE",
  GR = "GR",
  IE = "IE",
  IT = "IT",
  LV = "LV",
  LT = "LT",
  LU = "LU",
  MT = "MT",
  NL = "NL",
  PT = "PT",
  SK = "SK",
  SI = "SI",
  ES = "ES",
}

export const CountryCodeToCurrencyCodeMap = {
  [SupportedCountryCodes.NG]: SupportedCurrencies.NGN,
  [SupportedCountryCodes.GB]: SupportedCurrencies.GBP,
  [SupportedCountryCodes.US]: SupportedCurrencies.USD,
  [SupportedCountryCodes.CA]: SupportedCurrencies.CAD,
  [SupportedCountryCodes.KE]: SupportedCurrencies.KES,
  [SupportedCountryCodes.GH]: SupportedCurrencies.GHS,
  [SupportedCountryCodes.UG]: SupportedCurrencies.UGX,
  [SupportedCountryCodes.CM]: SupportedCurrencies.XAF,
  [SupportedCountryCodes.ET]: SupportedCurrencies.ETB,
  [SupportedCountryCodes.HT]: SupportedCurrencies.HTG,
  [SupportedCountryCodes.AT]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.BE]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.HR]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.CY]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.EE]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.FI]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.FR]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.DE]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.GR]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.IE]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.IT]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.LV]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.LT]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.LU]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.MT]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.NL]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.PT]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.SK]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.SI]: SupportedCurrencies.EUR,
  [SupportedCountryCodes.ES]: SupportedCurrencies.EUR,
};

export const CurrencyCodeToCountryCodeMap = {
  [SupportedCurrencies.NGN]: SupportedCountryCodes.NG,
  [SupportedCurrencies.GBP]: SupportedCountryCodes.GB,
  [SupportedCurrencies.USD]: SupportedCountryCodes.US,
  [SupportedCurrencies.CAD]: SupportedCountryCodes.CA,
  [SupportedCurrencies.KES]: SupportedCountryCodes.KE,
  [SupportedCurrencies.GHS]: SupportedCountryCodes.GH,
  [SupportedCurrencies.UGX]: SupportedCountryCodes.UG,
  [SupportedCurrencies.XAF]: SupportedCountryCodes.CM,
  [SupportedCurrencies.ETB]: SupportedCountryCodes.ET,
  [SupportedCurrencies.HTG]: SupportedCountryCodes.HT,
  [SupportedCurrencies.EUR]: SupportedCountryCodes.AT,
};

export const stripeSupportedCountries = [
  SupportedCountryCodes.GB,
  SupportedCountryCodes.CA,
  SupportedCountryCodes.US,
];

export const isStripeCountry = (country: SupportedCountryCodes): boolean =>
  stripeSupportedCountries.includes(country);

export const swapAllowedCountries = ["US"];
