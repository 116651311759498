import { convertRatesListToMap } from "../../../utils/rates/convertRatesListToMap";
import { RateInfoProps } from "../types";
import RatesTable from "./RatesTable";

const RatesList = ({ isLoading, rateInfo }: RateInfoProps) => {
  const appRates = convertRatesListToMap(rateInfo?.appRates ?? []);
  return (
    <div className="container py-6 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      {isLoading ? <p>Loading...</p> : <RatesTable rates={appRates} />}
    </div>
  );
};

export default RatesList;
