import "react-confirm-alert/src/react-confirm-alert.css";
import { getObject } from "../../../api/monitor";
import DataTable from "react-data-table-component";
import { useState } from "react";
import IndexCreationModal from "./IndexModal";
import csv from "csvtojson";

type IndexCreationFormProps = {
  data: [];
};

export default function IndexCreationForm({
  data,
}: IndexCreationFormProps): JSX.Element {
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [openIndexCreation, setIndexCreation] = useState(false);
  const [indexCreationItems, setIndexCreationItems] = useState<any>({} as any);

  function displayCreatedIndexes(key: string) {
    setWaitingForResponse(true);
    setIndexCreation(true);
    getObject(key).then(
      (result) => {
        csv({
          noheader: true,
          delimiter: ",",
          headers: ["db", "collection", "key"],
        })
          .fromString(result)
          .then((jsonObj: any) => {
            setIndexCreationItems(jsonObj);
            setWaitingForResponse(false);
          });
      },
      function (error) {
        alert(`erro=${error}`);
        setIndexCreation(false);
      }
    );
  }

  function toggleCreationModal() {
    setIndexCreation(false);
  }

  const columns = [
    {
      name: "Date",
      button: true,
      cell: (row: any) => (
        <button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => displayCreatedIndexes(row.Key)}
        >
          {row.Key.split("/")[1]}
        </button>
      ),
    },

    {
      name: "Last Modified",
      selector: (row: any) => row.LastModified,
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <div className="mt-10">
      <div className="my-4">
        <div>List of created Indexes</div>
        <div className="flex space-x-4">
          <div className="w-full grid grid-cols-1 gap-4 items-center">
            {openIndexCreation && (
              <IndexCreationModal
                data={indexCreationItems}
                loading={waitingForResponse}
                onClose={toggleCreationModal}
              />
            )}
            <DataTable columns={columns} pagination data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
