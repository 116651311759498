import React from "react";
interface ModalProps {
  children?: React.ReactNode;
  modalOpen: boolean;
}

export default function Modal(props: ModalProps) {
  return (
    <>
      {props.modalOpen ? (
        <>
          <div className=" h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded shadow-xl relative flex flex-col w-full bg-white outline-none focus:outline-none p-5 overflow-y-auto max-h-screen">
                {props.children}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
