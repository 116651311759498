import { CreateNewPromotionForm } from "./CreateNewPromotionForm";
import { usePromotions } from "./usePromotions";
import { PromotionsTable } from "./PromotionsTable/PromotionsTable";

export interface AfriexTier {
  name: string;
  discount: number;
  isActive: boolean;
  transactionCountThreshold: number;
  type: string;
  id: string;
}

export type preSaveTier = Omit<AfriexTier, "id">;

export default function PromotionsPage() {
  const { promotions, createPromotion } = usePromotions();
  return (
    <div className={`flex flex-col`}>
      <div className={"p-1"}>
        <CreateNewPromotionForm
          onCreatePromotion={createPromotion}
        ></CreateNewPromotionForm>
      </div>

      <div className={`mt-4 p-2 border border-solid w-full h-full rounded-2xl`}>
        <PromotionsTable promotions={promotions}></PromotionsTable>
      </div>
    </div>
  );
}
