import "react-confirm-alert/src/react-confirm-alert.css";
import DataTable from "react-data-table-component";
import Modal from "../../common/Modal";
import SpinningLoader from "../../common/SpinningLoader";
import Button from "../../common/Button";

type KeyHoleAnalysisProps = {
  data: [];
  onClose: () => void;
  loading: boolean;
};

export default function KeyHoleAnalysisModal({
  data,
  onClose,
  loading,
}: KeyHoleAnalysisProps): JSX.Element {
  const columns = [
    {
      name: "Row",
      cell: (row: any) => row.row,
    },
    {
      name: "Category",
      cell: (row: any) => row.category,
    },
    {
      name: "Average Time",
      selector: (row: any) => row.avgTime,
      sortable: true,
      wrap: true,
    },
    {
      name: "Max Time",
      button: true,
      cell: (row: any) => row.maxTime,
    },
    {
      name: "Count",
      selector: (row: any) => row.count,
      sortable: true,
      wrap: true,
    },
    {
      name: "Total Time",
      button: true,
      cell: (row: any) => row.totalTime,
    },
    {
      name: "Total Reslen",
      selector: (row: any) => row.totalReslen,
      sortable: true,
      wrap: true,
    },
    {
      name: "Namespace",
      button: true,
      cell: (row: any) => row.namespace,
    },
    {
      name: "Collscan",
      selector: (row: any) => row.collscan,
      sortable: true,
      wrap: true,
    },
    {
      name: "Indexe(s) Used",
      button: true,
      cell: (row: any) => row.indexesUsed,
    },
    {
      name: "Query Pattern",
      selector: (row: any) => row.queryPattern,
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <Modal modalOpen>
      <div className="max-h-screen overflow-y-auto my-3 mx-5">
        <h3 className="text-lg text-center">Key Hole Analysis</h3>
        <br />
        <br />
        {loading ? (
          <div className="flex w-full h-full place-content-stretch justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <div className="w-full grid place-content-stretch grid-cols-1 gap-4 items-center divide-y ">
              <div className="w-full grid grid-cols-1 place-content-stretch">
                <DataTable columns={columns} pagination data={data} />
              </div>

              <div className="flex w-full col-span-2 justify-center space-x-2 py-5">
                <Button onClick={onClose} variant="outline">
                  Done
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
