import { ReactElement } from "react";
import { Th, Thead, Tr } from "../../../components";
import { ratesHeaderHue } from "../OTC/RatesTable";

const ListHeader = (): ReactElement => (
  <Thead className={ratesHeaderHue}>
    <Tr>
      <Th></Th>
      <Th>Sent</Th>
      <Th>Received</Th>
      <Th>Rate</Th>
      <Th>Date</Th>
    </Tr>
  </Thead>
);

export default ListHeader;
