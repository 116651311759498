import { Table, TBody, Td, Th, Thead, Tr } from "../../../components";
import { SupportedCurrencies } from "../../../types";
import RateItem from "./RateItem";

export type RatesTableProp = {
  rates: any;
  onRatesEdited?: (
    fromCurrency: SupportedCurrencies,
    toCurrency: SupportedCurrencies,
    newRate: number
  ) => void;
  isEditable?: boolean;
};

export const ratesHeaderHue = "bg-blue-50 p-8 shadow-sm sticky";

const RatesTable = ({ rates, onRatesEdited }: RatesTableProp) => (
  <div className="py-3 overflow-x-auto" style={{ width: "100%" }}>
    <Table>
      <Thead className={ratesHeaderHue}>
        <Tr>
          <Th />
          <>
            {Object.keys(rates)?.map((currencySymbol) => (
              <Th key={currencySymbol}>{currencySymbol?.trim()}</Th>
            ))}
          </>
        </Tr>
      </Thead>
      <TBody>
        <>
          {Object.keys(rates).map((baseCurrency) => (
            <Tr key={baseCurrency} className="zebra">
              <Td className="sticky top-0">{baseCurrency}</Td>
              <>
                {Object.keys(rates).map((quoteCurrency: string, i: number) => (
                  <RateItem
                    key={i}
                    rate={
                      rates?.[baseCurrency]
                        ? rates?.[baseCurrency][quoteCurrency]
                        : 0
                    }
                    baseCurrency={baseCurrency}
                    quoteCurrency={quoteCurrency}
                    isEditable={true}
                    onRatesEdited={onRatesEdited}
                  />
                ))}
              </>
            </Tr>
          ))}
        </>
      </TBody>
    </Table>
  </div>
);

export default RatesTable;
