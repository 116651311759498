import { Header, Pagination } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import TransactionSearch from "./TransactionSearch";
import useTransactionList from "./useTransactionList";
import TransactionTable from "./TransactionTable";

const TransactionList = () => {
  const {
    isLoading,
    isSearch,
    isPageReset,
    fetchTransactionList,
    pageInfo,
    transactionList,
    filterCount,
    currentFilters,
    filtersToApply,
    handleApplyFilters,
    clearFilters,
    selectCurrentFilters,
    selectAppliedFilters,
  } = useTransactionList();
  const countSuffix = pageInfo.total > 0 ? `(${pageInfo.total})` : "";
  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={`Transactions ${countSuffix}`} />

      <TransactionSearch
        transactions={transactionList}
        filterCount={filterCount}
        currentFilters={currentFilters}
        filtersToApply={filtersToApply}
        clearFilters={clearFilters}
        selectCurrentFilters={selectCurrentFilters}
        selectAppliedFilters={selectAppliedFilters}
        handleApplyFilters={handleApplyFilters}
      />

      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <TransactionTable items={transactionList} />
        )}
      </section>

      {transactionList && transactionList.length > 1 && (
        <Pagination
          onPageChange={fetchTransactionList as any}
          total={pageInfo.total}
          isSearch={isSearch}
          isPageReset={isPageReset}
        />
      )}
    </main>
  );
};

export default TransactionList;
