import { ReactNode, useCallback } from "react";
import OtpInput from "react-otp-input";
import OtpVerifyImg from "../../../assets/svg/otp-verify.svg";
import { SpinningLoader } from "../../../components";

const OTPForm = ({
  isLoading,
  email,
  otp,
  handleOTPChange,
  handleResendOTP,
  countdown,
}: any) => {
  const renderButton = useCallback((): ReactNode => {
    let button: React.ReactNode;
    if (countdown === null) {
      button = <button onClick={handleResendOTP}>Send a new code</button>;
    } else if (countdown > 0) {
      const minutes = Math.floor(countdown / 60);
      const seconds = countdown % 60;
      const timeStr = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
      button = <button disabled>{timeStr}</button>;
    }
    if (countdown === 0) {
      button = <button onClick={handleResendOTP}>Resend code</button>;
    }
    return button;
  }, [countdown, handleResendOTP]);
  return (
    <div className="flex justify-center items-center h-screen">
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center my-5">
          <SpinningLoader size={14} />
        </div>
      ) : (
        <div className="border border-slate-200 flex flex-col items-center md:h-3/4 lg:h-3/4 xl:h-3/4 md:w-full lg:w-full xl:w-full rounded-xl">
          <h2 className="font-semibold text-center text-3xl md:w-3/4 lg:w-3/4 xl:w-3/4 mt-10 w-80 h-12 font-sans">
            Verification required
          </h2>

          <h3 className="text-center md:w-3/4 lg:w-3/4 xl:w-3/4 h-12 mt-5 text-2xl font-sans">
            Enter the code sent to {email}
          </h3>
          <img src={OtpVerifyImg} alt="loading..." className="w-20 h-24 mt-5" />
          <OtpInput
            containerStyle="mt-10"
            value={otp}
            onChange={handleOTPChange}
            numInputs={6}
            renderSeparator={<span style={{ width: "8px" }}></span>}
            shouldAutoFocus={true}
            inputStyle={{
              border: "1px solid",
              borderColor: "gray",
              borderRadius: "8px",
              width: "48px",
              height: "48px",
              fontSize: "16px",
              color: "#000",
              fontWeight: "400",
              caretColor: "blue",
            }}
            renderInput={(props) => <input {...props} />}
          />
          <h3 className="font-sans mt-5 font-bold text-l text-purple-500">
            {renderButton()}
          </h3>
          <div className="border-b border-slate-200 md:w-3/4 lg:w-3/4 xl:w-3/4 mt-10" />
          <div className="w-80 m-5 bg-purpleBg-500 rounded-xl text-center md:w-full lg:w-full xl:w-full">
            <p className="p-1 text-sm font-sans">
              {
                "If you're unable to locate your verification code, please ensure to look through your spam folder"
              }
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OTPForm;
