import Header from "../../components/common/Header";

import MongoDBLogsForm from "../../components/Monitor/MongoDB/MongoDBLogsForm";

export default function MonitorPage(): JSX.Element {
  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <Header title="Monitor" />
      <MongoDBLogsForm />
    </div>
  );
}
