import UserRoles from "../types/UserRoles";
import { getRolesForPermission } from "../components/common/PageRolePermissions";

let transactionsPage: string[] = Object.values(UserRoles);
transactionsPage = transactionsPage.concat(
  getRolesForPermission("access-transaction-page")
);

let transactionsPendingPage: string[] = Object.values(UserRoles);
transactionsPendingPage = transactionsPendingPage.concat(
  getRolesForPermission("transaction-pending-page")
);
let transactionsFailedPage: string[] = Object.values(UserRoles);
transactionsFailedPage = transactionsFailedPage.concat(
  getRolesForPermission("transaction-failed-page")
);

let transactionsVFDPage: string[] = Object.values(UserRoles);
transactionsVFDPage = transactionsVFDPage.concat(
  getRolesForPermission("transaction-vfd-page")
);

let transactionsSolidPage: string[] = Object.values(UserRoles);
transactionsSolidPage = transactionsSolidPage.concat(
  getRolesForPermission("transaction-solid-page")
);

let transactionsDetailPage: string[] = Object.values(UserRoles);
transactionsDetailPage = transactionsDetailPage.concat(
  getRolesForPermission("transaction-details-page")
);

let transactionsUsersPage: string[] = Object.values(UserRoles);
transactionsUsersPage = transactionsUsersPage.concat(
  getRolesForPermission("transaction-users-page")
);

let usersPage: string[] = Object.values(UserRoles);
usersPage = usersPage.concat(getRolesForPermission("access-all-users-page"));

let usersDetailPage: string[] = Object.values(UserRoles);
usersDetailPage = usersDetailPage.concat(getRolesForPermission("user-detail"));

let kycPage: string[] = [UserRoles.SUPER_ADMIN, UserRoles.CUSTOMER_CARE];
kycPage = kycPage.concat(getRolesForPermission("access-kyc-page"));

let referralPage: string[] = Object.values(UserRoles);
referralPage = referralPage.concat(getRolesForPermission("referral"));

let notificationsPage: string[] = [UserRoles.SUPER_ADMIN, UserRoles.GROWTH];
notificationsPage = notificationsPage.concat(
  getRolesForPermission("push-notifications")
);

let notificationsPage_ROUTE: string[] = [
  UserRoles.SUPER_ADMIN,
  UserRoles.GROWTH,
  UserRoles.OWNER,
];
notificationsPage_ROUTE = notificationsPage_ROUTE.concat(
  getRolesForPermission("push-notifications")
);

let ratesPage: string[] = [UserRoles.SUPER_ADMIN, UserRoles.FINANCE];
ratesPage = ratesPage.concat(getRolesForPermission("rates-dashboard"));

let promotionsPage: string[] = [
  UserRoles.SUPER_ADMIN,
  UserRoles.FINANCE,
  UserRoles.PRODUCT,
  UserRoles.OWNER,
];
promotionsPage = promotionsPage.concat(
  getRolesForPermission("rates-dashboard")
);

let ratesPage_ROUTE: string[] = [
  UserRoles.SUPER_ADMIN,
  UserRoles.FINANCE,
  UserRoles.OWNER,
];
ratesPage_ROUTE = ratesPage_ROUTE.concat(
  getRolesForPermission("rates-dashboard")
);

let dynamicRatesPage: string[] = [
  UserRoles.SUPER_ADMIN,
  UserRoles.FINANCE,
  UserRoles.OWNER,
];
dynamicRatesPage = dynamicRatesPage.concat(
  getRolesForPermission("rates-dashboard")
);

let userRatesPage: string[] = [UserRoles.SUPER_ADMIN, UserRoles.FINANCE];
userRatesPage = userRatesPage.concat(getRolesForPermission("rates-dashboard"));

let bulkPaymentsPage: string[] = [
  UserRoles.SUPER_ADMIN,
  UserRoles.FINANCE,
  UserRoles.OWNER,
];
bulkPaymentsPage = bulkPaymentsPage.concat(
  getRolesForPermission("bulk-payment")
);

let bulkPaymentsPage_ROUTE: string[] = [
  UserRoles.SUPER_ADMIN,
  UserRoles.FINANCE,
  UserRoles.OWNER,
];
bulkPaymentsPage_ROUTE = bulkPaymentsPage_ROUTE.concat(
  getRolesForPermission("bulk-payment")
);

const financialServicesPage: string[] = [
  UserRoles.OWNER,
  UserRoles.SUPER_ADMIN,
];

const monitoringPage: string[] = [
  UserRoles.SUPER_ADMIN,
  UserRoles.FINANCE,
  UserRoles.OWNER,
];

const monitoringMongoDBPage: string[] = [
  UserRoles.SUPER_ADMIN,
  UserRoles.FINANCE,
  UserRoles.SUPER_ADMIN,
  UserRoles.OWNER,
];

const monitoringProvidersPage: string[] = [
  UserRoles.SUPER_ADMIN,
  UserRoles.FINANCE,
  UserRoles.SUPER_ADMIN,
  UserRoles.OWNER,
];

const disputesPage: string[] = [UserRoles.COMPLIANCE];

const allRoles = [];
allRoles.push(...transactionsPage);
allRoles.push(...transactionsPendingPage);
allRoles.push(...transactionsFailedPage);
allRoles.push(...transactionsVFDPage);
allRoles.push(...transactionsSolidPage);
allRoles.push(...usersPage);
allRoles.push(...kycPage);
allRoles.push(...referralPage);
allRoles.push(...notificationsPage);
allRoles.push(...ratesPage);
allRoles.push(...dynamicRatesPage);
allRoles.push(...bulkPaymentsPage);
allRoles.push(...financialServicesPage);
allRoles.push(...monitoringPage);
allRoles.push(...monitoringMongoDBPage);
allRoles.push(...monitoringProvidersPage);
allRoles.push(...transactionsDetailPage);
allRoles.push(...transactionsUsersPage);
allRoles.push(...usersDetailPage);
allRoles.push(...notificationsPage_ROUTE);
allRoles.push(...ratesPage_ROUTE);
allRoles.push(...userRatesPage);
allRoles.push(...bulkPaymentsPage_ROUTE);
allRoles.push(...promotionsPage);
allRoles.push(...disputesPage);

export const PageRoles = {
  ALL_ROLES: Array.from(new Set(allRoles)),
  BULK_PAYMENTS_PAGE: bulkPaymentsPage,
  BULK_PAYMENTS_PAGE_ROUTE: bulkPaymentsPage_ROUTE,
  DYNAMIC_RATES_PAGE: dynamicRatesPage,
  FINANCIAL_SERVICES_PAGE: financialServicesPage,
  KYC_PAGE: kycPage,
  MONITORING_MONGODB_PAGE: monitoringMongoDBPage,
  MONITORING_PAGE: monitoringPage,
  MONITORING_PROVIDERS_PAGE: monitoringProvidersPage,
  NOTIFICATIONS_PAGE: notificationsPage,
  NOTIFICATIONS_PAGE_ROUTE: notificationsPage_ROUTE,
  PROMOTIONS_PAGE: promotionsPage,
  RATES_PAGE: ratesPage,
  RATES_PAGE_ROUTE: ratesPage_ROUTE,
  REFERRAL_PAGE: referralPage,
  TRANSACTIONS_DETAIL_PAGE: transactionsDetailPage,
  TRANSACTIONS_FAILED_PAGE: transactionsFailedPage,
  TRANSACTIONS_PAGE: transactionsPage,
  TRANSACTIONS_PENDING_PAGE: transactionsPendingPage,
  TRANSACTIONS_SOLID_PAGE: transactionsSolidPage,
  TRANSACTIONS_USERS_PAGE: transactionsUsersPage,
  TRANSACTIONS_VFD_PAGE: transactionsVFDPage,
  USERS_DETAIL_PAGE: usersDetailPage,
  USERS_PAGE: usersPage,
  USER_RATES_PAGE: userRatesPage,
  DISPUTES_PAGE: disputesPage,
};
