import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../../config/routesConfig";
import { AfriexLoginRequest } from "../../../types";
import { userService } from "../../../services/userService";
import { showErrorMessage } from "../../../utils/showErrorMessage";

const useLogin = () => {
  const navigate = useNavigate();
  const nextScreen = `/${appPaths.verifyOtp}`;

  const [loginCredentials, setLoginCredentials] = useState<AfriexLoginRequest>({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setLoginCredentials({ ...loginCredentials, [name]: value });
  };

  async function handleLogin(event: FormEvent<HTMLFormElement>) {
    try {
      event.preventDefault();
      setIsLoading(true);
      const nonceResponse = await userService.sendLogin(loginCredentials);
      localStorage.setItem("nonce", nonceResponse.nonce);
      navigate(nextScreen, { replace: true });
    } catch (error: any) {
      const errorMessage =
        error?.message ?? error.response?.data?.message ?? "";
      setIsLoading(false);
      showErrorMessage(errorMessage ?? "Error Logging in: Contact Engineering");
    }
  }

  return {
    handleChange,
    handleLogin,
    isLoading,
  };
};
export type LoginFormProps = ReturnType<typeof useLogin>;

export default useLogin;
