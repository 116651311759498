import { useState } from "react";
import { AfriexTier, preSaveTier } from "./index";

interface NewPromotionFormProps {
  onCreatePromotion: (promotion: preSaveTier) => void;
}

interface PromotionFormFieldProps {
  label: string;
  type: string;
  name: string;
  value: string | number | boolean;
  onChange: (name: string, value: string | number | boolean) => void;
}

const PromotionFormField: React.FC<PromotionFormFieldProps> = ({
  label,
  type,
  name,
  value,
  onChange,
}) => {
  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    onChange(name, e.target.value);
  };

  const isCheckbox = type === "checkbox";

  const getFlexType = () => {
    if (isCheckbox) {
      return "row";
    }
    return "col";
  };

  const getAlignType = () => {
    if (isCheckbox) {
      return "items-center";
    }
    return "justify-center";
  };

  const promotionTypes = ["promotion", "tier"];

  const getInputElement = () => {
    switch (type) {
      case "checkbox":
        return (
          <input
            className="p-1"
            type={type}
            name={name}
            checked={value as boolean}
            onChange={handleFieldChange}
          />
        );
      case "select":
        return (
          <select
            className="p-1"
            name={name}
            value={value?.toString()}
            onChange={handleFieldChange}
          >
            {promotionTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        );
      default:
        return (
          <input
            className="p-1"
            type={type}
            name={name}
            value={value?.toString()}
            onChange={handleFieldChange}
          />
        );
    }
  };

  return (
    <div
      className={`flex flex-${getFlexType()} mb-2 ${getAlignType()} rounded-2xl`}
    >
      <label className="pl-0 p-1">{label}:</label>
      {getInputElement()}
    </div>
  );
};

export const CreateNewPromotionForm: React.FC<NewPromotionFormProps> = ({
  onCreatePromotion,
}) => {
  const [promotion, setPromotion] = useState<preSaveTier>({
    isActive: false,
    name: "",
    discount: 0,
    transactionCountThreshold: "",
    type: "promotion",
  } as unknown as preSaveTier);

  const [discountVisible, setDiscountVisible] = useState<string>("");
  const [discountActual, setDiscountActual] = useState<number>(0);

  const [errors, setErrors] = useState<Partial<Record<string, string>>>({});

  const validateForm = () => {
    const newErrors: Partial<Record<string, string>> = {};

    if (!promotion.name) {
      newErrors.name = "Name cannot be empty";
    }

    if (!discountActual) {
      newErrors.discount = "Discount cannot be empty";
    }

    if (!promotion.transactionCountThreshold) {
      newErrors.transactionCountThreshold = "Max Transactions cannot be empty";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (
    name: string,
    value: string | number | boolean
  ) => {
    if (name === "discount") {
      const floatValue = parseFloat(value.toString());
      setDiscountActual(floatValue / 100);

      if (typeof value === "string") {
        setDiscountVisible(value.replace(/%/g, "") + "%");
      }
    } else {
      setPromotion((prevPromotion) => ({
        ...prevPromotion,
        [name]: value,
      }));
    }
  };

  const handleIsActiveToggle = (prevIsActive: boolean) => {
    setPromotion((prevPromotion) => ({
      ...prevPromotion,
      isActive: !prevIsActive,
    }));
  };

  const handleCreatePromotion = () => {
    const isValid = validateForm();

    if (isValid) {
      onCreatePromotion({
        ...promotion,
        discount: discountActual, // Use the decimal value for discount
      });
    }
  };

  return (
    <div className="border-2 border-solid rounded-2xl p-2 mt-4">
      <h1 className="text-center text-3xl">Create New Promo</h1>
      <div className="p-2">
        <form className="flex flex-col">
          <PromotionFormField
            label="Name"
            type="text"
            name="name"
            value={promotion.name}
            onChange={handleInputChange}
          />
          {errors.name && <p className="text-red-500">{errors.name}</p>}

          <PromotionFormField
            label="Discount"
            type="text"
            name="discount"
            value={discountVisible}
            onChange={handleInputChange}
          />
          {errors.discount && <p className="text-red-500">{errors.discount}</p>}

          <PromotionFormField
            label="Max Transactions"
            type="number"
            name="transactionCountThreshold"
            value={promotion.transactionCountThreshold}
            onChange={handleInputChange}
          />
          {errors.transactionCountThreshold && (
            <p className="text-red-500">{errors.transactionCountThreshold}</p>
          )}

          <PromotionFormField
            label="Type"
            type="select"
            name="type"
            value={promotion.type}
            onChange={handleInputChange}
          />

          <PromotionFormField
            label="Active"
            type="checkbox"
            name="isActive"
            value={promotion.isActive}
            onChange={() => handleIsActiveToggle(promotion.isActive)}
          />

          <button
            className="bg-green-500 text-white p-3 rounded"
            type="button"
            onClick={handleCreatePromotion}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
