import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Button, Modal } from "../../components";
import FeeEntry from "./FeeEntry";
import FeeEntryModalContent from "./FeeEntryModalContent";
import { TransactionFee } from "./type";

interface Props {
  overrides: TransactionFee[];
  onSave: (fee: TransactionFee) => void;
  onDelete: (fee: TransactionFee, index: number) => void;
}

const FeeOverrideSection = ({ overrides, onSave, onDelete }: Props) => {
  const handleAdd = () => {
    setEditingFee({ type: "override" });
  };
  const [editingFee, setEditingFee] = useState<TransactionFee>();

  return (
    <div className="section">
      <div className="flex flex-row justify-between">
        <h2 className="text-2xl font-semibold">Fee Overrides</h2>
        <Button className="bg-blue-500 text-white" onClick={handleAdd}>
          Add
        </Button>
      </div>
      <div className="mb-8 p-4 rounded-md border-solid border-4 border-gray-100 w-3/4">
        <p>
          Create fees that will override the base fee. Overrides take the
          following priority:
        </p>
        <ul className="flex">
          <li>Exact Currency &gt; </li>
          <li>Destination Currency &gt; </li>
          <li>Payment Method on transaction &gt; </li>
          <li>Base Fee</li>
        </ul>
      </div>
      {overrides.map((override, index) => (
        <FeeEntry
          key={JSON.stringify(Object.values(override))}
          fee={override}
          onEdit={(newOverride) => {
            setEditingFee(newOverride);
          }}
          onDelete={() => {
            confirmAlert({
              title: "Confirm to delete",
              message: "Are you sure you want to delete this fee?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => onDelete(override, index),
                  className: "bg-red-500 text-white",
                },
                {
                  label: "No",
                  onClick: () => false,
                },
              ],
            });
          }}
        />
      ))}
      <Modal modalOpen={!!editingFee}>
        <FeeEntryModalContent
          fee={editingFee}
          onSave={(newOverride) => {
            setEditingFee(undefined);
            if (!newOverride) {
              // don't save to list
              return;
            }
            onSave?.(newOverride);
          }}
        />
      </Modal>
    </div>
  );
};
export default FeeOverrideSection;
