import { ReactElement, useEffect, useState } from "react";
import { ImageViewer, Input, SpinningLoader } from "..";
import { showErrorMessage } from "../../utils/showErrorMessage";
import { fileService } from "../../services/fileService";

type FilePickerProps = {
  name: string;
  label: string;
  onChange: (url: string) => void;
  alt?: string;
  value?: string;
};

const ImagePicker = ({
  name,
  label,
  onChange,
  alt,
  value,
  ...props
}: FilePickerProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const handleUpload = async (e: InputEvent) => {
    try {
      setIsLoading(true);
      const blob: Blob = (e.target as HTMLInputElement)?.files?.[0] as Blob;
      const { getUrl } = await fileService.uploadFile(
        name,
        "ratesBucket",
        null,
        blob
      );
      setUrl(getUrl);
      onChange(getUrl);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      showErrorMessage("Failed to upload image: " + e?.message);
    }
  };

  useEffect(() => {
    if (value) {
      fileService.fetchUploadedFile(value).then((res) => {
        if (res) setUrl(res);
      });
    }
  }, [value]);

  return (
    <div className="mt-5">
      {isLoading ? (
        <SpinningLoader />
      ) : url ? (
        <ImageViewer src={url} alt={alt} />
      ) : (
        <Input label={label} type="file" {...props} onChange={handleUpload} />
      )}
    </div>
  );
};

export default ImagePicker;
