import { useEffect, useState } from "react";

import Button from "../common/Button";
import { Table, TBody } from "../common/Table";
import Badge from "../common/Badge";
import { ChevronRightIcon, DownloadIcon } from "@heroicons/react/solid";
import { CSVLink } from "react-csv";
import { transactionHeaders } from "../../constants/csvExportHeaders";
import React from "react";
import { PermissionsProvider } from "../common/PermissionsProvider";
import { AfriexTransaction } from "../../types";
import { Link } from "react-router-dom";
import { statusColors } from "../../types/Transaction";
import { getTransactionAmountandAsset } from "../../constants/formatters";

type TransactionHistoryProp = {
  data: AfriexTransaction[];
  fullTransactionsHistory: AfriexTransaction[];
  handleExport: (event: any, done: any) => void;
  loadingExport: boolean;
  totalTransactions: number;
};

const PAGE_PERMISSION = "access-transaction-page";
export default function TransactionHistory({
  data,
  fullTransactionsHistory,
  handleExport,
  loadingExport,
  totalTransactions,
}: TransactionHistoryProp) {
  const [adminRole, setAdminRole] = useState<string>("");

  useEffect(() => {
    _setDefaults();
  }, []);

  // This populates the state with default values
  const _setDefaults = async () => {
    const adminRole = localStorage.getItem("admin-role");
    if (adminRole) {
      setAdminRole(adminRole);
    }
  };

  return (
    <section>
      <div className="w-full flex justify-between font-semibold items-center">
        <h2 className="font-semibold text-lg mr-3">Recent History </h2>

        <div className="text-cyan-700 flex">
          <React.Fragment>
            <PermissionsProvider
              permission={PAGE_PERMISSION}
              action="click-transaction-export"
            >
              <Button
                variant="ghost"
                colorScheme="cyan"
                leftIcon={<DownloadIcon className="h-5 w-5" />}
                className="flex items-center"
                isLoading={loadingExport}
                loadingText="exporting"
                disabled={totalTransactions <= 0}
              >
                <CSVLink
                  data={fullTransactionsHistory}
                  asyncOnClick={true}
                  onClick={handleExport}
                  headers={transactionHeaders}
                  filename="user_transactions.csv"
                >
                  Export as .CSV
                </CSVLink>
              </Button>
            </PermissionsProvider>
          </React.Fragment>
        </div>
      </div>
      <hr className="border-1 border-gray-200 mt-3 w-full" />
      <div className={`hide-scrollbar`}>
        <TransactionsHistoryTable data={data} />
      </div>
    </section>
  );
}

const TransactionsHistoryTable = ({
  data,
}: {
  data: AfriexTransaction[];
}): JSX.Element => {
  return (
    <Table className="border-none">
      <TBody className="">
        {data.map((transaction) => {
          const { amount, asset } = getTransactionAmountandAsset(transaction);

          return (
            <tr
              key={transaction.id as React.Key}
              className="hover:bg-slate-200 hover:bg-opacity-20 transition-background transition-colors transition-opacity ease-in-out duration-500"
            >
              <td className="py-3 text-sm text-gray-800">{amount}</td>
              <td className="py-3 text-sm text-gray-800">{asset}</td>
              <td className="py-3 text-sm text-gray-800">
                <Badge
                  label={transaction.status || "N/A"}
                  colorScheme={
                    transaction.status ? statusColors[transaction.status] : ""
                  }
                />
              </td>
              <td className="py-3 text-sm text-gray-800">
                {new Date(transaction.createdAt).toLocaleDateString()}
              </td>

              <td className="py-3 text-sm text-gray-800 hover:text-cyan-500">
                <Link to={`/transactions/${transaction.id}`}>
                  <ChevronRightIcon className=" w-5 h-5" />
                </Link>
              </td>
            </tr>
          );
        })}
      </TBody>
    </Table>
  );
};
