import { useParams } from "react-router-dom";
import { Pagination } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import useTierTransactionList from "./useTierTransactionList";
import { AfriexTransaction } from "../../../types";
import TransactionTable from "../../Transaction/List/TransactionTable";

const TierTransactionList = () => {
  const params = useParams();
  const tierId = params?.tierId ?? "";
  const { isLoading, fetchList, total, list } = useTierTransactionList(tierId);

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <TransactionTable items={list as AfriexTransaction[]} />
        )}
      </section>

      {list && list.length > 1 && (
        <Pagination
          onPageChange={fetchList as any}
          total={total}
          isSearch={false}
        />
      )}
    </main>
  );
};

export default TierTransactionList;
