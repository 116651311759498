import { MouseEvent, useState } from "react";
import { Td } from "../../../components";
import { RatesTableProp } from "./RatesTable";

import "./styles.css";

type RateItemProp = {
  rate: number;
  baseCurrency: string;
  quoteCurrency: string;
} & Pick<RatesTableProp, "onRatesEdited" | "isEditable">;

//const headerHue = 'bg-blue-50 p-8 shadow-sm';

export default function RateItem({
  rate,
  baseCurrency,
  quoteCurrency,
}: RateItemProp): JSX.Element {
  const [isControlsVisible, setIsControlsVisible] = useState<boolean>(false);
  const title = rate > 0 ? `1 ${baseCurrency} = ${rate} ${quoteCurrency}` : "";

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    // todo

    const detail = e.detail || 0;
    if (detail > 0) {
      setIsControlsVisible(true);
    }
  };

  return (
    <Td
      className={`hover:bg-gray-100 
        ${isControlsVisible && "border border-blue-500 "}`}
    >
      <div
        className="flex justify-between mx-2"
        onClick={handleClick}
        title={title}
      >
        {rate?.toString() ?? "0"}
      </div>
    </Td>
  );
}
