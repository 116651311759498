import { Button } from "../../../components";
import { mapCountryCodeToFlag } from "../../../constants/countries";
import { ProcessorResponse } from "../../../services/processorService";
import { AfriexCountry, SupportedCurrencies } from "../../../types";

type SummaryProp = {
  item: AfriexCountry;
  processors: ProcessorResponse;
  currencyCode: SupportedCurrencies;
  corridors: AfriexCountry[];
  showProcessorUpdateForm: (currency: SupportedCurrencies) => void;
};

const Summary = ({
  item,
  processors,
  corridors,
  currencyCode,
  showProcessorUpdateForm,
}: SummaryProp) => {
  const depositProcessors = processors?.DEPOSIT?.[currencyCode]
    ?.split(",")
    ?.map((x) => x.trim());
  const withdrawalProcessors = processors?.WITHDRAW?.[currencyCode]
    ?.split(",")
    ?.map((x) => x.trim());
  const defaultDepositProcessor = depositProcessors?.[0] ?? "n/a";
  const defaultWithdrawalProcessor = withdrawalProcessors?.[0] ?? "n/a";
  const extraDepositProcessors = depositProcessors?.filter(
    (x) => x !== defaultDepositProcessor
  );
  const extraWithdrawalProcessors = withdrawalProcessors?.filter(
    (x) => x !== defaultWithdrawalProcessor
  );

  return (
    <div className="bg-white rounded-lg w-full px-10 py-3">
      <div className="w-full gap-16">
        <div>
          <div className="flex justify-between items-center w-full">
            <h3 className=" font-semibold">
              Processors for {currencyCode} {mapCountryCodeToFlag(item?.iso2)}
            </h3>
          </div>

          <div className="text-sm text-slate-500 w-full">
            {defaultDepositProcessor && (
              <div className="grid grid-cols-3 gap-3 my-2">
                <div>Deposit Processor</div>
                <div className="col-span-2">
                  {defaultDepositProcessor?.toUpperCase()}
                </div>
              </div>
            )}

            {(extraDepositProcessors?.length ?? 0) > 0 && (
              <div className="grid grid-cols-3 gap-3 my-2">
                <div>Other Deposit Processor(s)</div>
                <div className="col-span-2">
                  {extraDepositProcessors?.join(", ")?.toUpperCase()}
                </div>
              </div>
            )}

            {defaultWithdrawalProcessor && (
              <div className="grid grid-cols-3 gap-3 my-2">
                <div>Withdrawal Processor</div>
                <div className="col-span-2">
                  {defaultWithdrawalProcessor?.toUpperCase()}
                </div>
              </div>
            )}

            {(extraWithdrawalProcessors?.length ?? 0) > 0 && (
              <div className="grid grid-cols-3 gap-3 my-2">
                <div>Other Withdrawal Processor(s)</div>
                <div className="col-span-2">
                  {extraWithdrawalProcessors?.join(", ")?.toUpperCase()}
                </div>
              </div>
            )}
            <div className="grid grid-cols-3 gap-3 my-2">
              <div>Countries Affected</div>
              <div className="col-span-2">
                {corridors?.map((c) => c?.name).join(", ")}
              </div>
            </div>

            <div className="flex justify-between items-center w-full">
              <div />

              <Button
                className="btn border-2 p-2 text-sm bg-blue-500 text-white disabled:opacity-70 disabled:text-slate-200"
                data-tip
                data-for="payoutButton"
                onClick={() => {
                  showProcessorUpdateForm(currencyCode);
                  return false;
                }}
                variant={"solid"}
              >
                Update Processor
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
