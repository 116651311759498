import { useCallback, useEffect, useState } from "react";
import {
  CurrencyFilters,
  currencyService,
} from "../../../services/currencyService";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { identity, pickBy } from "lodash";
import { AfriexCurrency } from "../../../types";
import { replaceUnderScoreIds } from "../../../utils/replaceUnderScoreIds";

const useCurrencyUpdate = () => {
  const [list, setList] = useState<AfriexCurrency[]>([] as any);
  const [item, setItem] = useState<AfriexCurrency>({} as any);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [shouldShowForm, setShouldShowForm] = useState<boolean>(false);
  const [filters, setFilters] = useState<CurrencyFilters>(
    {} as CurrencyFilters
  );

  list?.sort((a, b) => a.name.localeCompare(b.name));

  const fetchList = useCallback(
    async (params: CurrencyFilters) => {
      try {
        setIsLoading(true);
        const { data: items, total: itemsTotal } =
          await currencyService.getList({ ...params, ...filters });
        setList(replaceUnderScoreIds(items));
        setTotal(itemsTotal ?? 0);
      } catch (e) {
        showErrorMessage(`Fetching currency list failed:${e as any}`);
      } finally {
        setIsLoading(false);
      }
    },
    [filters]
  );

  const fetchItem = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      const item = await currencyService.getItem(id);
      setItem(item);
    } catch (e) {
      showErrorMessage(`Fetching currency details failed:${e as any}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleFilters = useCallback(async (filters: CurrencyFilters) => {
    setIsSearch(false);
    // remove undefined values
    const cleanedFilters = pickBy(filters, identity);

    setFilters({ page: 0, ...cleanedFilters } as CurrencyFilters);
  }, []);

  useEffect(() => {
    setIsSearch(false);
    fetchList({} as CurrencyFilters);
  }, [fetchList]);

  const handleUpdate = useCallback(
    async (currencyId: string, body: Partial<AfriexCurrency>) => {
      try {
        if (!body) {
          return;
        }

        setError(null);
        setIsLoading(true);
        const currency = await currencyService.saveOne(currencyId, body);
        if (currency) {
          showSuccessMessage("Currency updated successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (e) {
        showErrorMessage(`Updating currency failed:${e as any}`);
      }
      setIsLoading(false);
    },
    []
  );

  const handleAdd = useCallback(async (body: Partial<AfriexCurrency>) => {
    try {
      if (!body) {
        return;
      }

      setError(null);
      setIsLoading(true);
      const currency = await currencyService.createItem(body);
      if (currency) {
        showSuccessMessage("Currency added successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      showErrorMessage(`Adding currency failed:${e as any}`);
    }
    setIsLoading(false);
  }, []);

  const handleSearch = useCallback(
    async (searchTerm: string) => {
      if (!searchTerm) {
        setIsSearch(false);
        setFilters({} as CurrencyFilters);
        fetchList({} as CurrencyFilters);
        return;
      }
      setIsSearch(true);
      setFilters({
        page: 0,
        status: searchTerm?.trim()?.toString(),
      } as CurrencyFilters);
    },
    [fetchList]
  );

  const toggleForm = useCallback(() => {
    setShouldShowForm(!shouldShowForm);
  }, [shouldShowForm]);

  return {
    error,
    isLoading,
    isSearch,
    handleUpdate,
    handleAdd,
    handleSearch,
    handleFilters,
    fetchList,
    page,
    setPage,
    total,
    list,
    item,
    fetchItem,
    shouldShowForm,
    toggleForm,
  };
};

export default useCurrencyUpdate;
