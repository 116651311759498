import {
  FilePicker,
  Modal,
  ImageViewer,
  Button,
  SpinningLoader,
  DatePicker,
  CustomSelect,
  CustomInput,
} from "../../../components";
import { primaryIDOptions, secondaryIDOptions } from "./data";
import {
  AfriexIDType,
  AfriexImageView,
  AfriexPerson,
  SupportedCountryCodes,
} from "../../../types";
import useKYCUpdate from "./useKYCUpdate";
import { ChangeEvent } from "react";

type KYCFormProps = {
  user: AfriexPerson;
  onClose: () => void;
};

const KYCForm = ({ user, onClose }: KYCFormProps) => {
  const secondaryIDTypes = secondaryIDOptions(
    user?.currentCountry?.toUpperCase() as SupportedCountryCodes
  );

  const hasSecondaryID = secondaryIDTypes?.length > 0;
  const {
    handleSubmit,
    handleFile,
    handleDateOfBirth,
    setPrimaryIDType,
    primaryIDNumber,
    primaryIDType,
    setPrimaryIDNumber,
    setSecondaryIDType,
    secondaryIDNumber,
    setSecondaryIDNumber,
    dateOfBirth,
    selfiePhoto,
    setSelfiePhoto,
    idFront,
    setIdFront,
    idBack,
    setIdBack,
    isIDFrontLoading,
    isIDBackLoading,
    isSelfieLoading,
    isLoading,
    secondaryIDType,
  } = useKYCUpdate(user, hasSecondaryID);

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg border-bottom">Upload KYC Details</h3>
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              {selfiePhoto ? (
                <ImageViewer
                  onClick={() => setSelfiePhoto("")}
                  src={selfiePhoto}
                  alt="Selfie Photo"
                />
              ) : (
                <FilePicker
                  isLoading={isSelfieLoading}
                  label="Selfie Photo"
                  name="selfiePhoto"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleFile(e, AfriexImageView.Selfie)
                  }
                />
              )}
              {idFront ? (
                <ImageViewer
                  onClick={() => setIdFront("")}
                  src={idFront}
                  alt="ID Front"
                />
              ) : (
                <FilePicker
                  isLoading={isIDFrontLoading}
                  label="ID Front"
                  name="imageUrlFront"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleFile(e, AfriexImageView.Front)
                  }
                />
              )}
              {idBack ? (
                <ImageViewer
                  onClick={() => setIdBack("")}
                  src={idBack}
                  alt="ID Back"
                />
              ) : (
                <FilePicker
                  isLoading={isIDBackLoading}
                  label="ID Back"
                  name="imageUrlBack"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleFile(e, AfriexImageView.Back)
                  }
                />
              )}
              <div className="mt-5">
                <DatePicker
                  label="Date of Birth"
                  selectedDate={
                    dateOfBirth &&
                    dateOfBirth.length > 0 &&
                    !isNaN(new Date(dateOfBirth).getTime())
                      ? new Date(dateOfBirth)
                      : new Date()
                  }
                  setSelectedDate={(date) => handleDateOfBirth(date)}
                />
              </div>
              <div className="mt-3">
                <CustomSelect
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setPrimaryIDType(e.target.value as AfriexIDType)
                  }
                  value={primaryIDType}
                  name="primaryID"
                  options={primaryIDOptions}
                  placeholder="Select Primary ID Type"
                  label="Primary ID Type"
                />
              </div>
              <div className="mt-3">
                <CustomInput
                  onChange={(e) => setPrimaryIDNumber(e.target.value)}
                  name="idNumber"
                  label="Primary ID Number"
                  type="number"
                  value={primaryIDNumber ?? ""}
                  className="w-full"
                  placeholder="Enter Primary ID Number"
                />
              </div>
              {secondaryIDTypes?.length > 0 && (
                <>
                  <div className="mt-3">
                    <CustomSelect
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setSecondaryIDType(e.target.value as AfriexIDType)
                      }
                      value={secondaryIDType}
                      name="secondaryID"
                      options={secondaryIDTypes}
                      placeholder="Select Secondary ID Type"
                      label="Secondary ID Type"
                    />
                  </div>
                  <div className="mt-3">
                    <CustomInput
                      onChange={(e) => setSecondaryIDNumber(e.target.value)}
                      name="idNumber"
                      label="Secondary ID Number"
                      type="number"
                      value={secondaryIDNumber ?? ""}
                      className="w-full"
                      placeholder="Enter Secondary ID Number"
                    />
                  </div>
                </>
              )}
              <div className="flex w-full justify-end space-x-2 py-5">
                <Button colorScheme="cyan" onClick={onClose} variant="outline">
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="solid"
                  colorScheme="cyan"
                >
                  Upload Details
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default KYCForm;
