import { ReactElement } from "react";
import { Button } from "../../../components";
import { AfriexPerson } from "../../../types";
import useBlockUser from "./useBlockUser";
import { BlockUserModal } from "./BlockUserModal";

type BlockUserProps = {
  user: AfriexPerson;
};

const BlockUser = ({ user }: BlockUserProps): ReactElement => {
  const {
    isLoading,
    showModal,
    toggleModal,
    securityNoteInput,
    handleSecurityNoteChange,
    handleBlockUser,
    handleBlockReason,
    blockReason,
    unblockReason,
    handleUnblockReason,
  } = useBlockUser(user);

  return (
    <>
      {showModal ? (
        <BlockUserModal
          user={user}
          isLoading={isLoading}
          isSecurityFlagged={!!user.isSecurityFlagged}
          onSubmit={handleBlockUser}
          onClose={toggleModal}
          securityNote={securityNoteInput ?? ""}
          handleBlockReason={handleBlockReason}
          blockReason={blockReason ?? ""}
          handleSecurityNoteChange={handleSecurityNoteChange}
          handleUnblockReason={handleUnblockReason}
          unblockReason={unblockReason ?? ""}
        />
      ) : (
        <Button
          colorScheme={user.isSecurityFlagged ? "orange" : "red"}
          onClick={toggleModal}
        >
          {user.isSecurityFlagged ? "Unblock " : "Block "}
          {user?.name?.firstName}
        </Button>
      )}
    </>
  );
};

export default BlockUser;
