import { useEffect, useState } from "react";
import { userService } from "../../../services/userService";
import { AfriexPerson } from "../../../types";
import { UserUpdateBody } from "../../../types/UserUpdateBody";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { UserUpdateReturnProps } from "./types";

const useUserUpdate = (
  user: AfriexPerson,
  toggleModal: () => void
): UserUpdateReturnProps => {
  const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isPhoneUpdated, setIsPhoneUpdated] = useState<boolean>(false);
  const [isEmailUpdated, setIsEmailUpdated] = useState<boolean>(false);
  const [isNameUpdated, setIsNameUpdated] = useState<boolean>(false);
  const [isUsernameUpdated, setIsUsernameUpdated] = useState<boolean>(false);
  const [isPhoneVerifiedUpdated, setIsPhoneVerifiedUpdated] =
    useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [username, setUsername] = useState<string>("");

  useEffect(() => {
    if (user) {
      setName(user.name?.fullName || "");
      setEmail(user.currentEmail || "");
      setPhone(user.currentPhone || "");
      setIsPhoneVerified(user.isPhoneVerified || false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setIsButtonDisabled(false);
    }
  }, [user]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (!name || !email || !phone) {
        throw new Error("Please fill all fields");
      }
      const payload: Partial<UserUpdateBody> = {};
      if (isNameUpdated) {
        payload["name"] = name;
      }
      if (isEmailUpdated) {
        payload["email"] = email;
      }
      if (isPhoneUpdated) {
        payload["phone"] = phone;
      }
      if (isUsernameUpdated) {
        payload["username"] = username;
      }
      if (isPhoneVerifiedUpdated) {
        payload["isPhoneVerified"] = isPhoneVerified;
      }
      await userService.updateUser(user.id, payload);

      showSuccessMessage("User updated successfully");
      setIsSubmitting(false);
      toggleModal();
      window.location.reload();
    } catch (err: any) {
      const errorMessage = err.response?.data.message
        ? err.response?.data.message
        : err.message;
      showErrorMessage("Failed to update user:" + errorMessage);
      setIsSubmitting(false);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.value !== user.name?.fullName) {
      setIsNameUpdated(true);
      setName(e.target.value);
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.value !== user.name?.userName) {
      setIsUsernameUpdated(true);
      setUsername(e.target.value);
    }
  };
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.value !== user.currentPhone) {
      setIsPhoneUpdated(true);
      setPhone(e.target.value);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.value !== user.currentEmail) {
      setIsEmailUpdated(true);
      setEmail(e.target.value);
    }
  };
  const handlePhoneVerifiedChange = (value: boolean) => {
    if (Boolean(value) !== Boolean(user.isPhoneVerified)) {
      setIsPhoneVerifiedUpdated(true);
      setIsPhoneVerified(value);
    }
  };

  return {
    isButtonDisabled,
    isPhoneVerified,
    isSubmitting,
    handleEmailChange,
    handleNameChange,
    handlePhoneChange,
    handlePhoneVerifiedChange,
    handleSubmit,
    handleUsernameChange,
    setIsPhoneVerified,
  };
};

export default useUserUpdate;
