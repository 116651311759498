import Button from "../common/Button";
import Modal from "../common/Modal";
import SpinningLoader from "../common/SpinningLoader";

type PushNotificationModalProps = {
  loading: boolean;
  sendPushNotification: () => void;
  onClose: () => void;
  title: string;
  message: string;
};

export function SendPushNotificationModal({
  loading,
  title,
  message,
  sendPushNotification,
  onClose,
}: PushNotificationModalProps) {
  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        {loading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <h3 className="text-lg ">
              Are you sure you want to send this Push Notification?
            </h3>
            <div className="text-xs font-light mt-3">
              Double check the amount before sending
            </div>
            <div className="pt-5 text-sm">
              <span className="text-md text-bold text-indigo-600">Title: </span>{" "}
              {title}
            </div>
            <div className="pt-5 text-sm">
              <span className="text-md text-bold text-indigo-600">
                Message:{" "}
              </span>{" "}
              {message}
            </div>
            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={onClose} variant="outline">
                cancel
              </Button>
              <Button
                onClick={sendPushNotification}
                variant="outline"
                className="bg-indigo-200 hover:g"
              >
                Send Push Notification
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
