import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import TierForm from "./TierForm";
import { AfriexReferralTier } from "../../../types";
import { isEmpty } from "lodash";

type TierUpdateButtonProps = {
  initialData?: AfriexReferralTier;
};

const TierUpdateButton = ({
  initialData,
}: TierUpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = isEmpty(initialData)
    ? "Create Referral Tier +"
    : "Update Referral Tier";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <TierForm onClose={toggleFullScreen} initialData={initialData} />
  ) : (
    <Button
      variant={"solid"}
      className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
      onClick={toggleFullScreen}
    >
      {updateLabel}
    </Button>
  );
};

export default TierUpdateButton;
