import { useCallback, useEffect, useState } from "react";
import { TabItem } from "./types";

const useTabs = (tabs: TabItem[], queryKey?: string) => {
  const [currentTab, setCurrentTab] = useState<TabItem>(tabs[0]);

  useEffect(() => {
    if (queryKey) {
      const tab = tabs.find(
        (tab) =>
          tab.id === new URL(window.location.href).searchParams.get(queryKey)
      );
      if (tab) {
        setCurrentTab(tab);
      } else {
        setCurrentTab(tabs[0]);
      }
    }
  }, [queryKey, tabs]);

  const handleTab = useCallback(
    (tab: TabItem) => {
      if (queryKey) {
        const currentUrl = new URL(window.location.href);

        // Modify search parameters
        currentUrl.searchParams.set(queryKey, tab.id);

        // Update URL without reloading the page
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, "", currentUrl.toString());
      }
      setCurrentTab(tab);
    },
    [queryKey]
  );

  return { setCurrentTab, currentTab, handleTab };
};

export default useTabs;
