import Header from "../../components/common/Header";

import ProvidersForm from "../../components/Monitor/Providers/ProvidersForm";

export default function ProvidersPage(): JSX.Element {
  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <Header title="Providers" />
      <ProvidersForm />
    </div>
  );
}
