import React, { useState } from "react";
import { usePromotions } from "../usePromotions";
import { AfriexTier } from "../index";
import { EditablePercentageField, EditableTextField } from "./Fields";

export const PromotionsTableHeaders = () => {
  return (
    <div className="flex font-bold text-gray-600 border-b border-gray-400 py-2">
      <div className="flex-1 p-2">Name</div>
      <div className="flex-1 p-2">Discount</div>
      <div className="flex-1 p-2">Max Txs</div>
      <div className="flex-1 p-2">Status</div>
      <div className="flex-1 p-2">Type</div>
      <div className="flex-1 p-2">Actions</div>
    </div>
  );
};

export const PromotionsTableBody = ({
  promotions,
}: {
  promotions: AfriexTier[];
}) => {
  return (
    <div>
      {promotions?.map((promotion) => (
        <PromotionsTableRow key={promotion.id} {...promotion} />
      ))}
    </div>
  );
};

export const PromotionsTableRow: React.FC<AfriexTier> = ({
  name,
  discount,
  isActive,
  transactionCountThreshold,
  type,
  id,
}) => {
  const { updatePromotion } = usePromotions();
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState({
    name,
    discount,
    transactionCountThreshold,
    isActive,
    type,
  });
  const [originalFields] = useState({ ...editedFields }); // Store original fields

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    updatePromotion(id, editedFields);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setEditedFields({ ...originalFields });
    setIsEditing(false);
  };

  const getBooleanStatus = (status: "Inactive" | "Active"): boolean =>
    status === "Active";

  const handleInputChange = (event: {
    name: string;
    value: string | number;
  }) => {
    const { name, value } = event;
    let parsedValue: string | number | boolean;
    if (name === "isActive") {
      parsedValue = getBooleanStatus(value as "Inactive" | "Active");
    }
    setEditedFields((prevFields) => ({
      ...prevFields,
      [name]: parsedValue ?? value,
    }));
  };

  return (
    <div
      key={id}
      className="flex items-center font-bold text-gray-600 border-b border-gray-400 py-2 overflow-hidden"
    >
      <EditableTextField
        isEditing={isEditing}
        fieldName="name"
        fieldValue={editedFields.name}
        handleInputChange={handleInputChange}
        type={"text"}
      />

      <EditablePercentageField
        isEditing={isEditing}
        fieldName="discount"
        fieldValue={editedFields.discount}
        handleInputChange={handleInputChange}
        type={"number"}
      />

      <EditableTextField
        isEditing={isEditing}
        fieldName="transactionCountThreshold"
        fieldValue={editedFields.transactionCountThreshold}
        handleInputChange={handleInputChange}
        type={"number"}
      />

      <EditableTextField
        isEditing={isEditing}
        fieldName="isActive"
        fieldValue={editedFields.isActive ? "Active" : "Inactive"}
        handleInputChange={handleInputChange}
        type={"select"}
        options={["Active", "Inactive"]}
      />

      <EditableTextField
        isEditing={isEditing}
        fieldName="type"
        fieldValue={editedFields.type}
        handleInputChange={handleInputChange}
        type={"select"}
        options={["promotion", "tier"]}
      />

      <div className="flex-1 p-2">
        {isEditing ? (
          <div>
            <button
              className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              className="bg-gray-500 text-white px-2 py-1 rounded"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            className="bg-blue-500 text-white px-3 py-2 rounded"
            onClick={handleEditClick}
          >
            Modify
          </button>
        )}
      </div>
    </div>
  );
};

export const PromotionsTable = ({
  promotions,
}: {
  promotions: AfriexTier[];
}) => {
  return (
    <div className={`w-full h-full rounded-2xl`}>
      <h1 className={`text-center text-3xl`}>Promotions</h1>
      <PromotionsTableHeaders />
      {promotions.length && (
        <PromotionsTableBody promotions={promotions}></PromotionsTableBody>
      )}
    </div>
  );
};
