import { useState } from "react";
import {
  Button,
  ChevronRightIcon,
  Link,
  MinusIcon,
  PlusIcon,
  Td,
  Tr,
} from "../../../components";
import { AfriexRewardCard } from "../../../types";
import { formatDate } from "../../../utils/formatDate";

type CardItemProps = {
  item: AfriexRewardCard;
};
const CardItem = ({ item }: CardItemProps) => {
  const className = "text-sm text-black pl-3";

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <>
      <Tr className="hover:bg-indigo-200 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <Td className="pl-5">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
            <div
              className={`border border-gray-300 w-5 h-5 flex justify-center rounded-lg hover:bg-sky-50 ${
                isExpanded && "bg-sky-50"
              }`}
            >
              {isExpanded ? (
                <MinusIcon className="w-4 h-4 text-indigo-500" />
              ) : (
                <PlusIcon className="text-gray-300 w-4 h-4 hover:text-indigo-500" />
              )}
            </div>
          </Button>
        </Td>
        <Td>
          <div className={className}>***</div>
        </Td>

        <Td>
          <div className={className}>{item.isDeactivated?.toString()}</div>
        </Td>

        <Td>
          <div className={className}>
            {" "}
            {item.isDeactivated ? (
              <Link to={`/users/${item?.redeemedBy?.userId}`}>
                {item?.redeemedBy?.name}
              </Link>
            ) : (
              "-"
            )}
          </div>
        </Td>
        <Td>
          <div className={className}>
            {item?.redeemedBy?.redeemedAt?.toString() ??
              formatDate(item?.updatedAt?.toString() ?? "")}
          </div>
        </Td>

        <Td>
          <div className="w-5 h-5 flex justify-center  text-gray-400 hover:border rounded-lg hover:border-gray-300 hover:bg-sky-50 hover:text-indigo-500">
            <ChevronRightIcon
              className=" w-5 h-5"
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </div>
        </Td>
      </Tr>
    </>
  );
};

export default CardItem;
