import { useState } from "react";
import { toast } from "react-toastify";
import { updateTransaction } from "../../api/transactions";
import { TransactionTypes } from "../../types/Transaction";
import Button from "../common/Button";
import Spinner from "../../assets/svg/Spinner";
import {
  TransactionDetailTable,
  TransactionDetailTableHeader,
} from "../TransactionDetail/TransactionDetail";
import { TransactionProcessors } from "../../types/ProcessorTypes";
import { AfriexTransaction, AfriexTransactionStatus } from "../../types";

type TransactionSummaryProp = {
  data: AfriexTransaction;
};

export default function TransactionSummary({
  data,
}: TransactionSummaryProp): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const isRefundable: boolean =
    (data.status === AfriexTransactionStatus.FAILED ||
      data.status === AfriexTransactionStatus.PENDING) &&
    data.type === TransactionTypes.WITHDRAW &&
    data.processor === TransactionProcessors.VFD;

  async function updateTransactionStatus(
    state: AfriexTransactionStatus
  ): Promise<void> {
    try {
      const message =
        state === AfriexTransactionStatus.CANCELLED
          ? "Requesting user wallet refund..."
          : "Updating transaction status...";
      setLoadingMessage(message);
      setLoading(true);

      const res = await updateTransaction(data.id, { state });
      if (res) {
        setLoading(false);
        setLoadingMessage("");
        const message =
          state === AfriexTransactionStatus.CANCELLED
            ? "Refunded users wallet"
            : "Updated Transaction to successful";
        toast.success(message);
      }
    } catch (err: any) {
      const errorMessage = err.response?.data.message
        ? err.response?.data.message
        : err.message;
      toast.error(errorMessage || "Unknown Error. Contact Admin!");
      setLoading(false);
    }
  }

  return (
    <div className="bg-white rounded-lg w-full px-5 sm:px-10 py-5 sm:py-10">
      <TransactionDetailTableHeader data={data} />
      <TransactionDetailTable data={data} />

      {loading && (
        <div className="w-full flex pt-5 sm:pt-10">
          <Spinner size={5} /> {loadingMessage}
        </div>
      )}
      {isRefundable && (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full text-white pt-5 sm:pt-10">
          <div>
            <Button
              name="updateToSuccessful"
              disabled={loading}
              onClick={() =>
                updateTransactionStatus(AfriexTransactionStatus.SUCCESS)
              }
              variant="solid"
              colorScheme="indigo"
            >
              Update to Successful Transaction
            </Button>
          </div>
          <div>
            <Button
              name="refund"
              disabled={loading}
              onClick={() =>
                updateTransactionStatus(AfriexTransactionStatus.CANCELLED)
              }
              variant="solid"
              colorScheme="green"
            >
              Refund to Customer Wallet
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
