import { useMemo } from "react";
import { Button } from "../../../components";
import { mapCountryCodeToFlag } from "../../../constants/countries";
import { BlockedPaymentMethod } from "../../../services/blockedPaymentMethodService";
import { formatDate } from "../../../utils/formatDate";
import {
  transationTypeToLabel,
  transactionTypeToProvider,
  transactionTypeToIdentifier,
} from "./UpdateForm";

type SummaryProp = {
  item: BlockedPaymentMethod;
  onRemove: (id: string) => Promise<void>;
};

const Summary = ({ item, onRemove }: SummaryProp) => {
  const label = useMemo(() => {
    return transationTypeToLabel?.[item.type] ?? "Payment Method";
  }, [item]);

  const providerLabel = useMemo(() => {
    return transactionTypeToProvider?.[item.type] ?? "Provider";
  }, [item]);

  const identifierLabel = useMemo(() => {
    return transactionTypeToIdentifier?.[item.type] ?? "Account Identifier";
  }, [item?.type]);

  return (
    <div className="bg-white rounded-lg w-full px-10 py-3">
      <div className="flex justify-between items-center w-full">
        <h3 className=" font-semibold">Blocked {label} Details</h3>
      </div>

      <div className="text-sm text-slate-500 w-full">
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Date Blocked</div>
          <div className="col-span-2">{formatDate(item?.createdAt ?? "")}</div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>{identifierLabel}</div>
          <div className="col-span-2">{item?.accountIdentifier}</div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>{providerLabel}</div>
          <div className="col-span-2">{item?.provider?.toString()}</div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Country</div>
          <div className="col-span-2">
            {item?.country} {mapCountryCodeToFlag(item?.country)}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Reason</div>
          <div className="col-span-2">{item.reason}</div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div className="col-span-2">
            <div className="flex justify-between">
              <span className="text-red-500"></span>
              <Button
                variant={"solid"}
                className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
                onClick={() => {
                  onRemove(item?.id as string);
                  return false;
                }}
              >
                Unblock
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
