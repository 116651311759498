import { useCallback, useEffect, useState } from "react";
import { userService } from "../../../services/userService";
import { AfriexPerson } from "../../../types";
import { UserListProps } from "./types";
import { AfriexUserQueryParams } from "../../../types/User";
import useFilters from "../../../hooks/useFilters";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

const useUserList = (): UserListProps => {
  const [userList, setUserList] = useState<AfriexPerson[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [isPageReset, setIsPageReset] = useState<boolean>(false);
  const [error, setError] = useState(null);

  const [currentFilters, setCurrentFilters] = useState<AfriexUserQueryParams>(
    {}
  );
  const [filtersToApply, setFiltersToApply] = useState<AfriexUserQueryParams>(
    {}
  );
  const [filterCount, setFilterCount] = useState(0);
  const { applyFilters } = useFilters(setCurrentFilters);

  const fetchAllUsers = useCallback(
    async (params?: AfriexUserQueryParams) => {
      try {
        setError(null);
        setIsLoading(true);
        const { data: users, total } = await userService.getUsers({
          ...params,
          ...currentFilters,
        });
        setUserList(users);
        setUserTotal(total ?? 0);
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    [currentFilters]
  );

  const handleAutoComplete = useCallback(
    async (searchTerm: string) => {
      try {
        setError(null);
        setIsLoading(true);
        setShowPagination(false);
        if (!searchTerm?.trim() || searchTerm.length < 3) {
          await fetchAllUsers({});
          return;
        }
        const users = await userService.searchAutoComplete(searchTerm);
        if (users) {
          setUserList(users);
          setUserTotal(1);
          setIsSearch(true);
        }
      } catch (e) {
        setError(e as any);
      }
      setIsLoading(false);
    },
    [fetchAllUsers]
  );

  useEffect(() => {
    fetchAllUsers({});
  }, [fetchAllUsers]);

  const fetchUserByAccountNumber = useCallback(
    async (bankAccountNumber: string) => {
      try {
        setError(null);
        setIsLoading(true);
        const user =
          await userService.getUserByBankAccountNumber(bankAccountNumber);

        const userList = !isEmpty(user) ? [user] : [];
        const userTotal = !isEmpty(user) ? 1 : 0;
        setUserList(userList);
        setUserTotal(userTotal);
        userTotal === 0 &&
          toast.error("Bank account not associated with a user");
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  // Modified handleApplyFilters to decide which API to call
  const handleApplyFilters = useCallback(() => {
    const { bankAccountNumber } = filtersToApply;
    if (
      bankAccountNumber &&
      typeof bankAccountNumber === "string" &&
      bankAccountNumber.trim() !== ""
    ) {
      fetchUserByAccountNumber(bankAccountNumber);
      return;
    }

    applyFilters(filtersToApply);
    setIsPageReset((prev) => !prev); // Reset pagination to the first page
  }, [applyFilters, filtersToApply, fetchUserByAccountNumber]);

  // Handle change for instant data fetching
  const selectCurrentFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setCurrentFilters((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        return updatedFilters;
      });
      setIsPageReset((prev) => !prev);
    },
    []
  );

  const selectAppliedFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setFiltersToApply((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        setFilterCount(Object.keys(updatedFilters).length);
        return updatedFilters;
      });
    },
    []
  );

  const cleanFilters = () => {
    setCurrentFilters({});
    setFiltersToApply({});
    setFilterCount(0);
    setIsPageReset((prev) => !prev);
  };

  return {
    error,
    fetchUserList: fetchAllUsers,
    isLoading,
    isSearch,
    setPage,
    pageInfo: {
      page,
      total: userTotal,
    },
    showPagination,
    userList,
    filterCount,
    isPageReset,
    currentFilters, // automatically triggers fetch
    filtersToApply, // requires handleApplyFilters to trigger fetch
    clearFilters: cleanFilters,
    selectAppliedFilters, // replaces handleDateChange
    handleApplyFilters,
    selectCurrentFilters, // replaces handleFilterChange
    handleAutoComplete,
  };
};

export default useUserList;
