import { Button } from "../../../components";
import { AfriexVerificationInfo } from "../../../types";
import { AfriexKYCProcessor } from "../../../types/AfriexKYCProcessor";
import { camelToTitle } from "../../../utils/camelToTitle";
import { formatDate, formatTime } from "../../../utils/formatDate";

type KYCVerificationViewProps = {
  processor: AfriexKYCProcessor;
  verification: AfriexVerificationInfo;
  handleDeleteSession: () => void;
};
const KYCVerificationView = ({
  processor,
  verification,
  handleDeleteSession,
}: KYCVerificationViewProps) => {
  return (
    <div className="px-3 pt-3 my-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {`${processor} Verification`.toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <div>
        {/* Status */}
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Status</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {camelToTitle(verification?.status ?? "-")}
          </p>
        </div>

        {/* Attempts */}
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Attempts</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {verification?.attempts ?? "-"}
          </p>
        </div>

        {/* Timeline */}
        <div className="mb-2 pt-2 flex flex-col gap-3 justify-between">
          <p className="text-gray-700 font-medium">Timeline</p>
          {verification?.timeline?.map((item) => (
            <div
              key={item.id}
              className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between"
            >
              <p className="text-slate-500 text-sm">
                {item.title} on{" "}
                <span className="text-gray-600">
                  {formatDate(item.timestamp)} {formatTime(item.timestamp)}
                </span>
              </p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {item.description}
              </p>
            </div>
          ))}
        </div>

        {/* Delete Veriff Session */}
        {processor === AfriexKYCProcessor.Veriff && (
          <div className="mb-2 pb-2 px-3 flex items-center gap-3 justify-between">
            <p className="text-slate-500 text-sm">Handle Veriff Session</p>
            <p className="text-sm font-medium text-gray-700 w-1/2">
              <Button colorScheme="red" onClick={handleDeleteSession}>
                Delete Veriff Session
              </Button>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default KYCVerificationView;
