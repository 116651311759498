import { AfriexCountry, AfriexOTCVolume } from "../../../types";
import { RatesOTCQueryParams } from "../../../types/request";

export interface OTCVolumeReturnProps {
  countryList: AfriexCountry[];
  error: any;
  fetchList: (params: RatesOTCQueryParams) => void;
  handleApprove: (id: string) => void;
  handleFilters: (filters: RatesOTCQueryParams) => void;
  isLoading: boolean;
  isSearch: boolean;
  list: AfriexOTCVolume[];
  page: number;
  setPage: (page: number) => void;
  showPagination: boolean;
  total: number;
}

export enum OTCVolumeActionType {
  UPDATE_OTC_RATE = "UPDATE_OTC_RATE",
}

interface UpdateOTCVolumeAction {
  type: OTCVolumeActionType.UPDATE_OTC_RATE;
  payload: Partial<AfriexOTCVolume>;
}

export type OTCVolumeAction = UpdateOTCVolumeAction;
