import Header from "../../components/common/Header";
import PushNotificationForm from "../../components/PushNotifications/PushNotificationForm";

export default function NotificationPage(): JSX.Element {
  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <Header title="Push Notifications" />
      <PushNotificationForm />
    </div>
  );
}
