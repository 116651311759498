import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import TierForm from "./TierForm";
import { AfriexRateInfo, AfriexTier } from "../../../types";
import { isEmpty } from "lodash";

type TierUpdateButtonProps = {
  initialData?: AfriexTier;
  rateInfo?: AfriexRateInfo;
};

const TierUpdateButton = ({
  initialData,
  rateInfo,
}: TierUpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = isEmpty(initialData)
    ? "Create Rate Tier +"
    : "Update Tier";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <TierForm
      onClose={toggleFullScreen}
      initialData={initialData}
      rateInfo={rateInfo}
    />
  ) : (
    <Button
      variant={"solid"}
      className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
      onClick={toggleFullScreen}
    >
      {updateLabel}
    </Button>
  );
};

export default TierUpdateButton;
