import { identity, pickBy } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { ratesService } from "../../../services/ratesService";
import { AfriexOTCVolume } from "../../../types";
import { RatesOTCQueryParams } from "../../../types/request";
import { OTCVolumeReturnProps } from "./types";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import useCountryList from "../../../hooks/useCountryList";

const useOTCVolumeList = (): OTCVolumeReturnProps => {
  const [list, setList] = useState<AfriexOTCVolume[]>([]);
  const { countryList } = useCountryList();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState<RatesOTCQueryParams>(
    {} as RatesOTCQueryParams
  );

  const fetchList = useCallback(
    async (params: RatesOTCQueryParams) => {
      try {
        setError(null);
        setIsLoading(true);
        const { data: items, total } = await ratesService.getOTCVolumeList({
          ...params,
          ...filters,
        });
        setList(items);
        setUserTotal(total ?? 0);
        if (total && total > 10) {
          setShowPagination(true);
        }
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    [filters]
  );

  const handleFilters = useCallback(async (filters: RatesOTCQueryParams) => {
    // remove undefined values
    const cleanedFilters = pickBy(filters, identity);
    const remappedFilters = cleanedFilters.toDate
      ? {
          ...cleanedFilters,
          toDate: moment(new Date(cleanedFilters.toDate))
            .add(1, "days")
            .toISOString(),
        }
      : cleanedFilters;
    setIsSearch(true);
    setFilters({ page: 0, ...remappedFilters });
  }, []);

  const handleApprove = useCallback(async (id: string) => {
    try {
      await ratesService.approveOTCVolume(id);
      showSuccessMessage(`OTC rate approved successfully`);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e: any) {
      showErrorMessage(`Error approving OTC rate: ${e?.message}`);
      setError(e as any);
    }
  }, []);

  useEffect(() => {
    fetchList({});
  }, [fetchList]);

  return {
    countryList,
    error,
    fetchList,
    handleApprove,
    handleFilters,
    isLoading,
    isSearch,
    list,
    page,
    setPage,
    showPagination,
    total: userTotal,
  };
};

export default useOTCVolumeList;
