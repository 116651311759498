import axios from "../api/axios";
import defaultAxios from "axios";

export const kycBucketKey = process.env.REACT_KYC_BUCKET ?? "selfieBucket";

export type s3URLResponse = {
  url: string;
};

export interface S3URLRequest {
  mode: "putObject" | "getObject"; // putObject for uploading, getObject for retrieval
  bucketKey: string; // The key in our config json that maps to an s3 bucket name
  name?: string; // The object key in the bucket. This is NOT the file name
  userId?: string;
}

export interface s3UploadFileResponse {
  putUrl: string;
  getUrl: string;
}

export const fileService = {
  /**
   * This is to get a presigned url from s3. To avoid DDOS using our image assets.
   * @param data
   * @returns
   */
  getS3Url: async (data: S3URLRequest): Promise<s3URLResponse> => {
    try {
      const response = await axios.post("/v2/admin/kyc/url", data);
      return response.data;
    } catch (error) {
      throw Error("Failed to fetch presigned url for file upload");
    }
  },

  /**
   * To convert presigned s3 url to base64 for other use cases
   * @param name
   * @param bucketKey
   * @param userId
   * @returns
   */
  s3ToBase64: async (
    name: string,
    bucketKey = "ratesBucket",
    userId: string
  ) => {
    const s3Params = {
      mode: "getObject",
      bucketKey,
      name,
      userId,
    } as S3URLRequest;

    const response = await fileService.getS3Url(s3Params);
    const bufferResponse = await defaultAxios.get(response.url, {
      responseType: "arraybuffer",
      headers: {},
    });

    // Example 1: Using Buffer.from (Node.js)
    const base64StringWithBuffer = Buffer.from(
      bufferResponse.data,
      "binary"
    ).toString("base64");

    // Combine with MIME type
    const base64ImageWithBuffer = `data:image/jpeg;base64,${base64StringWithBuffer}`;

    return base64ImageWithBuffer;
  },

  /**
   * Uploads file to S3 with blob
   * @param s3URL
   * @param data
   * @returns Promise<any>
   */
  uploadToS3: async (s3URL: string, data: Blob): Promise<Response> => {
    return fetch(s3URL, {
      method: "PUT",
      body: data,
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  /**
   * To upload file to s3 with pre-defined options.
   * Returns the put and get urls
   * @param fileName
   * @param bucketKey
   * @param userId
   * @param blob
   * @returns Promise<s3UploadFileResponse>
   */
  uploadFile: async (
    fileName: string,
    bucketKey = "ratesBucket",
    userId?: string | null,
    blob?: Blob
  ): Promise<s3UploadFileResponse> => {
    const s3Params = {
      name: fileName,
      bucketKey,
      userId,
      mode: "putObject",
    } as S3URLRequest;
    const s3URL = await fileService.getS3Url(s3Params);
    blob && (await fileService.uploadToS3(s3URL.url, blob));
    const getUrl = await fileService.getS3Url({
      ...s3Params,
      mode: "getObject",
    });

    return {
      putUrl: s3URL?.url,
      getUrl: getUrl?.url,
    };
  },

  /**
   * To get the s3 url with pre-defined options
   * @param fileName
   * @param bucketKey
   * @param userId
   * @returns Promise<string>
   */
  fetchUploadedFile: async (
    fileName: string,
    bucketKey = "ratesBucket",
    userId?: string
  ): Promise<string> => {
    const s3Params = {
      mode: "getObject",
      bucketKey,
      name: fileName,
      userId,
    } as S3URLRequest;
    const s3URL = await fileService.getS3Url(s3Params);
    return s3URL?.url ?? "";
  },
};
