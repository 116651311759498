type HeaderProps = {
  title: string;
  children?: JSX.Element | JSX.Element[];
};

export default function Header({ title, children }: HeaderProps): JSX.Element {
  return (
    <header className="mt-10">
      <h1 className="text-2xl">{title}</h1>
      {children}
    </header>
  );
}
