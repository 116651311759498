import axios from "./axios";

export async function getKeyHoleFiles() {
  const data = {
    actionType: "mongodblogs",
    action: "fetch_mongo_logs",
  };
  const response = await axios.post(`/monitoring`, data, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  const items = response.data as [];
  const keyHoleItems = items.filter((element: any) => {
    return element.Key.includes("keyhole") && element.Key.endsWith("tsv");
  });
  return keyHoleItems;
}

export async function getIndexCreationFiles() {
  const data = {
    actionType: "mongodblogs",
    action: "fetch_mongo_logs",
  };
  const response = await axios.post(`/monitoring`, data, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  });

  const items = response.data as [];
  const indexItems = items.filter((element: any) => {
    return element.Key.includes("index") && element.Key.endsWith("txt");
  });
  return indexItems;
}

export async function getObject(key: string) {
  const data = {
    actionType: "mongodblogs",
    key,
    action: "download_object",
  };
  const response = await axios.post(`/monitoring`, data, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  return response.data;
}
