import jsPDF from "jspdf";
import { DownloadIcon } from "@heroicons/react/outline";
import { Button } from "..";

const GenerateReceiptButton = ({
  fileName,
  label,
}: {
  fileName: string;
  label: string;
}) => {
  const generatePDF = async () => {
    const element = document.getElementById("receipt-parent");

    try {
      if (element) {
        const contentWidth = element.offsetWidth;
        const contentHeight = element.scrollHeight + 5;

        const pdf = new jsPDF({
          unit: "px",
          format: [contentWidth, contentHeight],
        });

        pdf.html(element, {
          callback: function (pdf) {
            const curTime = new Date().getTime();
            pdf.save(`${curTime}_${fileName ?? ""}_afriex.pdf`);
          },
          x: 0,
          y: 0,
          html2canvas: {
            scale: 1,
            logging: true,
            useCORS: true,
            windowWidth: contentWidth,
            windowHeight: contentHeight,
            removeContainer: true,
          },
        });
      } else {
        console.error("Element not found");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <Button
      onClick={generatePDF}
      colorScheme="cyan"
      variant="outline"
      leftIcon={<DownloadIcon className="h-5 w-5" />}
      className="bg-indigo-200"
    >
      {label}
    </Button>
  );
};

export default GenerateReceiptButton;
