import {
  Header,
  FilterBar,
  Pagination,
  Table,
  TBody,
  Tabs,
} from "../../../components";
import {
  FilterAttribute,
  FilterAttributeType,
} from "../../../components/FilterBar/types";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import { TabItem } from "../../../types";

import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import useCountryUpdate from "./useCountryUpdate";

const CountryList = () => {
  const { isLoading, isSearch, fetchList, handleFilters, total, list } =
    useCountryUpdate();
  const totalFigure = total ? ` (${total.toLocaleString()})` : "";

  /**
   * These are the filter attributes that will be passed to the filter bar
   */
  const attributes: FilterAttribute[] = [
    {
      title: "",
      label: "status",
      type: FilterAttributeType.Select,
      options: {
        active: "Active",
        inactive: "Not Active",
      },
    },
  ];

  const searchTabs: TabItem[] = [
    {
      id: "filter",
      title: "Filter",
      content: (
        <FilterBar attributes={attributes} onSubmit={handleFilters as any} />
      ),
    },
  ];

  return (
    <div className="mx-10">
      <Header title={"Manage Country List" + totalFigure} />
      <Tabs tabs={searchTabs} />

      <main className="pt-5 py-10 overflow-x-auto">
        {isLoading ? (
          <div className="min-w-full">
            <TableSkeleton />
          </div>
        ) : (
          <Table>
            <ListHeader />
            <TBody>
              {list?.map((item) => <ListItem item={item} key={item.id} />)}
            </TBody>
          </Table>
        )}
      </main>

      {list && list.length > 1 && (
        <Pagination
          onPageChange={fetchList as any}
          total={total}
          isSearch={isSearch}
        />
      )}
    </div>
  );
};

export default CountryList;
