export enum AfriexRoles {
  admin = "admin",
  customer_care = "customer_care",
  agent = "agent",
  super_admin = "super_admin",
  test = "test",
  top100Customer = "top-100-customer",
  user = "user",
  engineering = "engineering",
  customer_support_manager = "customer.support.manager",
  customer_support_member = "customer.support.member",
  compliance_member = "compliance.member",
  compliance_manager = "compliance.manager",
  finance_manager = "finance.manager",
  finance_member = "finance.member",
  growth_manager = "growth.manager",
  growth_member = "growth.member",
  operations_manager = "operations.manager",
  operations_member = "operations.member",
  peoples_operations_manager = "peoples.operations.manager",
  peoples_operations_member = "peoples.operations.member",
  product_eng_qa_design_member = "product.eng.qa.design.member",
  product_eng_qa_design_manager = "product.eng.qa.design.manager",
}
