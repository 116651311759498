import { useParams } from "react-router-dom";
import { Header, Tabs } from "../../../components";
import { TabItem } from "../../../types";
import useReward from "./useReward";
import CardList from "./CardList";

const Details = () => {
  const params = useParams();
  const rewardId = params?.id ?? "";
  const { isLoading, item } = useReward();

  const tabs: TabItem[] = [
    { id: "cards", title: " Cards", content: <CardList rewardId={rewardId} /> },
  ];
  return (
    <div>
      <Header title={`${item?.name ?? ""} Reward Item`} />
      <Tabs key={isLoading?.toString()} tabs={tabs} queryKey={"tab"} />;
    </div>
  );
};
export default Details;
