import UserList from "../screens/User/List";
import UserDetails from "../screens/User/Details"; //
import KYCList from "../screens/KYC/List";
import KYCDetails from "../screens/KYC/Details";
import ReferralList from "../screens/Referral/List";
import ReferralTierList from "../screens/Referral/Tier/List";
import ReferralTierDetials from "../screens/Referral/Tier/Details";
import NotificationPage from "../pages/PushNotification/NotificationPage";
import TransactionList from "../screens/Transaction/List";
import TransactionDetailsPage from "../screens/Transaction/Details/TransactionDetailsPage";
import TransactionReceiptPage from "../screens/Transaction/Details/TransactionReceiptPage";
import LoginPage from "../screens/Auth/Login";
import OTPPage from "../screens/Auth/OTP";
import RatesPage from "../screens/Rates";
import UserTransactionsPage from "../screens/Transaction/List/User";
import BulkPaymentPage from "../pages/BulkPayment/BulkPaymentPage";
import MonitorPage from "../pages/Monitor/MonitorPage";
import MonitorBoardPage from "../pages/Monitor/MonitorBoardPage";
import ProvidersPage from "../pages/Monitor/ProvidersPage";
import PromotionsPage from "../pages/Promotions";
import ProcessorList from "../screens/Processor/List";
import BlockedPaymentMethodList from "../screens/Blocked/PaymentMethods";
import { PageRoles } from "./pageRoles";
import HomePage from "../pages/HomePage";
import CountryList from "../screens/Settings/Country";
import CountryDetails from "../screens/Settings/Country/Details";
import CurrencyList from "../screens/Settings/Currency";
import CurrencyDetails from "../screens/Settings/Currency/Details";
import TierDetails from "../screens/Rates/Promo/Details";
import RewardCards from "../screens/Rewards/Card";
import RewardDetails from "../screens/Rewards/Card/Details";
import Metrics from "../screens/Processor/Metrics";
import UserLogList from "../screens/User/Log";
import Fees from "../screens/Fees";
import Disputes from "../screens/Disputes/Disputes";
import KycReceiptPage from "../screens/KYC/Details/KycReceiptPage";

export const appPaths = {
  blockedPaymentMethods: "blocked-payment-methods",
  bulkPayments: "bulk-payments",
  cards: "rewards/cards",
  countries: "settings/countries",
  currencies: "settings/currencies",
  financialServices: "financial-services",
  forgotPassword: "reset-password",
  fees: "fees",
  home: "dashboard",
  kyc: "kyc",
  login: "login",
  logs: "logs",
  monitor: "monitor",
  notification: "notification",
  processors: "processors",
  promotions: "promotions",
  rates: "rates",
  referral: "referral",
  rewards: "rewards",
  settings: "settings",
  tiers: "tiers",
  transactions: "transactions",
  users: "users",
  verifyOtp: "verifyOtp",
  disputes: "disputes",
};

export const privateRoutesConfig = [
  {
    path: appPaths.home,
    Component: HomePage,
    roles: PageRoles.ALL_ROLES,
  },

  {
    path: appPaths.transactions,
    Component: TransactionList,
    roles: PageRoles.TRANSACTIONS_PAGE,
  },

  {
    path: `${appPaths.transactions}/:transactionId`,
    Component: TransactionDetailsPage,
    roles: PageRoles.TRANSACTIONS_DETAIL_PAGE,
  },
  {
    path: `${appPaths.transactions}/:transactionId/receipt`,
    Component: TransactionReceiptPage,
    roles: PageRoles.TRANSACTIONS_DETAIL_PAGE,
  },
  {
    path: `${appPaths.transactions}/user/:userId`,
    Component: UserTransactionsPage,
    roles: PageRoles.TRANSACTIONS_DETAIL_PAGE,
  },
  {
    path: `${appPaths.transactions}/user/:userId/receipt`,
    Component: TransactionReceiptPage,
    roles: PageRoles.TRANSACTIONS_DETAIL_PAGE,
  },
  {
    path: appPaths.users,
    Component: UserList,
    roles: PageRoles.USERS_PAGE,
  },
  {
    path: appPaths.processors,
    Component: ProcessorList,
    roles: PageRoles.USERS_PAGE,
  },
  {
    path: appPaths.processors + "/metrics",
    Component: Metrics,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: appPaths.referral,
    Component: ReferralList,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: appPaths.referral + "/list",
    Component: ReferralList,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: appPaths.referral + "/tiers",
    Component: ReferralTierList,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: `${appPaths.referral}/tiers/:tierId`,
    Component: ReferralTierDetials,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: appPaths.kyc,
    Component: KYCList,
    roles: PageRoles.KYC_PAGE,
  },
  {
    path: `${appPaths.kyc}/:userId`,
    Component: KYCDetails,
    roles: PageRoles.KYC_PAGE,
  },
  {
    path: `${appPaths.kyc}/:userId/receipt`,
    Component: KycReceiptPage,
    roles: PageRoles.KYC_PAGE,
  },
  {
    path: `${appPaths.users}/:userId`,
    Component: UserDetails,
    roles: PageRoles.USERS_DETAIL_PAGE,
  },
  {
    path: appPaths.promotions,
    Component: PromotionsPage,
    roles: PageRoles.PROMOTIONS_PAGE,
  },

  {
    path: appPaths.blockedPaymentMethods,
    Component: BlockedPaymentMethodList,
    roles: PageRoles.ALL_ROLES,
  },

  {
    path: appPaths.notification,
    Component: NotificationPage,
    roles: PageRoles.NOTIFICATIONS_PAGE_ROUTE,
  },
  {
    path: appPaths.rates,
    Component: RatesPage,
    roles: PageRoles.RATES_PAGE_ROUTE,
  },
  {
    path: `${appPaths.tiers}/:tierId`,
    Component: TierDetails,
    roles: PageRoles.TRANSACTIONS_DETAIL_PAGE,
  },
  {
    path: `${appPaths.bulkPayments}`,
    Component: BulkPaymentPage,
    roles: PageRoles.BULK_PAYMENTS_PAGE_ROUTE,
  },
  {
    path: `${appPaths.monitor}`,
    Component: MonitorBoardPage,
    roles: PageRoles.MONITORING_PAGE,
  },
  {
    path: `${appPaths.monitor}/mongo-db`,
    Component: MonitorPage,
    roles: PageRoles.MONITORING_MONGODB_PAGE,
  },
  {
    path: `${appPaths.monitor}/providers`,
    Component: ProvidersPage,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: appPaths.settings,
    Component: CountryList,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: appPaths.rewards,
    Component: RewardCards,
    roles: PageRoles.PROMOTIONS_PAGE,
  },
  {
    path: appPaths.cards,
    Component: RewardCards,
    roles: PageRoles.PROMOTIONS_PAGE,
  },
  {
    path: appPaths.countries,
    Component: CountryList,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: `${appPaths.countries}/:countryCode`,
    Component: CountryDetails,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: appPaths.currencies,
    Component: CurrencyList,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: `${appPaths.currencies}/:currencyCode`,
    Component: CurrencyDetails,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: `${appPaths.rewards}/:id`,
    Component: RewardDetails,
    roles: PageRoles.PROMOTIONS_PAGE,
  },
  {
    path: `${appPaths.logs}/:userId`,
    Component: UserLogList,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: appPaths.fees,
    Component: Fees,
    roles: PageRoles.ALL_ROLES,
  },
  {
    path: appPaths.disputes,
    Component: Disputes,
    roles: PageRoles.ALL_ROLES,
  },
];

export const publicRoutesConfig = [
  {
    path: appPaths.login,
    Component: LoginPage,
    roles: [],
  },

  {
    path: appPaths.verifyOtp,
    Component: OTPPage,
    roles: [],
  },
];
