import {
  Header,
  FilterBar,
  Pagination,
  SearchBar,
  Table,
  TBody,
  Tabs,
} from "../../../components";
import {
  FilterAttribute,
  FilterAttributeType,
} from "../../../components/FilterBar/types";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import { doNothing } from "../../../constants/formatters";
import { TabItem, TransactionChannels } from "../../../types";

import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import UpdateButton from "./UpdateButton";
import useBlockedPaymentMethod from "./useBlockedPaymentMethod";

const BlockedPaymentMethodList = () => {
  const {
    isLoading,
    isSearch,
    fetchList,
    handleAddItem,
    handleSearch,
    handleRemoveItem,
    handleFilters,
    total,
    countryList,
    list,
  } = useBlockedPaymentMethod();
  const totalFigure = total ? ` (${total.toLocaleString()})` : "";

  /**
   * These are the filter attributes that will be passed to the filter bar
   */
  const attributes: FilterAttribute[] = [
    {
      title: "Country",
      label: "country",
      type: FilterAttributeType.Select,
      options: countryList?.reduce(
        (acc, item) => {
          acc[item.iso2] = item.name;
          return acc;
        },
        {} as Record<string, string>
      ),
    },
    {
      title: "Channel",
      label: "type",
      type: FilterAttributeType.Select,
      options: {
        [TransactionChannels.CARD]: "Debit Card",
        [TransactionChannels.BANK_ACCOUNT]: "Bank Account",
        [TransactionChannels.MOBILE_MONEY]: "Mobile Money Wallet",
      },
    },
  ];

  const searchTabs: TabItem[] = [
    {
      id: "search",
      title: "Search",
      content: (
        <SearchBar
          handleSearch={handleSearch}
          handleAutoComplete={doNothing}
          placeholder="Search by account or card number"
        />
      ),
    },
    {
      id: "filter",
      title: "Filter",
      content: (
        <FilterBar attributes={attributes} onSubmit={handleFilters as any} />
      ),
    },
  ];

  return (
    <div className=" my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <Header title={"Blocked Payment Method List" + totalFigure} />
      <div className="w-full flex justify-end mb-3">
        <UpdateButton handleSave={handleAddItem} countryList={countryList} />
      </div>
      <Tabs tabs={searchTabs} />

      <main className="pt-5 py-10 overflow-x-auto">
        {isLoading ? (
          <div className="min-w-full">
            <TableSkeleton />
          </div>
        ) : (
          <Table>
            <ListHeader />
            <TBody>
              {list?.map((item) => (
                <ListItem
                  item={item}
                  key={item.id}
                  onRemove={handleRemoveItem}
                />
              ))}
            </TBody>
          </Table>
        )}
      </main>

      {list && list.length > 1 && (
        <Pagination
          onPageChange={fetchList as any}
          total={total}
          isSearch={isSearch}
        />
      )}
    </div>
  );
};

export default BlockedPaymentMethodList;
