const RatesUploadForm = ({
  handleAppRatesUpload,
  handleTemplateDownload,
}: any) => {
  return (
    <section className="rates-upload p-8 border-2 mt-10">
      <aside className="rates-file-top">File Upload</aside>
      <p
        className="download-rate-template justify-right"
        onClick={handleTemplateDownload}
      >
        **Download rate file template**
      </p>

      <label
        className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
        htmlFor="file_input"
      >
        App Rates file upload (CSV)
      </label>
      <input
        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        accept="text/csv"
        type={"file"}
        onChange={handleAppRatesUpload}
      />
      <p className="my-12" />
    </section>
  );
};

export default RatesUploadForm;
