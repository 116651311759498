import {
  Modal,
  Input,
  Button,
  SpinningLoader,
  CountryPicker,
  DatePicker,
  AmountInput,
} from "../../../components";
import {
  AfriexAmount,
  AfriexOTCVolume,
  SupportedCountryCodes,
} from "../../../types";
import useOTCVolumeUpdate from "./useOTCVolumeUpdate";
import { OTCVolumeActionType } from "./types";
import { isEmpty } from "lodash";
import { BigNumber } from "bignumber.js";

type OTCVolumeFormProps = {
  onClose: VoidFunction;
  initialData?: AfriexOTCVolume;
};

const OTCVolumeForm = ({ onClose, initialData }: OTCVolumeFormProps) => {
  const { handleSubmit, otcRate, dispatch, isLoading, handleOTCRatesUpload } =
    useOTCVolumeUpdate(onClose, initialData);

  const { timestamp, country, fromSymbol, toSymbol, fromAmount, toAmount } =
    otcRate;

  const updateAction = {
    type: OTCVolumeActionType.UPDATE_OTC_RATE,
    payload: {},
  };

  const updatedRate =
    toAmount && fromAmount
      ? new BigNumber(toAmount?.toString())
          .dividedBy(fromAmount?.toString())
          ?.toNumber()
      : 0.0;
  const isDisabled =
    !fromAmount ||
    !toAmount ||
    !country ||
    !updatedRate ||
    isLoading ||
    !fromSymbol ||
    !toSymbol;
  const updateLabel = isEmpty(initialData)
    ? "Upload OTC Volume +"
    : "Update OTC Volume";

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom">{updateLabel}</h3>
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form
              onSubmit={() => handleSubmit(otcRate) as any}
              className="flex flex-col space-y-5"
            >
              <div className="mt-5">
                <DatePicker
                  label="Date & Time of Transaction"
                  onChange={(date: Date) =>
                    dispatch({ ...updateAction, payload: { timestamp: date } })
                  }
                  value={timestamp || new Date()}
                  showTime
                />
              </div>
              <div className="mt-5">
                <CountryPicker
                  label="Country of Trade"
                  onChange={(value: SupportedCountryCodes) =>
                    dispatch({ ...updateAction, payload: { country: value } })
                  }
                  value={country}
                  filter={{ status: "active" }}
                />
              </div>

              <div className="mt-5">
                <AmountInput
                  label="Amount Sent"
                  onChange={(value: AfriexAmount) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        fromAmount: value?.amount ?? fromAmount,
                        fromSymbol: value.currency ?? fromSymbol,
                      },
                    })
                  }
                  value={{ amount: fromAmount, currency: fromSymbol }}
                />
              </div>
              <div className="mt-5">
                <AmountInput
                  label="Amount Received"
                  onChange={(value: AfriexAmount) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        toAmount: value?.amount ?? toAmount,
                        toSymbol: value.currency ?? toSymbol,
                        rate: updatedRate,
                      },
                    })
                  }
                  value={{ amount: toAmount, currency: toSymbol }}
                />
              </div>
              <div className="mt-5">
                <Input
                  label="Rate"
                  type="number"
                  disabled
                  value={updatedRate?.toString()}
                />
              </div>
              <label
                className="block mb-2 text-md font-bold text-gray-900 dark:text-white"
                htmlFor="file_input"
              >
                OTC Rates file upload (CSV)
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                accept="text/csv"
                type={"file"}
                onChange={handleOTCRatesUpload}
              />

              <div className="flex w-full justify-end space-x-2 py-5">
                <Button onClick={onClose} variant="outline">
                  Cancel
                </Button>
                <Button
                  disabled={isDisabled}
                  type="submit"
                  variant="outline"
                  className="bg-indigo-200 hover:g"
                >
                  Submit Rates
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default OTCVolumeForm;
