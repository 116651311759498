import React, { ChangeEvent, useEffect, useState } from "react";

interface EditableFieldProps {
  isEditing: boolean;
  fieldName: string;
  fieldValue: string | number | boolean;
  handleInputChange: (event: { name: string; value: string | number }) => void;
  type: string;
  options?: string[];
}

interface DiscountFieldEditableProps {
  isEditing: boolean;
  fieldName: string;
  fieldValue: string | number;
  handleInputChange: (event: { name: string; value: string | number }) => void;
  type: string;
  options?: string[];
}

export const EditablePercentageField: React.FC<DiscountFieldEditableProps> = ({
  isEditing,
  fieldName,
  fieldValue,
  handleInputChange,
}) => {
  const [visibleValue, setVisibleValue] = useState<string | number>(
    parseFloat(fieldValue.toString()) * 100
  );

  const handleInputChangeInternal = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setVisibleValue(value);
  };

  useEffect(() => {
    if (isEditing) {
      const newValue =
        fieldName === "discount"
          ? parseFloat(visibleValue.toString()) / 100
          : visibleValue;
      handleInputChange({
        name: fieldName,
        value: newValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleValue, isEditing, fieldName]);

  return (
    <div className="flex-1 p-2">
      {isEditing ? (
        <input
          type="number"
          name={fieldName}
          value={visibleValue}
          onChange={handleInputChangeInternal}
        />
      ) : (
        <p>{`${parseFloat(fieldValue.toString()) * 100}%`}</p>
      )}
    </div>
  );
};

export const EditableTextField: React.FC<EditableFieldProps> = ({
  isEditing,
  fieldName,
  fieldValue,
  handleInputChange,
  type,
  options,
}) => {
  const handleInput = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    handleInputChange({ name, value });
  };

  return (
    <div className="flex-1 p-2">
      {isEditing ? (
        type === "select" ? (
          <select
            name={fieldName}
            value={fieldValue?.toString()}
            onChange={handleInput}
          >
            {options?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            name={fieldName}
            value={fieldValue?.toString()}
            onChange={handleInput}
          />
        )
      ) : (
        <p>
          {fieldName === "discount"
            ? `${parseFloat(fieldValue.toString()) * 100}%`
            : fieldValue}
        </p>
      )}
    </div>
  );
};
