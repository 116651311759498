import UserRoles from "../../types/UserRoles";

export const PageRolePermissions = {
  roles: [
    {
      role: "peoples.operations.manager",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Referral",
          permission: "referral",
          actions: [
            "click-referral-search-username",
            "click-referral-search-start-date",
            "click-referral-search-end-date",
            "click-referral-search",
            "click-referral-search-clear-filter",
            "click-referral-export-as-csv",
            "view-referral-details-panel",
            "referral-details",
            "click-referral-details-referral-by-username",
            "view-referral-details-referral-username",
            "view-referral-details-referee-username",
          ],
        },
        {
          name: "Bulk Payments",
          permission: "bulk-payment",
          actions: [
            "click-bulk-payment-upload-csv-file",
            "view-bulk-payment-recent-bulk-payments",
          ],
        },
      ],
    },
    {
      role: "peoples.operations.member",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Bulk Payments",
          permission: "bulk-payment",
          actions: [
            "click-bulk-payment-upload-csv-file",
            "view-bulk-payment-recent-bulk-payments",
          ],
        },
      ],
    },
    {
      role: "finance.manager",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Referral",
          permission: "referral",
          actions: [
            "click-referral-search-username",
            "click-referral-search-start-date",
            "click-referral-search-end-date",
            "click-referral-search",
            "click-referral-search-clear-filter",
            "click-referral-export-as-csv",
            "view-referral-details-panel",
            "referral-details",
            "click-referral-details-referral-by-username",
            "view-referral-details-referral-username",
            "view-referral-details-referee-username",
          ],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "click-transaction-export",
            "view-transaction-details",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view", "click-transaction-details-export"],
        },
        {
          name: "Rates",
          permission: "rates-dashboard",
          actions: [
            "click-rates-dashboard-download-rate-file-template",
            "click-rates-dashboard-choose-file",
            "view-rates-dashboard-preview",
            "view-rates-dashboard-live-rates",
          ],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-failed-page",
          actions: ["view"],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: ["view"],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "view-user-details",
            "view-user-summary-view-transactions",
            "click-user-summary-block-user",
            "click-user-summary-view-more",
            "add-user-summary-security-notes",
            "view-user-email",
            "view-user-phonenumber",
          ],
        },
        {
          name: "User summary panel",
          permission: "user-summary",
          actions: [
            "click-user-summary-swap",
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Detail Pages",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "click-user-details-download-KYC",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-update-modal",
            "add-user-details-update-modal-Email",
            "add-user-details-update-modal-phone",
            "view-user-details-update-modal-verify-phone-number",
            "user-detail-device-ID-modal",
            "user-detail-device-ID-clear",
            "user-detail-device-ID-cancel",
          ],
        },
      ],
    },
    {
      role: "finance.member",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "click-transaction-export",
            "view-transaction-details",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view", "click-transaction-details-export"],
        },
        {
          name: "Rates",
          permission: "rates-dashboard",
          actions: [
            "click-rates-dashboard-download-rate-file-template",
            "click-rates-dashboard-choose-file",
            "view-rates-dashboard-preview",
            "view-rates-dashboard-live-rates",
          ],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-failed-page",
          actions: ["view"],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: ["view"],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "view-user-details",
            "view-user-summary-view-transactions",
            "click-user-summary-block-user",
            "click-user-summary-view-more",
            "add-user-summary-security-notes",
            "view-user-email",
            "view-user-phonenumber",
          ],
        },
        {
          name: "User summary panel",
          permission: "user-summary",
          actions: [
            "click-user-summary-swap",
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Detail Pages",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "click-user-details-download-KYC",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-update-modal",
            "add-user-details-update-modal-Email",
            "add-user-details-update-modal-phone",
            "view-user-details-update-modal-verify-phone-number",
            "user-detail-device-ID-modal",
            "user-detail-device-ID-clear",
            "user-detail-device-ID-cancel",
          ],
        },
      ],
    },
    {
      role: "product.eng.qa.design.member",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "view-transaction-details",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view"],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-pending-page",
          actions: [""],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: [""],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "view-user-details",
          ],
        },
        {
          name: "User Summary Panel",
          permission: "user-summary",
          actions: [
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Details Page",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-device-ID-modal",
            "user-detail-device-ID-clear",
            "user-detail-device-ID-cancel",
          ],
        },
        {
          name: "KYC",
          permission: "access-kyc-page",
          actions: [
            "kyc-user-summary",
            "click-kyc-user-summary-security-notes",
          ],
        },
        {
          name: "Rates",
          permission: "rates-dashboard",
          actions: [
            "view-rates-dashboard-preview",
            "view-rates-dashboard-live-rates",
          ],
        },
      ],
    },
    {
      role: "product.eng.qa.design.manager",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "view-transaction-details",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view"],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-pending-page",
          actions: [""],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: [""],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "view-user-details",
          ],
        },
        {
          name: "User Summary Panel",
          permission: "user-summary",
          actions: [
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Details Page",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-device-ID-modal",
            "user-detail-device-ID-clear",
            "user-detail-device-ID-cancel",
          ],
        },
        {
          name: "KYC",
          permission: "access-kyc-page",
          actions: [
            "kyc-user-summary",
            "click-kyc-user-summary-security-notes",
          ],
        },
      ],
    },
    {
      role: "growth.manager",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Referral",
          permission: "referral",
          actions: [
            "click-referral-search-username",
            "click-referral-search-start-date",
            "click-referral-search-end-date",
            "click-referral-search",
            "click-referral-search-clear-filter",
            "click-referral-export-as-csv",
            "view-referral-details-panel",
            "referral-details",
            "click-referral-details-referral-by-username",
            "view-referral-details-referral-username",
            "view-referral-details-referee-username",
          ],
        },
        {
          name: "Rates",
          permission: "rates-dashboard",
          actions: [
            "click-rates-dashboard-download-rate-file-template",
            "click-rates-dashboard-choose-file",
            "view-rates-dashboard-preview",
            "view-rates-dashboard-live-rates",
          ],
        },
        {
          name: "Notifications",
          permission: "push-notifications",
          actions: [
            "click-push-notification-mass-push",
            "click-push-notification-individual",
            "click-push-notification-email",
            "click-push-notification-title",
            "click-push-notification-message",
            "click-push-notification-submit",
          ],
        },
      ],
    },
    {
      role: "growth.member",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Referral",
          permission: "referral",
          actions: [
            "click-referral-search-username",
            "click-referral-search-start-date",
            "click-referral-search-end-date",
            "click-referral-search",
            "click-referral-search-clear-filter",
            "click-referral-export-as-csv",
            "view-referral-details-panel",
            "referral-details",
            "click-referral-details-referral-by-username",
            "view-referral-details-referral-username",
            "view-referral-details-referee-username",
          ],
        },
        {
          name: "Rates",
          permission: "rates-dashboard",
          actions: [
            "click-rates-dashboard-download-rate-file-template",
            "click-rates-dashboard-choose-file",
            "view-rates-dashboard-preview",
            "view-rates-dashboard-live-rates",
          ],
        },
        {
          name: "Notifications",
          permission: "push-notifications",
          actions: [
            "click-push-notification-mass-push",
            "click-push-notification-individual",
            "click-push-notification-email",
            "click-push-notification-title",
            "click-push-notification-message",
            "click-push-notification-submit",
          ],
        },
      ],
    },
    {
      role: "engineering",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "click-transaction-export",
            "view-transaction-details",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view", "click-transaction-details-export"],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-failed-page",
          actions: ["view"],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: ["view"],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "view-user-details",
            "view-user-summary-view-transactions",
            "click-user-summary-block-user",
            "click-user-summary-view-more",
            "add-user-summary-security-notes",
            "view-user-email",
            "view-user-phonenumber",
            "view",
          ],
        },
        {
          name: "User summary panel",
          permission: "user-summary",
          actions: [
            "click-user-summary-swap",
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Detail Pages",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "click-user-details-download-KYC",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-update-modal",
            "add-user-details-update-modal-Email",
            "add-user-details-update-modal-phone",
            "view-user-details-update-modal-verify-phone-number",
            "user-detail-device-ID-modal",
            "user-detail-device-ID-clear",
            "user-detail-device-ID-cancel",
          ],
        },
        {
          name: "KYC",
          permission: "access-kyc-page",
          actions: [
            "click-kyc-search-start-date",
            "click-kyc-search-end-date",
            "click-kyc-search",
            "click-kyc-search-clear-filter",
            "view-kyc-user-panel",
            "view-kyc-user-details",
            "kyc-user-summary",
            "click-kyc-user-summary-swap",
            "click-kyc-user-summary-security-notes",
            "view-kyc-user-summary-view-transactions",
            "click-kyc-user-summary-view-more",
            "click-kyc-user-summary-block-user",
            "click-kyc-request-documents",
          ],
        },
        {
          name: "Notifications",
          permission: "push-notifications",
          actions: [
            "click-push-notification-mass-push",
            "click-push-notification-individual",
            "click-push-notification-email",
            "click-push-notification-title",
            "click-push-notification-message",
            "click-push-notification-submit",
          ],
        },
        {
          name: "Rates",
          permission: "rates-dashboard",
          actions: [
            "view-rates-dashboard-preview",
            "view-rates-dashboard-live-rates",
          ],
        },
        {
          name: "Bulk Payments",
          permission: "bulk-payment",
          actions: [
            "click-bulk-payment-upload-csv-file",
            "view-bulk-payment-recent-bulk-payments",
          ],
        },
      ],
    },
    {
      role: "operations.manager",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "click-transaction-export",
            "view-transaction-details",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view", "click-transaction-details-export"],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-failed-page",
          actions: ["view"],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: ["view"],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "view-user-details",
            "view-user-summary-view-transactions",
            "click-user-summary-block-user",
            "click-user-summary-view-more",
            "add-user-summary-security-notes",
            "view-user-email",
            "view-user-phonenumber",
            "view",
          ],
        },
        {
          name: "User summary panel",
          permission: "user-summary",
          actions: [
            "click-user-summary-swap",
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Detail Pages",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "click-user-details-download-KYC",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-update-modal",
            "add-user-details-update-modal-Email",
            "add-user-details-update-modal-phone",
            "view-user-details-update-modal-verify-phone-number",
            "user-detail-device-ID-modal",
            "user-detail-device-ID-clear",
            "user-detail-device-ID-cancel",
          ],
        },
        {
          name: "KYC",
          permission: "access-kyc-page",
          actions: [
            "click-kyc-search-start-date",
            "click-kyc-search-end-date",
            "click-kyc-search",
            "click-kyc-search-clear-filter",
            "view-kyc-user-panel",
            "view-kyc-user-details",
            "kyc-user-summary",
            "click-kyc-user-summary-swap",
            "click-kyc-user-summary-security-notes",
            "view-kyc-user-summary-view-transactions",
            "click-kyc-user-summary-view-more",
            "click-kyc-user-summary-block-user",
          ],
        },
        {
          name: "Notifications",
          permission: "push-notifications",
          actions: [
            "click-push-notification-mass-push",
            "click-push-notification-individual",
            "click-push-notification-email",
            "click-push-notification-title",
            "click-push-notification-message",
            "click-push-notification-submit",
          ],
        },
        {
          name: "Rates",
          permission: "rates-dashboard",
          actions: [
            "view-rates-dashboard-preview",
            "view-rates-dashboard-live-rates",
          ],
        },
        {
          name: "Bulk Payments",
          permission: "bulk-payment",
          actions: [
            "click-bulk-payment-upload-csv-file",
            "view-bulk-payment-recent-bulk-payments",
          ],
        },
      ],
    },
    {
      role: "operations.member",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "click-transaction-export",
            "view-transaction-details",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view", "click-transaction-details-export"],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-failed-page",
          actions: ["view"],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: ["view"],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "view-user-details",
            "view-user-summary-view-transactions",
            "click-user-summary-block-user",
            "click-user-summary-view-more",
            "add-user-summary-security-notes",
            "view-user-email",
            "view-user-phonenumber",
          ],
        },
        {
          name: "User summary panel",
          permission: "user-summary",
          actions: [
            "click-user-summary-swap",
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Detail Pages",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "click-user-details-download-KYC",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-update-modal",
            "add-user-details-update-modal-Email",
            "add-user-details-update-modal-phone",
            "view-user-details-update-modal-verify-phone-number",
            "user-detail-device-ID-modal",
            "user-detail-device-ID-clear",
            "user-detail-device-ID-cancel",
          ],
        },
        {
          name: "KYC",
          permission: "access-kyc-page",
          actions: [
            "kyc-user-summary",
            "click-kyc-user-summary-swap",
            "click-kyc-user-summary-security-notes",
            "view-kyc-user-summary-view-transactions",
            "click-kyc-user-summary-view-more",
            "click-kyc-user-summary-block-user",
          ],
        },
      ],
    },
    {
      role: "compliance.member",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "view-transaction-details",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view", "click-transaction-details-export"],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-failed-page",
          actions: [""],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: [""],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "click-user-export",
            "view-user-details",
            "view-user-summary-view-transactions",
            "click-user-summary-block-user",
            "click-user-summary-view-more",
            "add-user-summary-security-notes",
          ],
        },
        {
          name: "User summary panel",
          permission: "user-summary",
          actions: [
            "click-user-summary-swap",
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Detail Pages",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "click-user-details-download-KYC",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-update-modal",
            "add-user-details-update-modal-first-name",
            "add-user-details-update-modal-last-name",
            "add-user-details-update-modal-email",
            "add-user-details-update-modal-phone",
            "view-user-details-update-modal-verify-phone-number",
            "click-user-details-update-modal-cancel",
            "click-user-details-update-modal-update-user",
            "user-detail-upload-modal",
            "user-details-upload-modal-selfie-photo",
            "user-details-upload-modal-ID-Front",
            "user-details-upload-modal-ID-Back",
            "user-details-upload-modal-Date-of-back",
            "user-details-upload-modal-ID-Type",
            "user-details-upload-modal-ID-Number",
            "user-details-upload-modal-cancel",
            "user-details-upload-modal-Upload-Details",
            "user-detail-device-ID-modal",
            "user-detail-device-ID-cancel",
          ],
        },
        {
          name: "KYC",
          permission: "access-kyc-page",
          actions: [
            "click-kyc-search-start-date",
            "click-kyc-search-end-date",
            "click-kyc-search",
            "click-kyc-search-clear-filter",
            "view-kyc-user-panel",
            "view-kyc-user-details",
            "kyc-user-summary",
            "click-kyc-user-summary-swap",
            "click-kyc-user-summary-security-notes",
            "view-kyc-user-summary-view-transactions",
            "click-kyc-user-summary-view-more",
            "click-kyc-user-summary-block-user",
            "click-kyc-request-documents",
          ],
        },
      ],
    },
    {
      role: "compliance.manager",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "view-transaction-details",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view", "click-transaction-details-export"],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-failed-page",
          actions: [""],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: [""],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "click-user-export",
            "view-user-details",
            "view-user-summary-view-transactions",
            "click-user-summary-block-user",
            "click-user-summary-view-more",
            "add-user-summary-security-notes",
          ],
        },
        {
          name: "User summary panel",
          permission: "user-summary",
          actions: [
            "click-user-summary-swap",
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Detail Pages",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "click-user-details-download-KYC",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-update-modal",
            "add-user-details-update-modal-first-name",
            "add-user-details-update-modal-last-name",
            "add-user-details-update-modal-email",
            "add-user-details-update-modal-phone",
            "view-user-details-update-modal-verify-phone-number",
            "click-user-details-update-modal-cancel",
            "click-user-details-update-modal-update-user",
            "user-detail-upload-modal",
            "user-details-upload-modal-selfie-photo",
            "user-details-upload-modal-ID-Front",
            "user-details-upload-modal-ID-Back",
            "user-details-upload-modal-Date-of-back",
            "user-details-upload-modal-ID-Type",
            "user-details-upload-modal-ID-Number",
            "user-details-upload-modal-cancel",
            "user-details-upload-modal-Upload-Details",
            "user-detail-device-ID-modal",
            "user-detail-device-ID-cancel",
          ],
        },
        {
          name: "KYC",
          permission: "access-kyc-page",
          actions: [
            "click-kyc-search-start-date",
            "click-kyc-search-end-date",
            "click-kyc-search",
            "click-kyc-search-clear-filter",
            "view-kyc-user-panel",
            "view-kyc-user-details",
            "kyc-user-summary",
            "click-kyc-user-summary-swap",
            "click-kyc-user-summary-security-notes",
            "view-kyc-user-summary-view-transactions",
            "click-kyc-user-summary-view-more",
            "click-kyc-user-summary-block-user",
            "click-kyc-request-documents",
          ],
        },
      ],
    },
    {
      role: "customer.support.manager",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "click-transaction-export",
            "view-transaction-details",
          ],
        },
        {
          name: "Rates",
          permission: "rates-dashboard",
          actions: [
            "click-rates-dashboard-download-rate-file-template",
            "click-rates-dashboard-choose-file",
            "view-rates-dashboard-preview",
            "view-rates-dashboard-live-rates",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view", "click-transaction-details-export"],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-failed-page",
          actions: ["view"],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: ["view"],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "view-user-details",
            "view-user-summary-view-transactions",
            "click-user-summary-block-user",
            "click-user-summary-view-more",
            "add-user-summary-security-notes",
            "view-user-email",
            "view-user-phonenumber",
            "view",
          ],
        },
        {
          name: "User summary panel",
          permission: "user-summary",
          actions: [
            "click-user-summary-swap",
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Detail Pages",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "click-user-details-download-KYC",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-update-modal",
            "add-user-details-update-modal-Email",
            "add-user-details-update-modal-phone",
            "view-user-details-update-modal-verify-phone-number",
            "click-user-details-update-modal-cancel",
            "click-user-details-update-modal-update-user",
            "user-detail-upload-modal",
            "user-details-upload-modal-selfie-photo",
            "user-details-upload-modal-ID-Front",
            "user-details-upload-modal-ID-Back",
            "user-details-upload-modal-Date-of-back",
            "user-details-upload-modal-ID-Type",
            "user-details-upload-modal-ID-Number",
            "user-details-upload-modal-cancel",
            "user-details-upload-modal-Upload-Details",
            "user-detail-device-id-modal",
            "user-detail-device-id-clear",
            "user-detail-device-id-cancel",
          ],
        },
        {
          name: "KYC",
          permission: "access-kyc-page",
          actions: [
            "click-kyc-search-start-date",
            "click-kyc-search-end-date",
            "click-kyc-search",
            "click-kyc-search-clear-filter",
            "view-kyc-user-panel",
            "view-kyc-user-details",
            "kyc-user-summary",
            "click-kyc-user-summary-swap",
            "click-kyc-user-summary-security-notes",
            "view-kyc-user-summary-view-transactions",
            "click-kyc-user-summary-view-more",
            "click-kyc-user-summary-block-user",
          ],
        },
        {
          name: "Notifications",
          permission: "push-notifications",
          actions: [
            "click-push-notification-mass-push",
            "click-push-notification-individual",
            "click-push-notification-email",
            "click-push-notification-title",
            "click-push-notification-message",
            "click-push-notification-submit",
          ],
        },
      ],
    },
    {
      role: "customer.support.member",
      pages: [
        {
          name: "Main",
          permission: "access-main-page",
          actions: [""],
        },
        {
          name: "Transaction",
          permission: "access-transaction-page",
          actions: [
            "click-transaction-search",
            "view-transaction-summary",
            "click-transaction-export",
            "view-transaction-details",
          ],
        },
        {
          name: "Rates",
          permission: "rates-dashboard",
          actions: [
            "click-rates-dashboard-download-rate-file-template",
            "click-rates-dashboard-choose-file",
            "view-rates-dashboard-preview",
            "view-rates-dashboard-live-rates",
          ],
        },
        {
          name: "Transaction Details",
          permission: "transaction-details-page",
          actions: ["view", "click-transaction-details-export"],
        },
        {
          name: "Transaction Pending Page",
          permission: "transaction-pending-page",
          actions: ["view"],
        },
        {
          name: "Transaction Failed",
          permission: "transaction-failed-page",
          actions: ["view"],
        },
        {
          name: "Transaction VFD",
          permission: "transaction-vfd-page",
          actions: ["view"],
        },
        {
          name: "Users",
          permission: "access-all-users-page",
          actions: [
            "click-user-search",
            "view-user-panel",
            "view-user-details",
            "view-user-summary-view-transactions",
            "click-user-summary-block-user",
            "click-user-summary-view-more",
            "add-user-summary-security-notes",
            "view-user-email",
            "view-user-phonenumber",
          ],
        },
        {
          name: "User summary panel",
          permission: "user-summary",
          actions: [
            "click-user-summary-swap",
            "add-user-summary-security-notes",
            "view-user-summary-view-transactions",
            "click-user-summary-view-more",
            "click-user-summary-block-user",
          ],
        },
        {
          name: "User Detail Pages",
          permission: "user-detail",
          actions: [
            "click-user-details-update",
            "click-user-details-download-KYC",
            "view-user-details-wallet-balance",
            "view-user-details-bank-account-details",
            "view-user-details-debit-card-details",
            "view-user-details-verification-status",
            "user-detail-update-modal",
            "add-user-details-update-modal-Email",
            "add-user-details-update-modal-phone",
            "view-user-details-update-modal-verify-phone-number",
            "user-detail-device-ID-modal",
            "user-detail-device-ID-clear",
            "user-detail-device-ID-cancel",
          ],
        },
        {
          name: "KYC",
          permission: "access-kyc-page",
          actions: [
            "kyc-user-summary",
            "click-kyc-user-summary-swap",
            "click-kyc-user-summary-security-notes",
            "view-kyc-user-summary-view-transactions",
            "click-kyc-user-summary-view-more",
            "click-kyc-user-summary-block-user",
          ],
        },
      ],
    },
  ],
};

export function getRolesForPermission(permission: string) {
  const { roles } = PageRolePermissions;
  const out = roles.filter((r) => {
    const { pages } = r;
    const foundPages = pages.filter((p) => p.permission === permission);
    return foundPages.length > 0;
  });
  return out.map((r) => {
    return r.role;
  });
}

export function getRoles() {
  return PageRolePermissions.roles.map((value) => {
    return value.role;
  });
}

export function hasPermission(permission: string, action: string) {
  const role = localStorage.getItem("admin-role") as string;

  if (Object.values(UserRoles).includes(role)) {
    return true;
  }

  return (
    PageRolePermissions.roles.filter((x) => {
      return (
        x.role === role &&
        x.pages.filter((y) => {
          return y.permission === permission && y.actions.includes(action);
        }).length === 1
      );
    }).length > 0
  );
}
