import { Route, Routes } from "react-router-dom";
import Layout from "./components/common/Layout";
import RequireAuth from "./components/RequireAuth";
import { privateRoutesConfig, publicRoutesConfig } from "./config/routesConfig";
import TransactionProvider from "./context/TransactionContext";
import { useAuth } from "./hooks/useAuth";
import HomePage from "./pages/HomePage";
import PageNotFoundPage from "./pages/PageNotFoundPage";
import { AfriexRoles } from "./types";
import SpinningLoader from "./components/common/SpinningLoader";

export default function RouteNodes() {
  const { role, authLoading } = useAuth();

  // Show a loading spinner while checking authentication status
  if (authLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <SpinningLoader size={14} />
      </div>
    );
  }

  return (
    <Routes>
      {/* public Routes */}
      {publicRoutesConfig.map(({ path, Component }) => (
        <Route path={path} element={<Component />} key={path} />
      ))}

      {/* Private Routes */}
      <Route
        path="/"
        element={
          <TransactionProvider>
            <RequireAuth>
              <Layout />
            </RequireAuth>
          </TransactionProvider>
        }
      >
        <Route index element={<HomePage />} />
        {privateRoutesConfig.map(({ path, roles, Component }) => {
          const found =
            role === AfriexRoles.admin ||
            roles.includes(role?.toString() ?? "");
          return (
            found && <Route path={path} element={<Component />} key={path} />
          );
        })}
        <Route path="*" element={<PageNotFoundPage />} />
      </Route>
    </Routes>
  );
}
