import { identity, pickBy } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { referralService } from "../../../services/referralService";
import { AfriexReferral, AfriexReferralStatus } from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { ReferralListProps, ReferralParams } from "./types";

const useReferralList = (): ReferralListProps => {
  const [referralList, setReferralList] = useState<AfriexReferral[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [itemTotal, setItemTotal] = useState<number>(0);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [error, setError] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status =
    (queryParams.get("status") as AfriexReferralStatus) ??
    AfriexReferralStatus.Qualified;

  const [filters, setFilters] = useState<ReferralParams>({} as ReferralParams);

  const fetchReferralList = useCallback(
    async (params: ReferralParams) => {
      try {
        setError(null);
        setIsLoading(true);
        const { data: referrals, total } =
          await referralService.getReferralList({ ...params, ...filters });
        setReferralList(referrals);
        setItemTotal(total ?? 0);
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    [filters]
  );

  const handleFilters = useCallback(async (filters: ReferralParams) => {
    // remove undefined values
    const cleanedFilters = pickBy(filters, identity);
    const remappedFilters = cleanedFilters.toDate
      ? {
          ...cleanedFilters,
          toDate: moment(new Date(cleanedFilters.toDate))
            .add(1, "days")
            .toISOString(),
        }
      : cleanedFilters;

    setFilters({ page: 0, ...remappedFilters });
  }, []);

  useEffect(() => {
    setFilters({ status });
  }, [status]);

  useEffect(() => {
    fetchReferralList({});
  }, [fetchReferralList]);

  const handleAutoComplete = useCallback(
    async (searchTerm: string) => {
      try {
        setError(null);
        setIsLoading(true);
        setShowPagination(false);
        if (!searchTerm?.trim() || searchTerm.length < 3) {
          await fetchReferralList({});
          return;
        }
        const referrals = await referralService.searchAutoComplete(searchTerm);
        if (referrals) {
          setReferralList(referrals);
          setItemTotal(1);
          setIsSearch(true);
        }
      } catch (e) {
        setError(e as any);
      }
      setIsLoading(false);
    },
    [fetchReferralList]
  );

  const handlePayment = useCallback(async (referralId: string) => {
    try {
      setError(null);
      setIsLoading(true);
      const referral = await referralService.fulfillReferral(referralId);
      if (referral) {
        showSuccessMessage("Referral fulfilled successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      showErrorMessage(`Payment failed:${e as any}`);
    }
    setIsLoading(false);
  }, []);

  const handleBlockPayout = useCallback(async (referralId: string) => {
    try {
      setError(null);
      setIsLoading(true);
      const referral = await referralService.blockPayout(referralId);
      if (referral) {
        showSuccessMessage("Referral payout blocked successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      showErrorMessage(`Blocking payout failed:${e as any}`);
    }
    setIsLoading(false);
  }, []);

  return {
    error,
    fetchReferralList,
    handleAutoComplete,
    handleFilters,
    handlePayment,
    isLoading,
    isSearch,
    page,
    setPage,
    showPagination,
    total: itemTotal,
    referralList,
    handleBlockPayout,
  };
};

export default useReferralList;
