import "react-confirm-alert/src/react-confirm-alert.css";
import { getObject } from "../../../api/monitor";
import DataTable from "react-data-table-component";
import { useState } from "react";
import KeyHoleAnalysisModal from "./KeyHoleAnalysisModal";
import csv from "csvtojson";

type KeyHoleFormProps = {
  data: [];
};

export default function KeyHoleForm({ data }: KeyHoleFormProps): JSX.Element {
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [openKeyHoleAnalysis, setKeyHoleAnalysis] = useState(false);
  const [keyHoleItems, setKeyHoleItems] = useState<any>({} as any);

  function displayKeyHoleAnalysis(key: string) {
    setWaitingForResponse(true);
    setKeyHoleAnalysis(true);
    getObject(key).then(
      (result) => {
        csv({
          noheader: false,
          delimiter: "\t",
          headers: [
            "row",
            "category",
            "avgTime",
            "maxTime",
            "count",
            "totalTime",
            "totalReslen",
            "namespace",
            "collscan",
            "indexesUsed",
            "queryPattern",
          ],
        })
          .fromString(result)
          .then((jsonObj: any) => {
            setKeyHoleItems(jsonObj);
            setWaitingForResponse(false);
          });
      },
      function (error) {
        alert(`erro=${error}`);
        setKeyHoleAnalysis(false);
      }
    );
  }

  function toggleKeyHoleAnalysisForm() {
    setKeyHoleAnalysis(false);
  }

  const columns = [
    {
      name: "Date",
      button: true,
      cell: (row: any) => (
        <button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => displayKeyHoleAnalysis(row.Key)}
        >
          {row.Key.split("/")[1]}
        </button>
      ),
    },

    {
      name: "Last Modified",
      selector: (row: any) => row.LastModified,
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <div className="mt-10">
      <div className="my-4">
        <div>Query Analysis Generated by Key Hole</div>
        <div className="flex space-x-4">
          <div className="w-full grid grid-cols-1 gap-4 items-center">
            {openKeyHoleAnalysis && (
              <KeyHoleAnalysisModal
                data={keyHoleItems}
                loading={waitingForResponse}
                onClose={toggleKeyHoleAnalysisForm}
              />
            )}
            <DataTable columns={columns} pagination data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
