import { InfoBox, WarningIcon } from "../../../components";
import { ArbitrageCheckInfo, RatesMap } from "../../../types";
import { ArbitragePairwiseReporter } from "./ArbitragePairwiseReporter";
import { ArbitrageReporter } from "./ArbitrageReporter";
type ArbitrageTableProps = {
  arbitrageResult: ArbitrageCheckInfo;
  rates?: RatesMap;
  tolerance: number;
  onToleranceChange?: (e: any) => void;
};
const ArbitrageTable = ({
  arbitrageResult,
  onToleranceChange,
  tolerance,
}: ArbitrageTableProps) => {
  const toleranceInput = onToleranceChange ? (
    <div className="w-full flex flex-end">
      <div className="float-right">
        <label
          htmlFor="tolerance"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Percentage Tolerance
        </label>
        <input
          type="number"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Tolerance"
          defaultValue={tolerance}
          onChange={onToleranceChange}
          max={100}
          min={0}
        />
      </div>
    </div>
  ) : null;

  return !arbitrageResult.hasArbitrage ? (
    <div className="p-3 mb-6">
      <InfoBox>
        No arbitrage detected using the tolerance of {tolerance}
      </InfoBox>
      {toleranceInput}
    </div>
  ) : (
    <div className="p-3 mb-6">
      {toleranceInput}
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400">
        <WarningIcon />
        <div>
          <h3>Arbitrage Detected</h3>
          {arbitrageResult.cycles?.map((cycle, index) => (
            <ArbitrageReporter key={index} {...cycle} tolerance={tolerance} />
          ))}

          <ArbitragePairwiseReporter {...arbitrageResult.pairWiseDiscrepancy} />
        </div>
      </div>
    </div>
  );
};
export default ArbitrageTable;
